import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-claims-utilization-analysis',
  templateUrl: './claims-utilization-analysis.component.html',
  styleUrls: ['./claims-utilization-analysis.component.css']
})
export class ClaimsUtilizationAnalysisComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
