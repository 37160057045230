<!-- Body -->
<div class="container-fluid page-body-wrapper">

    <!-- Sidebar -->
    <app-sidebar></app-sidebar>

    <!-- Main Wrapper -->
    <div class="main-panel">
        <div class="content-wrapper pt-3">

            <h3 class="bs-title" style="line-height: unset;">Benchmarking
                <!-- <select class="form-select" style="float: right; width: 260px;">
                    <option>ALL</option>
                </select> -->
            </h3>
            <hr class="mb-2 mt-4" style="height: 0px">            
            
            <div #reportContainer class="my-3 mt-4" style="height: 100vh; width: 100%;"></div>

        </div>
        <app-footer></app-footer>
    </div>

</div>