import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { TpaDetailsService } from './faq-details';
import { Subject } from 'rxjs';
import { ToastService } from '../../toast.service';

interface PolicyType {
  name: string;
  sub_type_id: number;
}

@Component({
  selector: 'app-faq-module',
  templateUrl: './faq-module.component.html',
  styleUrls: ['./faq-module.component.css']
})
export class FaqModuleComponent implements OnInit {

  addflag: any = 0;

  show = false;
  fullScreen = true;
  template = ``;

  user_role: any;
  validmsg: any;
  resstatus = localStorage.getItem('resstatus');
  type_name = localStorage.getItem('type_name');

  public AddInfo: FormGroup;
  AddInfoSubmitted: boolean = false;

  public EditInfo: FormGroup;
  EditInfoSubmitted: boolean = false;

  public DeactivateInfo: FormGroup;
  public ActivateInfo: FormGroup;

  InfoArray: any = [];
  InfoArrayBroker: any = [];
  displayStyle_add = "none";
  displayStyle_edit = "none";
  displayStyleDeactivate = "none";
  displayStyleActivate = "none";

  readaccess: any = 0;
  writeaccess: any = 0;
  deleteaccess: any = 0;
  modulename = 'Manage TPA';

  InfoArrayCorporate: any = [];
  sub_type_id:any  = [];

  radioButtonsDisabled: boolean = true;
  document_file: any;
  document_file_edit: any;

  dtOptions: DataTables.Settings = {
    destroy: true,
    scrollX: true,
    columnDefs: [{
      targets: ['_all'], // column index (start from 0)
      orderable: false, // set orderable false for selected columns
    }]
  };
  dtTrigger: Subject<any> = new Subject<any>();

  constructor(private fb: FormBuilder, private dataservice: TpaDetailsService,
    private route: Router,
    private toastService: ToastService

  ) {
    this.route.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.user_role = localStorage.getItem('type_name');
      }
      if (this.resstatus == null) {
        this.route.navigate(['/home']);
      }
    });
  }

  ngOnInit(): void {

    this.initGroup();
    setTimeout(() => {
      $('.dataTables_wrapper .dataTables_filter input').attr('placeholder', 'Search...');
    }, 1000);
  }

  initGroup() {

    this.InfoArray = [];
    this.getInfo();    
    this.assignrights();
    this.getClient();
    this.getPolicyType();

    // Form element defined below
    this.AddInfo = this.fb.group({
      client_id: new FormControl(null, Validators.required),
      sub_type_id: new FormControl('', Validators.required),
      file_type: new FormControl('yes'),
      document_file: new FormControl('')
    });

    // Form element defined below
    this.EditInfo = this.fb.group({
      client_id_edit: new FormControl('', Validators.required),
      sub_type_id_edit: new FormControl('', Validators.required),
      file_type_edit: new FormControl(''),
      document_file_edit: new FormControl(''),
      edit_id: new FormControl(''),
      edit_is_active: new FormControl(''),
      questions: new FormControl(''),
      answers: new FormControl(''),
    });

    // Form element defined below
    this.DeactivateInfo = this.fb.group({
      delete_id: new FormControl('')
    });

    // Form element defined below
    this.ActivateInfo = this.fb.group({
      active_delete_id: new FormControl('')
    });

  }

  //Add
  get getAddInformationFormControls() {
    return this.AddInfo.controls;
  }

  openPopupAdd() {
    this.addflag = 1;
  }

  closePopupAdd() {
    this.addflag = 0;
    window.location.reload();
  }

  toggleRadioButtons() {
    this.radioButtonsDisabled = !this.radioButtonsDisabled;
  }

  getClient() {

    this.show = true;
    this.fullScreen = true;
    this.template = ``;

    // $('#corporate').val('0');


    var postdata = {
      "tokenable_id": localStorage.getItem('tokenable_id'),
      "broker_id": localStorage.getItem('broker_id'),
    };

    this.dataservice.getInfoClient(postdata)
      .subscribe((result: any) => {

        this.show = false;
        this.InfoArrayCorporate = result.data;

        console.log(this.InfoArrayCorporate);

      }, (error) => {

        this.InfoArrayCorporate = [];

      });
  }

  onSubmitAdd() {

    let postdata: any = {};

    this.AddInfoSubmitted = true;
    if (this.AddInfoSubmitted && this.AddInfo.valid) {

      if (this.document_file == "" || this.document_file == null || this.document_file == undefined) {
        $('#document_file_error').css('display', 'block');
        $('#features-file-upload_document_file').css('border', '1px solid red');

        return;
      } else {

        $('#document_file_error').css('display', 'none');
        $('#features-file-upload_document_file').css('border', '1px solid #dee2e6');
      }

      postdata = {
        "tokenable_id": localStorage.getItem('tokenable_id'),
        "broker_id": localStorage.getItem('broker_id'),
        "client_id": this.AddInfo.controls['client_id'].value,
        "sub_type_id": this.AddInfo.controls['sub_type_id'].value,
        "file_type": this.AddInfo.controls['file_type'].value,
        "help_file": this.document_file
      };


      console.log(postdata);

    this.dataservice.AddInfo(postdata)
      .subscribe((result: any) => {

        // console.log(result);

        if (result.status == true) {

          this.show = false;
          this.toastService.showSuccess('Success', 'Details Saved Successfully!');

          setTimeout(() => {
            window.location.reload();
          }, 3000);

          this.getInfo();
          this.dtTrigger.unsubscribe();

        } else {
          this.show = false;
        }
      }, (error) => {
        this.toastService.showFailed('Failed', 'There was some error');
      });

    }
  }

  //Edit
  get getEditInformationFormControls() {
    return this.EditInfo.controls;
  }

  openPopupEdit(res: any) {
    this.addflag = 2;

    var postdata = {
      "tokenable_id": localStorage.getItem('tokenable_id'),
      "faq_id": res.id
    };

    this.dataservice.InfoSingle(postdata)
      .subscribe((result: any) => {
        this.EditInfo.controls['client_id_edit'].setValue(result.data[0]['employer_idfk']);
        this.EditInfo.controls['sub_type_id_edit'].setValue(result.data[0]['sub_type_policy_idfk']);
        this.EditInfo.controls['file_type_edit'].setValue(result.data[0]['file_type']);
        this.EditInfo.controls['edit_is_active'].setValue(result.data[0]['is_active']);
        this.EditInfo.controls['edit_id'].setValue(result.data[0]['id']);                
        this.EditInfo.controls['questions'].setValue(result.data[0]['questions']);
        this.EditInfo.controls['answers'].setValue(result.data[0]['answers']);
      }, (error) => {

      });

  }

  uploadfile(event: any) {
    if (event.target.files.length > 0) {
      this.document_file = event.target.files[0];
      $("#features-file-upload_document_file").empty();
      $('#features-file-upload_document_file').html(event.target.files[0]['name']);
      $('#features-file-upload_document_file').css('text-align', 'left');

      var iSize = Math.round(event.target.files[0]['size'] / 1024);

      iSize = (Math.round(iSize * 100) / 100);

      $('#features-file-upload_document_file').append('<img src="../../assets/img/policy/file.png" style="float:left; width:12px; margin-top: 2px; margin-right:5px" /> <span style="float: right">' + iSize + 'kb</span>');


    }
  }

  onSubmitEdit() {
    
    let id = $('#edit_id').val();    
    let postdata: any = {};
    this.EditInfoSubmitted = true;
    if (this.EditInfoSubmitted && this.EditInfo.valid) {          

      // if (this.document_file_edit == "" || this.document_file_edit == null || this.document_file_edit == undefined) {
      //   $('#document_file_edit_error').css('display', 'block');
      //   $('#features-file-upload_document_file_edit').css('border', '1px solid red');

      //   return;
      // } else {

      //   $('#document_file_edit_error').css('display', 'none');
      //   $('#features-file-upload_document_file_edit').css('border', '1px solid #dee2e6');
      // }

      postdata = {
        "tokenable_id": localStorage.getItem('tokenable_id'),
        "broker_id": localStorage.getItem('broker_id'),
        "faq_id": id,
        "client_id": this.EditInfo.controls['client_id_edit'].value,
        "sub_type_id": this.EditInfo.controls['sub_type_id_edit'].value,
        "file_type": this.EditInfo.controls['file_type_edit'].value,
        // "help_file": this.document_file_edit,
        "is_active": this.EditInfo.controls['edit_is_active'].value,
        "questions": this.EditInfo.controls['questions'].value,
        "answers": this.EditInfo.controls['answers'].value
      };      

      this.dataservice.InfoUpdate(postdata)
        .subscribe((result: any) => {

          if (result.status == true) {

            this.show = false;
            this.closePopup();
            this.validmsg = result.message;
            this.toastService.showSuccess('Success', 'Details Updated Successfully!');

            setTimeout(() => {
              window.location.reload();
            }, 3000);

          }
        }, (error) => {
          this.closePopup();
          this.toastService.showFailed('Failed', 'There was some error');

        });

    }else{
      console.log(2);
    }

  }

  getPolicyType() {    

    var postdata = {
      "tokenable_id": localStorage.getItem('tokenable_id'),
      "broker_id": localStorage.getItem('broker_id'),
    }
    this.dataservice.getpolicytype(postdata)
      .subscribe((result) => {

        this.sub_type_id = result.data;

      }, (error) => {
        // console.log(result);
      });
  }

  //Delete
  openPopupDeactivate(res: any) {
    this.displayStyleDeactivate = "block";

    var postdata = {
      "tokenable_id": localStorage.getItem('tokenable_id'),
      "faq_id": res.id
    };

    this.dataservice.InfoSingle(postdata)
      .subscribe((result: any) => {

        // console.log(result);
        this.DeactivateInfo.controls['delete_id'].setValue(result.data[0]['id']);

      }, (error) => {

      });

  }

  onSubmitDeactivate() {

    this.show = true;
    this.fullScreen = true;
    this.template = ``;

    let id = $('#delete_id').val();

    var postdata = {
      "tokenable_id": localStorage.getItem('tokenable_id'),
      "faq_id": id,
      "is_active": 0
    };

    this.dataservice.InfoActiveDeactivate(postdata)
      .subscribe((result: any) => {

        if (result.status == true) {

          this.show = false;
          // console.log(result.data);          
          this.toastService.showSuccess('Success', 'Details Deactivated Successfully!');

          // $('#validmsg').css('display', 'block');
          // $('#errormsg').css('display', 'none');          
          this.validmsg = result.message;
          this.closePopup();
          this.getInfo();
          this.dtTrigger.unsubscribe();

        }
      }, (error) => {
        this.closePopup();
        this.toastService.showFailed('Failed', 'There was some error');

      });

  }

  openPopupActivate(res: any) {
    this.displayStyleActivate = "block";

    var postdata = {
      "tokenable_id": localStorage.getItem('tokenable_id'),
      "faq_id": res.id
    };

    this.dataservice.InfoSingle(postdata)
      .subscribe((result: any) => {

        this.ActivateInfo.controls['active_delete_id'].setValue(result.data[0]['id']);

      }, (error) => {

      });

  }

  onSubmitActivate() {

    let id = $('#active_delete_id').val();

    var postdata = {
      "tokenable_id": localStorage.getItem('tokenable_id'),
      "faq_id": id,
      "is_active": 1
    };

    this.dataservice.InfoActiveDeactivate(postdata)
      .subscribe((result: any) => {

        if (result.status == true) {

          this.show = false;
          this.toastService.showSuccess('Success', 'Details Activated Successfully!');
          this.closePopup();
          this.getInfo();
          this.dtTrigger.unsubscribe();

        }
      }, (error) => {
        this.closePopup();
        this.toastService.showFailed('Failed', 'There was some error');

      });

  }

  //Common functions
  getInfo() {

    this.show = true;
    this.fullScreen = true;
    this.template = ``;

    let postdata: any = {};

    if (this.type_name == 'superadmin') {
      postdata = {
        "tokenable_id": localStorage.getItem('tokenable_id'),
      };
    } else {
      postdata = {
        "tokenable_id": localStorage.getItem('tokenable_id'),
        "broker_id": localStorage.getItem('broker_id')
      };
    }

    this.dataservice.getInfo(postdata)
      .subscribe((result: any) => {

        this.show = false;

        // console.log(result);
        this.InfoArray = result.data;
        this.dtTrigger.next();

      }, (error) => {

        this.InfoArray = [];

        if (error.error.message == 'Unauthenticated.') {
          localStorage.clear();
          localStorage.setItem('resstatus', 'false');
          this.route.navigate(['/home']);
        }

      });
  }

  closealert() {
    $("#validmsg").css('display', 'none');
    $("#errormsg").css('display', 'none');
  }

  closePopup() {

    this.displayStyle_add = "none";
    this.displayStyle_edit = "none";
    this.displayStyleDeactivate = "none";
    this.displayStyleActivate = "none";
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  isNumber(evt: any) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  assignrights() {
    let module_info_arr: any = localStorage.getItem("module_info");
    let module_info = JSON.parse(module_info_arr);
    for (var i = 0; i < module_info.length; i++) {
      if (module_info[i]['child'].length == 0) {
        if (module_info[i]['module_content'] == this.modulename) {
          this.writeaccess = module_info[i]['module_write'];
          this.readaccess = module_info[i]['module_read'];
          this.deleteaccess = module_info[i]['module_delete'];
        }
      } else {
        for (var j = 0; j < module_info[i]['child'].length; j++) {
          let json = module_info[i]['child'][j];
          if (json['module_content'] == this.modulename) {
            this.writeaccess = json['module_write'];
            this.readaccess = json['module_read'];
            this.deleteaccess = json['module_delete'];
          }
        }
      }
    }
  }

  downloadsample(){
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', '../../assets/Member Data Sample Files/add_faq_sample.xlsx');
    link.setAttribute('download', `add_faq_sample.xls`);    
    document.body.appendChild(link);
    link.click();
    link.remove(); 
  }

  uploadfile_edit(event: any) {
    if (event.target.files.length > 0) {
      this.document_file_edit = event.target.files[0];
      $("#features-file-upload_document_file_edit").empty();
      $('#features-file-upload_document_file_edit').html(event.target.files[0]['name']);
      $('#features-file-upload_document_file_edit').css('text-align', 'left');

      var iSize = Math.round(event.target.files[0]['size'] / 1024);

      iSize = (Math.round(iSize * 100) / 100);

      $('#features-file-upload_document_file_edit').append('<img src="../../assets/img/policy/file.png" style="float:left; width:12px; margin-top: 2px; margin-right:5px" /> <span style="float: right">' + iSize + 'kb</span>');


    }
  }

}

