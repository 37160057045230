import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { DataTablesModule } from 'angular-datatables';

import { NgSelectModule } from '@ng-select/ng-select';

import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';

import { HttpClientModule } from '@angular/common/http';

import { NavbarComponent } from './commonComponents/navbar/navbar.component';
import { LoginComponent } from './auth/login/login.component';
import { RegisterComponent } from './auth/register/register.component';
import { SuperAdminDashboard } from './superAdmin/dashboard/dashboard.component';
import { AdminDashboard } from './admin/dashboard/dashboard.component';

import { authInterceptorProviders } from './_helpers/auth.interceptor';

// import { ChartsModule } from 'ng2-charts';
import { ChartModule } from 'angular-highcharts';
import { HomeComponent } from './home/home.component';
import { ClientCreationComponent } from './client-creation/client-creation.component';
import { PricingComponent } from './client/pricing/pricing.component';
import { ProfileComponent } from './client/profile/profile.component';
import { UsersComponent } from './superAdmin/user mangement/users/users.component';
import { UsersClientComponent } from './superAdmin/user mangement/users-client/users-client.component';
import { EmployeeDependencyComponent } from './employee-dependency/employee-dependency.component';
import { ClientDashboard } from './client/dashboard/dashboard.component';
import { PolicyDetailsComponent } from './policy-details/policy-details.component';
import { VerifymobileComponent } from './auth/verification/verifymobile/verifymobile.component';
import { VerifyemailComponent } from './auth/verification/verifyemail/verifyemail.component';

import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { MarketDataComponent } from './superAdmin/market-data/market-data.component';
import { TpaDetailsComponent } from './superAdmin/tpa-details/tpa-details.component';
import { InsuranceCompanyInsurerComponent } from './superAdmin/insurance-company-insurer/insurance-company-insurer.component';
import { SelectProductComponent } from './client/select-product/select-product.component';
import { ProductDetailsComponent } from './superAdmin/product-mgmt/product-details/product-details.component';
import { SubproductDetailsComponent } from './superAdmin/product-mgmt/subproduct-details/subproduct-details.component';
import { TopupPolicyDetailsComponent } from './topup-policy-details/topup-policy-details.component';
import { GpaPolicyDetailsComponent } from './gpa-policy-details/gpa-policy-details.component';
import { GtlPolicyDetailsComponent } from './gtl-policy-details/gtl-policy-details.component';
import { OpdPolicyDetailsComponent } from './opd-policy-details/opd-policy-details.component';
import { GoToPoliciesComponent } from './go-to-policies/go-to-policies.component';
import { SelectProductMobileComponent } from './client/select-product-mobile/select-product-mobile.component';
import { PolicyConversionGmcComponent } from './superAdmin/policy-conversion-gmc/policy-conversion-gmc.component';
import { PolicyConversionGpaComponent } from './superAdmin/policy-conversion-gpa/policy-conversion-gpa.component';
import { PolicyConversionGtlComponent } from './superAdmin/policy-conversion-gtl/policy-conversion-gtl.component';
import { PolicyConversionOpdComponent } from './superAdmin/policy-conversion-opd/policy-conversion-opd.component';
import { PolicyFinalizeGmcComponent } from './superAdmin/policy-finalize-gmc/policy-finalize-gmc.component';
import { PolicyFinalizeGpaComponent } from './superAdmin/policy-finalize-gpa/policy-finalize-gpa.component';
import { PolicyFinalizeGtlComponent } from './superAdmin/policy-finalize-gtl/policy-finalize-gtl.component';
import { PolicyFinalizeOpdComponent } from './superAdmin/policy-finalize-opd/policy-finalize-opd.component';
import { SurveyLoginComponent } from './survey/auth/survey-login/survey-login.component';
import { SurveyRegisterComponent } from './survey/auth/survey-register/survey-register.component';
import { SurveyEmailVerificationComponent } from './survey/auth/survey-verification/survey-email-verification/survey-email-verification.component';
import { SurveySelectProductComponent } from './survey/survey-select-product/survey-select-product.component';
import { SurveyGmcPolicyDetailsComponent } from './survey/survey-gmc-policy-details/survey-gmc-policy-details.component';
import { SurveyGpaPolicyDetailsComponent } from './survey/survey-gpa-policy-details/survey-gpa-policy-details.component';
import { SurveyGtlPolicyDetailsComponent } from './survey/survey-gtl-policy-details/survey-gtl-policy-details.component';
import { SurveyOpdPolicyDetailsComponent } from './survey/survey-opd-policy-details/survey-opd-policy-details.component';
import { AboutUsComponent } from './website/about-us/about-us.component';
import { ContactUsComponent } from './website/contact-us/contact-us.component';
import { BenefitBenchmarkingComponent } from './survey/benefit-benchmarking/benefit-benchmarking.component';
import { SurveyManagerComponent } from './superAdmin/survey-manager/survey-manager.component';
import { NgxLoaderModule } from '@tusharghoshbd/ngx-loader';
import { ManageCountryComponent } from './superAdmin/manage-country/manage-country.component';
import { ManageStateComponent } from './superAdmin/manage-state/manage-state.component';
import { ManageCityComponent } from './superAdmin/manage-city/manage-city.component';
import { ManageBrokerComponent } from './superAdmin/manage-broker/manage-broker.component';
import { ManageIndustryComponent } from './superAdmin/manage-industry/manage-industry.component';
import { ManageUserTypesComponent } from './superAdmin/manage-user-types/manage-user-types.component';
import { ManagePlanComponent } from './superAdmin/manage-plan/manage-plan.component';

import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { ManageAnnoucementsComponent } from './superAdmin/manage-annoucements/manage-annoucements.component';
import { ManageNewsComponent } from './superAdmin/manage-news/manage-news.component';
import { MasterBrokerComponent } from './superAdmin/master-broker/master-broker.component';
import { BrokerManageMasterEmployerComponent } from './broker/broker-manage-master-employer/broker-manage-master-employer.component';
import { DashboardComponent } from './broker/dashboard/dashboard.component';
import { EmployerDashboardComponent } from './broker/employer-dashboard/employer-dashboard.component';
import { AddUserComponent } from './superAdmin/master-broker/add-user/add-user.component';
import { AddBrokerUserComponent } from './broker/add-broker-user/add-broker-user.component';
import { AdminthemeComponent } from './superAdmin/admintheme/admintheme.component';
import { AdminmastermodulesComponent } from './superAdmin/adminmastermodules/adminmastermodules.component';
import { UserManagementComponent } from './broker/user-management/user-management.component';
import { EmployeeUserManagementComponent } from './broker/employee-user-management/employee-user-management.component';
import { BrokerManageMasterComponent } from './broker/broker-manage-master/broker-manage-master.component';
import { SubModulesComponent } from './superAdmin/adminmastermodules/sub-modules/sub-modules.component';
import { HrInfoDashboardComponent } from './hr_info/hr-info-dashboard/hr-info-dashboard.component';
import { BrokeruserDashboardComponent } from './broker/brokeruser-dashboard/brokeruser-dashboard.component';
import { EmployeruserDashboardComponent } from './broker/employeruser-dashboard/employeruser-dashboard.component';
import { SidebarComponent } from './commonComponents/sidebar/sidebar.component';
import { FooterComponent } from './commonComponents/footer/footer.component';
import { PoliciesComponent } from './superAdmin/policies/policies.component';
import { PolicieslistingComponent } from './superAdmin/policieslisting/policieslisting.component';
import { EndorsementrequestComponent } from './broker/endorsementrequest/endorsementrequest.component';
import { FamilyContructComponent } from './broker/employeruser-dashboard/family-contruct/family-contruct.component';
import { EmployeeBenefitComponent } from './broker/employeruser-dashboard/employee-benefit/employee-benefit.component';
import { FamilyEnrollmentComponent } from './broker/employeruser-dashboard/family-contruct/family-enrollment/family-enrollment.component';
import { BrokerManageMasterEditComponent } from './broker/broker-manage-master/broker-manage-master-edit/broker-manage-master-edit.component';
import { PolicyEditComponent } from './broker/policy-edit/policy-edit.component';
import { ClaimStatusComponent } from './broker/claim-status/claim-status.component';
import { ClaimIntimationComponent } from './broker/claim-intimation/claim-intimation.component';
import { ClaimDocumentComponent } from './broker/claim-document/claim-document.component';
import { ClaimsexecutiveuserDashboardComponent } from './broker/add-broker-user/claimsexecutiveuser-dashboard/claimsexecutiveuser-dashboard.component';
import { ClaimIntimationClientexetutiveuserComponent } from './broker/claim-intimation-clientexetutiveuser/claim-intimation-clientexetutiveuser.component';
import { ProductFeatureMasterComponent } from './broker/product-feature-master/product-feature-master.component';
import { FlexConfiguratorComponent } from './broker/flex-configurator/flex-configurator.component';
import { PolicyDetailsFamilyComponent } from './broker/employeruser-dashboard/family-contruct/family-enrollment/policy-details-family/policy-details-family.component';
import { BrokerInsuranceCompanyComponent } from './broker/broker-insurance-company/broker-insurance-company.component';
import { ListingComponent } from './broker/claim-intimation-clientexetutiveuser/listing/listing.component';
import { FlexConfiguratorEditComponent } from './broker/flex-configurator-edit/flex-configurator-edit.component';
import { EndoresementRequestComponent } from './broker/endoresement-request/endoresement-request.component';
import { UploadPolicyCoverageComponent } from './broker/upload-policy-coverage/upload-policy-coverage.component';
import { EndorsmentReportComponent } from './broker/endorsment-report/endorsment-report.component';
import { FlexConfiguratorDashboardComponent } from './broker/flex-configurator-dashboard/flex-configurator-dashboard.component';
import { NomineeUploadComponent } from './broker/nominee-upload/nominee-upload.component';
import { FlexPlanComponent } from './broker/flex-plan/flex-plan.component';
import { FlexPlanSingleComponent } from './broker/flex-plan/flex-plan-single/flex-plan-single.component';
import { FlexPlanMultipleComponent } from './broker/flex-plan/flex-plan-multiple/flex-plan-multiple.component';
import { Saml2Component } from './auth/saml2/saml2.component';
import { LogoutComponent } from './commonComponents/logout/logout.component';
import { FlexPlanSummaryComponent } from './broker/flex-plan/flex-plan-summary/flex-plan-summary.component';
import { MultiplePlanComponent } from './broker/flex-plan/multiple-plan/multiple-plan.component';
import { BrokerManageMasterViewComponent } from './broker/broker-manage-master/broker-manage-master-view/broker-manage-master-view.component';
import { CarInsuranceComponent } from './broker/car-insurance/car-insurance.component';
import { HealthInsuranceComponent } from './broker/health-insurance/health-insurance.component';
import { WellBeingComponent } from './broker/well-being/well-being.component';
import { ContactUsInfoComponent } from './broker/contact-us-info/contact-us-info.component';
import { SinglePlanComponent } from './broker/flex-plan/single-plan/single-plan.component';
import { WellnessComponent } from './broker/wellness/wellness.component';
import { WellnessBenefitComponent } from './broker/wellness-benefit/wellness-benefit.component';
import { NetworkHospitalComponent } from './broker/network-hospital/network-hospital.component';
import { EmployerBenefitWellnessComponent } from './broker/employer-benefit-wellness/employer-benefit-wellness.component';
import { AncillaryComponent } from './broker/ancillary/ancillary.component';
import { AncillaryDetailsComponent } from './broker/ancillary-details/ancillary-details.component';
import { NetworksComponent } from './broker/networks/networks.component';
import { FormCenterComponent } from './broker/form-center/form-center.component';
import { BrokerNetworksComponent } from './broker/broker-networks/broker-networks.component';
import { BrokerTpaTriggerComponent } from './broker/broker-tpa-trigger/broker-tpa-trigger.component';
import { BrokerIntimateClaimsComponent } from './broker/broker-intimate-claims/broker-intimate-claims.component';
import { EmployeeEcardComponent } from './broker/employee-ecard/employee-ecard.component';
import { EmployerEcardComponent } from './broker/employer-ecard/employer-ecard.component';
import { BrokerEcardComponent } from './broker/broker-ecard/broker-ecard.component';
import { EmployeeFaqComponent } from './broker/employee-faq/employee-faq.component';
import { BrokerTpaTriggerLogsComponent } from './broker/broker-tpa-trigger-logs/broker-tpa-trigger-logs.component';
import { BrokerEcardUploadComponent } from './broker/broker-ecard-upload/broker-ecard-upload.component';
import { EcardFileUploadComponent } from './broker/ecard-file-upload/ecard-file-upload.component';
import { EcardDetailsComponent } from './broker/ecard-details/ecard-details.component';
import { OverallClaimsComponent } from './broker/overall-claims/overall-claims.component';
import { ClaimViewComponent } from './broker/claim-view/claim-view.component';
import { BrokerReportComponent } from './broker/broker-report/broker-report.component';
import { FlexReportComponent } from './broker/flex-report/flex-report.component';
import { ManageDocumentTypeComponent } from './superAdmin/manage-document-type/manage-document-type.component';
import { ClaimPortalComponent } from './broker/claim-portal/claim-portal.component';
import { DashboardAncillaryComponent } from './broker/dashboard-ancillary/dashboard-ancillary.component';
import { EmployeeClaimsPortalComponent } from './broker/employee-claims-portal/employee-claims-portal.component';
import { EmployeeIntimateClaimsComponent } from './broker/employee-intimate-claims/employee-intimate-claims.component';
import { ScheduleDemoComponent } from './website/schedule-demo/schedule-demo.component';
import { CdUpdationComponent } from './broker/cd-updation/cd-updation.component';
import { AboutCompanyComponent } from './website/about-company/about-company.component';
import { LeadershipTeamComponent } from './website/leadership-team/leadership-team.component';
import { WhyNeodigitComponent } from './website/why-neodigit/why-neodigit.component';
import { VisionMissionComponent } from './website/vision-mission/vision-mission.component';
import { EbPortalComponent } from './website/eb-portal/eb-portal.component';
import { HealthBenefitsToolComponent } from './website/health-benefits-tool/health-benefits-tool.component';
import { BenefitsConsultingComponent } from './website/benefits-consulting/benefits-consulting.component';
import { AutomationComponent } from './website/automation/automation.component';
import { CommunicationComponent } from './website/communication/communication.component';
import { WellbeingComponent } from './website/wellbeing/wellbeing.component';
import { CustomizedSolutionComponent } from './website/customized-solution/customized-solution.component';
import { WebFooterComponent } from './website/web-footer/web-footer.component';
import { WebMenuBarComponent } from './website/web-menu-bar/web-menu-bar.component';
import { AffinityComponent } from './website/affinity/affinity.component';
import { GmcFlexPlanComponent } from './broker/flex-plan/gmc-flex-plan/gmc-flex-plan.component';
import { OpdFlexPlanComponent } from './broker/flex-plan/opd-flex-plan/opd-flex-plan.component';
import { GpaFlexPlanComponent } from './broker/flex-plan/gpa-flex-plan/gpa-flex-plan.component';
import { GtlFlexPlanComponent } from './broker/flex-plan/gtl-flex-plan/gtl-flex-plan.component';
import { BenchmarkingReportComponent } from './broker/benchmarking-report/benchmarking-report.component';
import { GmcDashboardComponent } from './broker/dashboard/gmc-dashboard/gmc-dashboard.component';
import { OpdDashboardComponent } from './broker/dashboard/opd-dashboard/opd-dashboard.component';
import { GpaDashboardComponent } from './broker/dashboard/gpa-dashboard/gpa-dashboard.component';
import { GtlDashboardComponent } from './broker/dashboard/gtl-dashboard/gtl-dashboard.component';
import { ClaimsUtilizationAnalysisComponent } from './broker/claims-utilization-analysis/claims-utilization-analysis.component';
import { EdliComponent } from './broker/dashboard/edli/edli.component';
import { FaqModuleComponent } from './broker/faq-module/faq-module.component';


@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    LoginComponent,
    RegisterComponent,
    SuperAdminDashboard,
    AdminDashboard,
    HomeComponent,
    ClientCreationComponent,
    PricingComponent,
    ProfileComponent,
    UsersComponent,
    UsersClientComponent,
    EmployeeDependencyComponent,
    ClientDashboard,
    PolicyDetailsComponent,
    VerifymobileComponent,
    VerifyemailComponent,
    MarketDataComponent,
    TpaDetailsComponent,
    InsuranceCompanyInsurerComponent,
    SelectProductComponent,
    ProductDetailsComponent,
    SubproductDetailsComponent,
    TopupPolicyDetailsComponent,
    GpaPolicyDetailsComponent,
    GtlPolicyDetailsComponent,
    OpdPolicyDetailsComponent,
    GoToPoliciesComponent,
    SelectProductMobileComponent,
    PolicyConversionGmcComponent,
    PolicyConversionGpaComponent,
    PolicyConversionGtlComponent,
    PolicyConversionOpdComponent,
    PolicyFinalizeGmcComponent,
    PolicyFinalizeGpaComponent,
    PolicyFinalizeGtlComponent,
    PolicyFinalizeOpdComponent,
    SurveyLoginComponent,
    SurveyRegisterComponent,
    SurveyEmailVerificationComponent,
    SurveySelectProductComponent,
    SurveyGmcPolicyDetailsComponent,
    SurveyGpaPolicyDetailsComponent,
    SurveyGtlPolicyDetailsComponent,
    SurveyOpdPolicyDetailsComponent,
    AboutUsComponent,
    ContactUsComponent,
    BenefitBenchmarkingComponent,
    SurveyManagerComponent,
    ManageCountryComponent,
    ManageStateComponent,
    ManageCityComponent,
    ManageBrokerComponent,
    ManageIndustryComponent,
    ManageUserTypesComponent,
    ManagePlanComponent,
    ManageAnnoucementsComponent,
    ManageNewsComponent,
    MasterBrokerComponent,
    BrokerManageMasterEmployerComponent,
    DashboardComponent,
    EmployerDashboardComponent,
    AddUserComponent,
    AddBrokerUserComponent,
    AdminthemeComponent,
    AdminmastermodulesComponent,
    UserManagementComponent,
    EmployeeUserManagementComponent,
    BrokerManageMasterComponent,
    SubModulesComponent,
    HrInfoDashboardComponent,
    BrokeruserDashboardComponent,
    EmployeruserDashboardComponent,
    SidebarComponent,
    FooterComponent,
    PoliciesComponent,
    PolicieslistingComponent,
    EndorsementrequestComponent,
    FamilyContructComponent,
    EmployeeBenefitComponent,
    FamilyEnrollmentComponent,
    BrokerManageMasterEditComponent,
    PolicyEditComponent,
    ClaimStatusComponent,
    ClaimIntimationComponent,
    ClaimDocumentComponent,
    ClaimsexecutiveuserDashboardComponent,
    ClaimIntimationClientexetutiveuserComponent,
    ProductFeatureMasterComponent,
    FlexConfiguratorComponent,
    PolicyDetailsFamilyComponent,
    BrokerInsuranceCompanyComponent,
    ListingComponent,
    FlexConfiguratorEditComponent,
    EndoresementRequestComponent,
    UploadPolicyCoverageComponent,
    EndorsmentReportComponent,
    FlexConfiguratorDashboardComponent,
    NomineeUploadComponent,
    FlexPlanComponent,
    FlexPlanSingleComponent,
    FlexPlanMultipleComponent,
    Saml2Component,
    LogoutComponent,
    FlexPlanSummaryComponent,
    MultiplePlanComponent,
    BrokerManageMasterViewComponent,
    CarInsuranceComponent,
    HealthInsuranceComponent,
    WellBeingComponent,
    ContactUsInfoComponent,
    SinglePlanComponent,
    WellnessComponent,
    WellnessBenefitComponent,
    NetworkHospitalComponent,
    EmployerBenefitWellnessComponent,
    AncillaryComponent,
    AncillaryDetailsComponent,
    NetworksComponent,
    FormCenterComponent,
    BrokerNetworksComponent,
    BrokerTpaTriggerComponent,
    BrokerIntimateClaimsComponent,
    EmployeeEcardComponent,
    EmployerEcardComponent,
    BrokerEcardComponent,
    EmployeeFaqComponent,
    BrokerTpaTriggerLogsComponent,
    BrokerEcardUploadComponent,
    EcardFileUploadComponent,
    EcardDetailsComponent,
    OverallClaimsComponent,
    ClaimViewComponent,
    BrokerReportComponent,
    FlexReportComponent,
    ManageDocumentTypeComponent,
    ClaimPortalComponent,
    DashboardAncillaryComponent,
    EmployeeClaimsPortalComponent,
    EmployeeIntimateClaimsComponent,
    ScheduleDemoComponent,
    CdUpdationComponent,
    AboutCompanyComponent,
    LeadershipTeamComponent,
    WhyNeodigitComponent,
    VisionMissionComponent,
    EbPortalComponent,
    HealthBenefitsToolComponent,
    BenefitsConsultingComponent,
    AutomationComponent,
    CommunicationComponent,
    WellbeingComponent,
    CustomizedSolutionComponent,
    WebFooterComponent,
    WebMenuBarComponent,
    AffinityComponent,
    GmcFlexPlanComponent,
    OpdFlexPlanComponent,
    GpaFlexPlanComponent,
    GtlFlexPlanComponent,
    BenchmarkingReportComponent,
    GmcDashboardComponent,
    OpdDashboardComponent,
    GpaDashboardComponent,
    GtlDashboardComponent,
    ClaimsUtilizationAnalysisComponent,
    EdliComponent,
    FaqModuleComponent,
  ],
  imports: [
    NgMultiSelectDropDownModule.forRoot(),
    BrowserModule,
    AppRoutingModule,
    DataTablesModule,
    NgSelectModule,
    FormsModule,
    NgxLoaderModule,
    ReactiveFormsModule,
    HttpClientModule,
    ChartModule,    
    NgxSliderModule
  ],
  providers: [authInterceptorProviders],
  bootstrap: [AppComponent]
})
export class AppModule { }
