import { Component, OnInit } from '@angular/core';
import { Chart } from 'angular-highcharts';

@Component({
  selector: 'app-gtl-dashboard',
  templateUrl: './gtl-dashboard.component.html',
  styleUrls: ['./gtl-dashboard.component.css']
})
export class GtlDashboardComponent implements OnInit {

  pieChart1: Chart;
  pieChart2: Chart;
  pieChart3: Chart;  
  areaChart: Chart;
  barChart1: Chart;
  barChart2: Chart;
  barChart3: Chart;
  donutChart1: Chart;
  donutChart2: Chart;
  donutChart3: Chart;
  donutChart4: Chart;
  donutChart5: Chart;
  donutChart6: Chart;
  donutChart7: Chart;
  donutChart8: Chart;

  ngOnInit(): void {

    this.initGroup();
  
  }

  initGroup(){
    this.PieChart1();
    this.PieChart2();
    this.PieChart3();    
    this.AreaChart();
    this.BarChart1();
    this.BarChart2();
    this.BarChart3();
    this.DonutChart1();
    this.DonutChart2();
    this.DonutChart3();
    this.DonutChart4();
    this.DonutChart5();
    this.DonutChart6();
    this.DonutChart7();
    this.DonutChart8();
  }

  PieChart1() {

    const data1 = [      
      { name: 'Branch 1', y: 39.015 },
      { name: 'Branch 2', y: 52.785 },
      { name: 'Branch 3', y: 57.375 },
      { name: 'Branch 4', y: 80.325 }
    ];      
  
    const chartWidth = window.innerWidth > 1900 ? 360 : 270;
  
    this.pieChart1 = new Chart({
      chart: {
        type: 'bar',
        height: 240,
        width: chartWidth,
        marginLeft: 65,
        marginBottom: 60
      },
      title: {
        text: '',
        style: {
          fontSize: '12px',
          color: '#000',
          fontFamily: '"Poppins", sans-serif',
          fontWeight: 'bold'
        }
      },
      xAxis: {
        categories: data1.map(item => item.name), // Use name values as categories for y-axis
        title: {
          text: 'Companies' // Title for y-axis
        },
        labels: {
          useHTML: true,
          formatter: function() {
            const text = this.value.toString();
            const maxLabelLength = 10; // Adjust the length as needed
            const displayedText = text.length > maxLabelLength ? text.slice(0, maxLabelLength) + '...' : text;
            return `<span title="${text}">${displayedText}</span>`;
          },
          style: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }
        }
      },
      series: [{
        name: '',
        type: 'bar',
        data: data1.map(item => ({
          y: item.y,
          color: item.y <= 60 ? '#00D63C' : item.y <= 75 ? '#00D63C' : '#00D63C' // Define color based on value range
        })),
        pointWidth: 15
      }],
      exporting: {
        enabled: false
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true,
            format: '{point.y} Cr' // Display values in crores
          }
        }
      },
      legend: {
        enabled: false // Disable legend since it's not necessary for this configuration
      },
      yAxis: {
        title: {
          text: '' // Title for x-axis
        }
      }
    });
  
  }  
  
  PieChart2() {

    const data1 = [
      { name: 'Branch 1', y: 1.95 },
      { name: 'Branch 2', y: 2.64 },
      { name: 'Branch 3', y: 2.87 },
      { name: 'Branch 4', y: 4.02 }
    ];    
  
    const chartWidth = window.innerWidth > 1900 ? 360 : 270;
  
    this.pieChart2 = new Chart({
      chart: {
        type: 'bar',
        height: 240,
        width: chartWidth,
        marginLeft: 65,
        marginBottom: 60
      },
      title: {
        text: '',
        style: {
          fontSize: '12px',
          color: '#000',
          fontFamily: '"Poppins", sans-serif',
          fontWeight: 'bold'
        }
      },
      xAxis: {
        categories: data1.map(item => item.name), // Use name values as categories for y-axis
        title: {
          text: 'Companies' // Title for y-axis
        },
        labels: {
          useHTML: true,
          formatter: function() {
            const text = this.value.toString();
            const maxLabelLength = 10; // Adjust the length as needed
            const displayedText = text.length > maxLabelLength ? text.slice(0, maxLabelLength) + '...' : text;
            return `<span title="${text}">${displayedText}</span>`;
          },
          style: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }
        }
      },
      series: [{
        name: '',
        type: 'bar',
        data: data1.map(item => ({
          y: item.y,
          color: item.y <= 60 ? '#00D63C' : item.y <= 75 ? '#00D63C' : '#00D63C' // Define color based on value range
        })),
        pointWidth: 15
      }],
      exporting: {
        enabled: false
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true,
            format: '{point.y} Cr' // Display values in crores
          }
        }
      },
      legend: {
        enabled: false // Disable legend since it's not necessary for this configuration
      },
      yAxis: {
        title: {
          text: '' // Title for x-axis
        }
      }
    });

  }

  PieChart3() {

    const data1 = [
      { name: 'NEW', y: 61 },
      { name: 'RENEWED', y: 245 }
    ];

    const chartWidth = window.innerWidth > 1900 ? 360 : 270;

    this.pieChart3 = new Chart({
      chart: {
        type: 'pie',
        height: 240,
        width: chartWidth,
        // margin: [20, 20, 20, 20], // Reduce margins
        // spacing: [15, 15, 15, 15] // Reduce spacing
      },
      title: {
        text: 'Premium Spent',
        style: {
          fontSize: '12px',
          color: '#000',
          fontFamily: '"Poppins", sans-serif',
          fontWeight: 'bold'
        }
      },
      series: [{
        name: 'Data',
        type: 'pie',
        data: data1
      }],
      exporting: {
        enabled: false
      },
      plotOptions: {
        pie: {
          colors: ['#FFBF3F', '#173F35'],
          dataLabels: {
            enabled: true, // Enable data labels
            format: '{point.y}', // Display name and percentage
            distance: 5 // Position data labels outside the pie slices
          },
          size: '100%', // Make the pie chart take up full width
          showInLegend: true
        }
      },
      legend: {
        enabled: true,
        layout: 'horizontal',
        align: 'center',
        verticalAlign: 'bottom',
        itemStyle: {
          fontFamily: '"Poppins", sans-serif',
          fontSize: '12px',
          color: '#000'
        },
        symbolHeight: 10, // Set symbol height to make it a square
        symbolWidth: 10,  // Set symbol width to make it a square
        symbolRadius: 0 // Make legend symbol square
      }
    });

  }  

  AreaChart() {

    const data1 = [
      { name: '0', y: 'JAN' },
      { name: '70.00', y: 'JAN' },
      { name: '25.00', y: 'FEB' },
      { name: '154.00', y: 'MAR' },
      { name: '120.00', y: 'APR' },
      { name: '22.00', y: 'MAY' }
    ];

    const chartWidth = window.innerWidth > 1900 ? 360 : 270;

    this.areaChart = new Chart({
      chart: {
        type: 'area',
        height: 240,
        width: chartWidth,
        // margin: [30, 30, 60, 30], // Reduce margins
        marginLeft: 35,
        marginBottom: 60
      },
      title: {
        text: '',
        style: {
          fontSize: '12px',
          color: '#000',
          fontFamily: '"Poppins", sans-serif',
          fontWeight: 'bold'
        }
      },
      xAxis: {
        categories: data1.map(d => d.y),
        title: {
          text: '',
          style: {
            fontSize: '10px',
            color: '#000',
            fontFamily: '"Poppins", sans-serif'
          }
        }
      },
      yAxis: {
        title: {
          text: '', // amount
          style: {
            fontSize: '10px',
            color: '#000',
            fontFamily: '"Poppins", sans-serif'
          }
        }
      },
      series: [{
        name: 'Claim ratio',
        type: 'area',
        data: data1.map(d => parseFloat(d.name))
      }],
      exporting: {
        enabled: false
      },
      plotOptions: {
        area: {
          fillColor: '#173F35',
          lineColor: '#FFA500',
          fillOpacity: 0.5,
          dataLabels: {
            enabled: true,
            format: '{point.y}', // Display value
          },
          marker: {
            fillColor: '#173F35', // Set marker color
            lineColor: '#173F35'  // Set marker border color
          },
          showInLegend: true
        }
      },
      legend: {
        enabled: true,
        layout: 'horizontal',
        align: 'center',
        verticalAlign: 'bottom',
        itemStyle: {
          fontFamily: '"Poppins", sans-serif',
          fontSize: '12px',
          color: '#000'
        },        
        symbolHeight: 10, // Set symbol height to make it a square
        symbolWidth: 10,  // Set symbol width to make it a square
        symbolRadius: 0 // Make legend symbol square
      }
    });

  }

  BarChart1() {
    const data1 = [
      { name: 'ICICI', y: 85 },
      { name: 'BAJAJ', y: 30 },
      { name: 'PNB', y: 30 }
    ];
  
    const chartWidth = window.innerWidth > 1900 ? 360 : 270;
  
    this.barChart1 = new Chart({
      chart: {
        type: 'bar',
        height: 240,
        width: chartWidth,
        marginLeft: 45,
        marginBottom: 60
      },
      title: {
        text: '',
        style: {
          fontSize: '12px',
          color: '#000',
          fontFamily: '"Poppins", sans-serif',
          fontWeight: 'bold'
        }
      },
      xAxis: {
        categories: data1.map(item => item.name), // Use name values as categories for y-axis
        title: {
          text: 'Companies' // Title for y-axis
        }
      },
      series: [{
        name: '',
        type: 'bar',
        data: data1.map(item => ({
          y: item.y,
          color: item.y <= 60 ? '#00D63C' : item.y <= 75 ? '#00D63C' : '#00D63C' // Define color based on value range
        })),
        pointWidth: 15
      }],
      exporting: {
        enabled: false
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true,
            format: '{point.y}'
          }
        }
      },
      legend: {
        enabled: false // Disable legend since it's not necessary for this configuration
      },
      yAxis: {
        title: {
          text: '' // Title for x-axis
        }
      }
    });
  }
             
  BarChart2() {

    const data1 = [
      { name: 'GMC', y: 85 },
      { name: 'OPD', y: 30 },
      { name: 'TITLE 1', y: 30 }
    ];
  
    const chartWidth = window.innerWidth > 1900 ? 360 : 270;
  
    this.barChart2 = new Chart({
      chart: {
        type: 'bar',
        height: 240,
        width: chartWidth,
        marginLeft: 45,
        marginBottom: 70
      },
      title: {
        text: '',
        style: {
          fontSize: '12px',
          color: '#000',
          fontFamily: '"Poppins", sans-serif',
          fontWeight: 'bold'
        }
      },
      xAxis: {
        categories: data1.map(item => item.name), // Use name values as categories for y-axis
        title: {
          text: 'Companies' // Title for y-axis
        }
      },
      series: [{
        name: '',
        type: 'bar',
        data: data1.map(item => ({
          y: item.y,
          color: item.y <= 60 ? '#00D63C' : item.y <= 75 ? '#00D63C' : '#00D63C' // Define color based on value range
        })),
        pointWidth: 15
      }],
      exporting: {
        enabled: false
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true,
            format: '{point.y}'
          }
        }
      },
      legend: {
        enabled: false // Disable legend since it's not necessary for this configuration
      },
      yAxis: {
        title: {
          text: '' // Title for x-axis
        }
      }
    });

  }

  BarChart3() {

    const data1 = [
      { name: 'GMC', y: 85 },
      { name: 'OPD', y: 30 },
      { name: 'TITLE 1', y: 30 }
    ];
  
    const chartWidth = window.innerWidth > 1900 ? 360 : 270;
  
    this.barChart3 = new Chart({
      chart: {
        type: 'bar',
        height: 240,
        width: chartWidth,
        marginLeft: 45,
        marginBottom: 70
      },
      title: {
        text: '',
        style: {
          fontSize: '12px',
          color: '#000',
          fontFamily: '"Poppins", sans-serif',
          fontWeight: 'bold'
        }
      },
      xAxis: {
        categories: data1.map(item => item.name), // Use name values as categories for y-axis
        title: {
          text: 'Companies' // Title for y-axis
        }
      },
      series: [{
        name: '',
        type: 'bar',
        data: data1.map(item => ({
          y: item.y,
          color: item.y <= 60 ? '#00D63C' : item.y <= 75 ? '#00D63C' : '#00D63C' // Define color based on value range
        })),
        pointWidth: 15
      }],
      exporting: {
        enabled: false
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true,
            format: '{point.y}'
          }
        }
      },
      legend: {
        enabled: false // Disable legend since it's not necessary for this configuration
      },
      yAxis: {
        title: {
          text: '' // Title for x-axis
        }
      }
    });

  }

  DonutChart1() {    
    const data1 = [
      { name: 'Client 1', y: 15 },
      { name: 'Client 2', y: 10 },
      { name: 'Client 3', y: 10 },
      { name: 'Client 4', y: 20 },
      { name: 'Client 5', y: 10 },
      { name: 'Other', y: 35 }
    ];

    const chartWidth = window.innerWidth > 1900 ? 360 : 270;    
  
    this.donutChart1 = new Chart({
      chart: {
        type: 'pie',
        height: 240,
        width: chartWidth,
      },
      title: {
        text: ''
      },
      series: [{
        name: 'Share',
        type: 'pie',
        innerSize: '60%', // Set inner size to make it a donut chart
        data: data1
      }],
      exporting: {
        enabled: false
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          colors: ['#FFBF3F', '#FFA500', '#F9423A', '#A0231D', '#057A88', '#173F35'],
          cursor: 'pointer',
          dataLabels: {
            format: '{point.y}', // Display name and percentage
            distance: 5 // Position data labels outside the pie slices
          },
          size: '100%',
          showInLegend: true
        }
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
      },
      legend: {
        enabled: true,
        layout: 'horizontal',
        align: 'center',
        verticalAlign: 'bottom',
        itemStyle: {
          fontFamily: '"Poppins", sans-serif',
          fontSize: '12px',
          color: '#000'
        },
        symbolHeight: 10, // Set symbol height to make it a square
        symbolWidth: 10,  // Set symbol width to make it a square
        symbolRadius: 0 // Make legend symbol square
      }
    });  
  }

  DonutChart2() {    
    const data1 = [
      { name: 'RM 1', y: 15 },
      { name: 'RM 2', y: 10 },
      { name: 'RM 3', y: 10 },
      { name: 'RM 4', y: 20 },
      { name: 'RM 5', y: 10 },
      { name: 'Other', y: 35 }
    ];

    const chartWidth = window.innerWidth > 1900 ? 360 : 270;    
  
    this.donutChart2 = new Chart({
      chart: {
        type: 'pie',
        height: 240,
        width: chartWidth,
      },
      title: {
        text: ''
      },
      series: [{
        name: 'Share',
        type: 'pie',
        innerSize: '60%', // Set inner size to make it a donut chart
        data: data1
      }],
      exporting: {
        enabled: false
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          colors: ['#FFBF3F', '#FFA500', '#F9423A', '#A0231D', '#057A88', '#173F35'],
          cursor: 'pointer',
          dataLabels: {
            format: '{point.y}', // Display name and percentage
            distance: 5 // Position data labels outside the pie slices
          },
          size: '100%',
          showInLegend: true
        }
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
      },
      legend: {
        enabled: true,
        layout: 'horizontal',
        align: 'center',
        verticalAlign: 'bottom',
        itemStyle: {
          fontFamily: '"Poppins", sans-serif',
          fontSize: '12px',
          color: '#000'
        },
        symbolHeight: 10, // Set symbol height to make it a square
        symbolWidth: 10,  // Set symbol width to make it a square
        symbolRadius: 0 // Make legend symbol square
      }
    });  
  }

  DonutChart3() {    
    const data1 = [
      { name: 'RM 1', y: 15 },
      { name: 'RM 2', y: 10 },
      { name: 'RM 3', y: 10 },
      { name: 'ICICI 4', y: 20 },
      { name: 'BAJAJ 5', y: 10 },
      { name: 'Other', y: 35 }
    ];

    const chartWidth = window.innerWidth > 1900 ? 360 : 270;    
  
    this.donutChart3 = new Chart({
      chart: {
        type: 'pie',
        height: 240,
        width: chartWidth,
      },
      title: {
        text: ''
      },
      series: [{
        name: 'Share',
        type: 'pie',
        innerSize: '60%', // Set inner size to make it a donut chart
        data: data1
      }],
      exporting: {
        enabled: false
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          colors: ['#FFBF3F', '#FFA500', '#F9423A', '#A0231D', '#057A88', '#173F35'],
          cursor: 'pointer',
          dataLabels: {
            format: '{point.y}', // Display name and percentage
            distance: 5 // Position data labels outside the pie slices
          },
          size: '100%',
          showInLegend: true
        }
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
      },
      legend: {
        enabled: true,
        layout: 'horizontal',
        align: 'center',
        verticalAlign: 'bottom',
        itemStyle: {
          fontFamily: '"Poppins", sans-serif',
          fontSize: '12px',
          color: '#000'
        },
        symbolHeight: 10, // Set symbol height to make it a square
        symbolWidth: 10,  // Set symbol width to make it a square
        symbolRadius: 0 // Make legend symbol square
      }
    });  
  }

  DonutChart4() {    
    const data1 = [
      { name: 'RM 1', y: 15 },
      { name: 'RM 2', y: 10 },
      { name: 'RM 3', y: 10 },
      { name: 'ICICI 4', y: 20 },
      { name: 'BAJAJ 5', y: 10 },
      { name: 'Other', y: 35 }
    ];

    const chartWidth = window.innerWidth > 1900 ? 360 : 270;    
  
    this.donutChart4 = new Chart({
      chart: {
        type: 'pie',
        height: 240,
        width: chartWidth,
      },
      title: {
        text: ''
      },
      series: [{
        name: 'Share',
        type: 'pie',
        innerSize: '60%', // Set inner size to make it a donut chart
        data: data1
      }],
      exporting: {
        enabled: false
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          colors: ['#FFBF3F', '#FFA500', '#F9423A', '#A0231D', '#057A88', '#173F35'],
          cursor: 'pointer',
          dataLabels: {
            format: '{point.y}', // Display name and percentage
            distance: 5 // Position data labels outside the pie slices
          },
          size: '100%',
          showInLegend: true
        }
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
      },
      legend: {
        enabled: true,
        layout: 'horizontal',
        align: 'center',
        verticalAlign: 'bottom',
        itemStyle: {
          fontFamily: '"Poppins", sans-serif',
          fontSize: '12px',
          color: '#000'
        },
        symbolHeight: 10, // Set symbol height to make it a square
        symbolWidth: 10,  // Set symbol width to make it a square
        symbolRadius: 0 // Make legend symbol square
      }
    });  
  }

  DonutChart5() {    
    const data1 = [
      { name: 'Provider 1', y: 1500 },
      { name: 'Provider 2', y: 1000 },
      { name: 'Provider 3', y: 500 },
      { name: 'Provider 4', y: 2000 },
      { name: 'Provider 5', y: 5000 }
    ];

    const chartWidth = window.innerWidth > 1900 ? 360 : 270;    
  
    this.donutChart5 = new Chart({
      chart: {
        type: 'pie',
        height: 240,
        width: chartWidth,
      },
      title: {
        text: 'PREMIUM',
        style: {
          fontSize: '14px',
          color: '#015963',
          fontFamily: '"Poppins", sans-serif',
          fontWeight: 'bold'
        }
      },
      series: [{
        name: 'Share',
        type: 'pie',
        innerSize: '60%', // Set inner size to make it a donut chart
        data: data1
      }],
      exporting: {
        enabled: false
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          colors: ['#FFBF3F', '#FFA500', '#F9423A', '#A0231D', '#173F35'],
          cursor: 'pointer',
          dataLabels: {
            format: '{point.y}', // Display name and percentage
            distance: 5 // Position data labels outside the pie slices
          },
          size: '100%',
          showInLegend: true
        }
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
      },
      legend: {
        enabled: true,
        layout: 'horizontal',
        align: 'center',
        verticalAlign: 'bottom',
        itemStyle: {
          fontFamily: '"Poppins", sans-serif',
          fontSize: '12px',
          color: '#000'
        },
        symbolHeight: 10, // Set symbol height to make it a square
        symbolWidth: 10,  // Set symbol width to make it a square
        symbolRadius: 0 // Make legend symbol square
      }
    });  
  }

  DonutChart6() {    
    const data1 = [
      { name: 'Provider 1', y: 15 },
      { name: 'Provider 2', y: 10 },
      { name: 'Provider 3', y: 5 },
      { name: 'Provider 4', y: 10 },
      { name: 'Provider 5', y: 40 }
    ];

    const chartWidth = window.innerWidth > 1900 ? 360 : 270;    
  
    this.donutChart6 = new Chart({
      chart: {
        type: 'pie',
        height: 240,
        width: chartWidth,
      },
      title: {
        text: 'PRODUCT',
        style: {
          fontSize: '14px',
          color: '#015963',
          fontFamily: '"Poppins", sans-serif',
          fontWeight: 'bold'
        }
      },
      series: [{
        name: 'Share',
        type: 'pie',
        innerSize: '60%', // Set inner size to make it a donut chart
        data: data1
      }],
      exporting: {
        enabled: false
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          colors: ['#FFBF3F', '#FFA500', '#F9423A', '#A0231D', '#173F35'],
          cursor: 'pointer',
          dataLabels: {
            format: '{point.y}%', // Display name and percentage
            distance: 5 // Position data labels outside the pie slices
          },
          size: '100%',
          showInLegend: true
        }
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
      },
      legend: {
        enabled: true,
        layout: 'horizontal',
        align: 'center',
        verticalAlign: 'bottom',
        itemStyle: {
          fontFamily: '"Poppins", sans-serif',
          fontSize: '12px',
          color: '#000'
        },
        symbolHeight: 10, // Set symbol height to make it a square
        symbolWidth: 10,  // Set symbol width to make it a square
        symbolRadius: 0 // Make legend symbol square
      }
    });  
  }

  DonutChart7() {    
    const data1 = [
      { name: 'Provider 1', y: 1500 },
      { name: 'Provider 2', y: 1000 },
      { name: 'Provider 3', y: 500 },
      { name: 'Provider 4', y: 2000 },
      { name: 'Provider 5', y: 5000 }
    ];

    const chartWidth = window.innerWidth > 1900 ? 360 : 270;    
  
    this.donutChart7 = new Chart({
      chart: {
        type: 'pie',
        height: 240,
        width: chartWidth,
      },
      title: {
        text: 'PREMIUM',
        style: {
          fontSize: '14px',
          color: '#015963',
          fontFamily: '"Poppins", sans-serif',
          fontWeight: 'bold'
        }
      },
      series: [{
        name: 'Share',
        type: 'pie',
        innerSize: '60%', // Set inner size to make it a donut chart
        data: data1
      }],
      exporting: {
        enabled: false
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          colors: ['#FFBF3F', '#FFA500', '#F9423A', '#A0231D', '#173F35'],
          cursor: 'pointer',
          dataLabels: {
            format: '{point.y}', // Display name and percentage
            distance: 5 // Position data labels outside the pie slices
          },
          size: '100%',
          showInLegend: true
        }
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
      },
      legend: {
        enabled: true,
        layout: 'horizontal',
        align: 'center',
        verticalAlign: 'bottom',
        itemStyle: {
          fontFamily: '"Poppins", sans-serif',
          fontSize: '12px',
          color: '#000'
        },
        symbolHeight: 10, // Set symbol height to make it a square
        symbolWidth: 10,  // Set symbol width to make it a square
        symbolRadius: 0 // Make legend symbol square
      }
    });  
  }

  DonutChart8() {    
    const data1 = [
      { name: 'Provider 1', y: 15 },
      { name: 'Provider 2', y: 10 },
      { name: 'Provider 3', y: 5 },
      { name: 'Provider 4', y: 10 },
      { name: 'Provider 5', y: 40 }
    ];

    const chartWidth = window.innerWidth > 1900 ? 360 : 270;    
  
    this.donutChart8 = new Chart({
      chart: {
        type: 'pie',
        height: 240,
        width: chartWidth,
      },
      title: {
        text: 'PRODUCT',
        style: {
          fontSize: '14px',
          color: '#015963',
          fontFamily: '"Poppins", sans-serif',
          fontWeight: 'bold'
        }
      },
      series: [{
        name: 'Share',
        type: 'pie',
        innerSize: '60%', // Set inner size to make it a donut chart
        data: data1
      }],
      exporting: {
        enabled: false
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          colors: ['#FFBF3F', '#FFA500', '#F9423A', '#A0231D', '#173F35'],
          cursor: 'pointer',
          dataLabels: {
            format: '{point.y}%', // Display name and percentage
            distance: 5 // Position data labels outside the pie slices
          },
          size: '100%',
          showInLegend: true
        }
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
      },
      legend: {
        enabled: true,
        layout: 'horizontal',
        align: 'center',
        verticalAlign: 'bottom',
        itemStyle: {
          fontFamily: '"Poppins", sans-serif',
          fontSize: '12px',
          color: '#000'
        },
        symbolHeight: 10, // Set symbol height to make it a square
        symbolWidth: 10,  // Set symbol width to make it a square
        symbolRadius: 0 // Make legend symbol square
      }
    });  
  }

}
