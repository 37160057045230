<div class="container-scroller">
    
  <!-- Navbar -->
  <nav class="navbar default-layout col-lg-12 col-12 p-0 fixed-top d-flex align-items-top flex-row" *ngIf="restatus == 'true'">
    <div class="text-center navbar-brand-wrapper align-items-center justify-content-start">
      
      <div>
        <a class="navbar-brand brand-logo">
          <!-- <img src="../../../assets/homelogo.png" alt="logo" *ngIf="user_role != 'employeruser' && user_role != 'employeradmin'" />
          <img src="{{broker_logo}}" alt="logo" *ngIf="user_role == 'employeruser' || user_role == 'employeradmin'" /> -->

          <span *ngIf="client_logo == '' || client_logo == null || client_logo == undefined">
            <label title="{{clientname}}" class="namelabel">{{clientname}}</label>                
          </span>

          <span *ngIf="client_logo != ''">
            <img src="../../../assets/homelogo.png" style="margin-top: -40px;" alt="logo" *ngIf="user_role != 'employeruser' && user_role != 'employeradmin' && user_role != 'claimsexecutiveuser' && user_role != 'brokeradmin'" />
            <img src="{{client_logo}}" alt="logo" *ngIf="user_role == 'employeruser' || user_role == 'employeradmin' || user_role == 'claimsexecutiveuser'" />
          </span>
          <img  src="{{broker_logo}}" alt="Profile image" *ngIf="user_role == 'brokeradmin'" style="width: 150px; margin-top: -40px" />

        </a>            
        <!-- <div class="triangle-down"></div>     -->
      </div>      
    </div> 

    <div class="navbar-menu-wrapper d-flex align-items-top">
      <button class="navbar-toggler navbar-toggler align-self-center" type="button" (click)="checknav()" data-bs-toggle="minimize" style="background: #005963;">
        <!-- <span class="icon-menu"></span> -->
        <img src="../../../assets/img/common icons/white_left_arrow.png" style="width: 30px;padding: 7px 3px; border-radius: 8px;" />

      </button>
      <ul class="navbar-nav">
        <li class="nav-item font-weight-semibold d-none d-lg-block ms-0">
          <h6 class="welcome-text">Welcome, 
            <!-- <span class="fw-bold" *ngIf="user_type_id != '10'">{{username}}</span>
            <span class="fw-bold" *ngIf="user_type_id == '10'">{{name}}</span> -->
            <span class="fw-bold">{{name}}</span>            
          </h6>
          <!-- <h6 class="welcome-text" *ngIf="user_type_id != '3'">Welcome, <span class="fw-bold">{{username}}</span></h6> -->
        </li>
        <div class="input-container">
          <input class="form-control" placeholder="Search anything here..." id="searchbox" style="width: 400px; height: 34px; margin-left: 20px; background: rgba(204, 223, 225, 0.5);" />
          <i class="mdi mdi-magnify" title="Search"></i>
        </div>        
      </ul>
      <ul class="navbar-nav ms-auto"> 
        <li>
          <span *ngIf="broker_logo == '' || broker_logo == null || broker_logo == undefined">
            <label title="{{brokername}}" class="namelabel" style="text-align: right;" *ngIf="user_type_id != '4'">{{brokername}}</label>
          </span>

          <span *ngIf="broker_logo != ''">
            <span *ngIf="user_type_id != '4'">
              <img class="img-md rounded-circle" src="assets/images/faces/face8.jpg" alt="Profile image" *ngIf="user_role != 'employeruser' && user_role != 'employeradmin' && user_role != 'claimsexecutiveuser' && user_role != 'brokeradmin'">
            </span>
            <img  src="{{broker_logo}}" alt="Profile image" *ngIf="user_role == 'employeruser' || user_role == 'employeradmin' || user_role == 'claimsexecutiveuser'" style="width: 150px; height: 50px;" />
          </span>
        </li>                   
        <li class="nav-item dropdown d-none d-lg-block user-dropdown">
          <a class="nav-link" id="UserDropdown" href="#" data-bs-toggle="dropdown" aria-expanded="false">
            
            <!-- <img class="img-xs rounded-circle" src="assets/images/faces/face8.jpg" alt="Profile image" *ngIf="user_role != 'employeruser' && user_role != 'employeradmin'"> 
            <img src="{{client_logo}}" alt="logo" alt="Profile image" *ngIf="user_role == 'employeruser' || user_role == 'employeradmin'" style="width: 150px; position: absolute; right: -30px; top: -16px;" /> -->

            <!-- <i class="dropdown-item-icon mdi mdi-power text-primary me-2" style="background-color: #00000017; padding: 6px 6px 2px 6px; border-radius: 10px;"></i>             -->
            <img src="../../../assets/img/notigication.svg" style="margin-right: 20px; width: 20px;" />
            <span style="border-radius: 50px;
            padding: 0px 5px;
            background: #fff;
            border: solid 1px;
            position: absolute;
            right: 52px;            
            top: 4px;
            font-size: 9px;">0</span>
            <img src="../../../assets/img/logout.svg" />

          </a> 

          <div class="dropdown-menu dropdown-menu-right navbar-dropdown" aria-labelledby="UserDropdown">
            <div class="dropdown-header text-center">
              
              <!-- <img class="img-md rounded-circle" src="assets/images/faces/face8.jpg" alt="Profile image" *ngIf="user_role != 'employeruser' && user_role != 'employeradmin' ">
              <img  src="{{broker_logo}}" alt="Profile image" *ngIf="user_role == 'employeruser' || user_role == 'employeradmin'" style="width: 150px;" /> -->

              <!-- <label title="{{brokername}}" class="namelabel" style="font-size: 14px;" *ngIf="brokername != ''">{{brokername}}</label>               -->              
              <p class="mb-1 mt-3 font-weight-semibold">{{username}}</p>
              <p class="fw-light text-muted mb-0">{{user_company}}</p>
            </div>
            <a class="dropdown-item" *ngIf="user_role == 'client'" routerLink="/profile"><i class="dropdown-item-icon mdi mdi-account-outline text-primary me-2"></i> My
              Profile</a>              
            <a class="dropdown-item" (click)="signout()"><i class="dropdown-item-icon mdi mdi-power text-primary me-2"></i>Sign Out</a>
            <a class="dropdown-item"><i class="dropdown-item-icon mdi mdi-account-key-outline text-primary me-2"></i>Change Password</a>
            <a class="dropdown-item"><i class="dropdown-item-icon mdi mdi-account-switch text-primary me-2"></i>Switch User</a>
          </div>                  

        </li>
      </ul>
      <button class="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button"
        data-bs-toggle="offcanvas">
        <span class="mdi mdi-menu"></span>
      </button>
    </div>
  </nav>

  <router-outlet>
        
  </router-outlet>          
  
</div>