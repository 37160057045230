import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { Service } from './service';
import { Subject } from 'rxjs';
import { CommonService } from '../../_services/common.service';

@Component({
  selector: 'app-form-center',
  templateUrl: './form-center.component.html',
  styleUrls: ['./form-center.component.css']
})
export class FormCenterComponent implements OnInit {

  dtOptions: DataTables.Settings = {
    destroy: true,
    scrollX: false,
    columnDefs: [
      { orderable: true, className: 'reorder', targets: 0 },
      { orderable: false, targets: '_all' }
    ]
  };
  dtTrigger: Subject<any> = new Subject<any>();

  resstatus = localStorage.getItem('resstatus');
  pagehide = localStorage.getItem('pagehide');
  useremail = localStorage.getItem('username');

  gmcDoc:any = [];
  opdDoc:any = [];
  gpaDoc:any = [];
  gtlDoc:any = [];
 
  gmcPolicy:any = [];
  opdPolicy:any = [];
  gpaPolicy:any = [];
  gtlPolicy:any = [];

  ngOnInit(): void {

    this.gmcPolicy = [];
    this.getInfo();    
  }

  constructor(
    private fb: FormBuilder,
    private dataservice: Service,
    private route: Router,
    public commonservice: CommonService,

  ) {
    this.route.events.subscribe((e) => {
      if (this.resstatus == 'false') {
          this.route.navigate(['/home']);
      }
    });
  }

  getInfo(){

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id')      
    };

    this.dataservice.getInfo(postdata)
      .subscribe((result) => {  
        
        if(result.status == true){   
          
          this.gmcPolicy = result.data.gmcPolicyArray;
          this.opdPolicy = result.data.opdPolicyArray;
          this.gpaPolicy = result.data.gpaPolicyArray;
          this.gtlPolicy = result.data.gtlPolicyArray;          
          
          this.gmcDoc = result.data.gmcDocArray;
          this.opdDoc = result.data.opdDocArray;
          this.gpaDoc = result.data.gpaDocArray;
          this.gtlDoc = result.data.gtlDocArray;
          this.dtTrigger.next();
        }

      }, (error) => {         
        
        if(error.error.message == 'Unauthenticated.'){
          localStorage.clear();
          localStorage.setItem('resstatus', 'false');
          this.route.navigate(['/home']);
        }
    });
  }

  goback(){
    this.route.navigate(['/flex-configurator-dashboard']);
  }

  getColor(index: number): string {
    const colors = ['#005963', '#C8535E', '#2C2C2C'];
    return colors[index % colors.length];
  }

  ondownload_doc(val:any){
    window.open(val,'_blank');
  }

}
