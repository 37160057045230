import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { SuperAdminDashboard } from './superAdmin/dashboard/dashboard.component';
import { AdminDashboard } from './admin/dashboard/dashboard.component';
import { ClientDashboard } from './client/dashboard/dashboard.component';
import { LoginComponent } from './auth/login/login.component';
import { RegisterComponent } from './auth/register/register.component';
import { HomeComponent } from './home/home.component';
import { ClientCreationComponent } from './client-creation/client-creation.component';
import { PricingComponent } from './client/pricing/pricing.component';
import { ProfileComponent } from './client/profile/profile.component';
import { UsersComponent } from './superAdmin/user mangement/users/users.component';
import { UsersClientComponent } from './superAdmin/user mangement/users-client/users-client.component';
import { EmployeeDependencyComponent } from './employee-dependency/employee-dependency.component';
import { PolicyDetailsComponent } from './policy-details/policy-details.component';
import { VerifymobileComponent } from './auth/verification/verifymobile/verifymobile.component';
import { VerifyemailComponent } from './auth/verification/verifyemail/verifyemail.component';
import { MarketDataComponent } from './superAdmin/market-data/market-data.component';
import { TpaDetailsComponent } from './superAdmin/tpa-details/tpa-details.component';
import { InsuranceCompanyInsurerComponent } from './superAdmin/insurance-company-insurer/insurance-company-insurer.component';
import { SelectProductComponent } from './client/select-product/select-product.component';
import { SelectProductMobileComponent } from './client/select-product-mobile/select-product-mobile.component';
import { ProductDetailsComponent } from './superAdmin/product-mgmt/product-details/product-details.component';
import { SubproductDetailsComponent } from './superAdmin/product-mgmt/subproduct-details/subproduct-details.component';
import { TopupPolicyDetailsComponent } from './topup-policy-details/topup-policy-details.component';
import { GpaPolicyDetailsComponent } from './gpa-policy-details/gpa-policy-details.component';
import { GtlPolicyDetailsComponent } from './gtl-policy-details/gtl-policy-details.component';
import { OpdPolicyDetailsComponent } from './opd-policy-details/opd-policy-details.component';
import { GoToPoliciesComponent } from './go-to-policies/go-to-policies.component';
import { ManageDocumentTypeComponent } from './superAdmin/manage-document-type/manage-document-type.component';

import { PolicyConversionGmcComponent } from './superAdmin/policy-conversion-gmc/policy-conversion-gmc.component';
import { PolicyConversionGpaComponent } from './superAdmin/policy-conversion-gpa/policy-conversion-gpa.component';
import { PolicyConversionGtlComponent } from './superAdmin/policy-conversion-gtl/policy-conversion-gtl.component';
import { PolicyConversionOpdComponent } from './superAdmin/policy-conversion-opd/policy-conversion-opd.component';

import { PolicyFinalizeGmcComponent } from './superAdmin/policy-finalize-gmc/policy-finalize-gmc.component';
import { PolicyFinalizeGpaComponent } from './superAdmin/policy-finalize-gpa/policy-finalize-gpa.component';
import { PolicyFinalizeGtlComponent } from './superAdmin/policy-finalize-gtl/policy-finalize-gtl.component';
import { PolicyFinalizeOpdComponent } from './superAdmin/policy-finalize-opd/policy-finalize-opd.component';

import { SurveyLoginComponent } from './survey/auth/survey-login/survey-login.component';
import { SurveyRegisterComponent } from './survey/auth/survey-register/survey-register.component';
import { SurveyEmailVerificationComponent } from './survey/auth/survey-verification/survey-email-verification/survey-email-verification.component';

import { SurveySelectProductComponent } from './survey/survey-select-product/survey-select-product.component';

import { SurveyGmcPolicyDetailsComponent } from './survey/survey-gmc-policy-details/survey-gmc-policy-details.component';
import { SurveyGpaPolicyDetailsComponent } from './survey/survey-gpa-policy-details/survey-gpa-policy-details.component';
import { SurveyGtlPolicyDetailsComponent } from './survey/survey-gtl-policy-details/survey-gtl-policy-details.component';
import { SurveyOpdPolicyDetailsComponent } from './survey/survey-opd-policy-details/survey-opd-policy-details.component';

import { AboutUsComponent } from './website/about-us/about-us.component';
import { ContactUsComponent } from './website/contact-us/contact-us.component';
import { ScheduleDemoComponent } from './website/schedule-demo/schedule-demo.component';
import { AboutCompanyComponent } from './website/about-company/about-company.component';
import { LeadershipTeamComponent } from './website/leadership-team/leadership-team.component';
import { WhyNeodigitComponent } from './website/why-neodigit/why-neodigit.component';
import { VisionMissionComponent } from './website/vision-mission/vision-mission.component';
import { EbPortalComponent } from './website/eb-portal/eb-portal.component';
import { HealthBenefitsToolComponent } from './website/health-benefits-tool/health-benefits-tool.component';
import { BenefitsConsultingComponent } from './website/benefits-consulting/benefits-consulting.component';
import { AutomationComponent } from './website/automation/automation.component';
import { CommunicationComponent } from './website/communication/communication.component';
import { WellbeingComponent } from './website/wellbeing/wellbeing.component';
import { CustomizedSolutionComponent } from './website/customized-solution/customized-solution.component';
import { AffinityComponent } from './website/affinity/affinity.component';

import { BenefitBenchmarkingComponent } from './survey/benefit-benchmarking/benefit-benchmarking.component';

import { SurveyManagerComponent } from './superAdmin/survey-manager/survey-manager.component';

import { ManageCountryComponent } from './superAdmin/manage-country/manage-country.component';
import { ManageStateComponent } from './superAdmin/manage-state/manage-state.component';
import { ManageCityComponent } from './superAdmin/manage-city/manage-city.component';
import { ManageBrokerComponent } from './superAdmin/manage-broker/manage-broker.component';
import { ManageIndustryComponent } from './superAdmin/manage-industry/manage-industry.component';
import { ManageUserTypesComponent } from './superAdmin/manage-user-types/manage-user-types.component';
import { ManagePlanComponent } from './superAdmin/manage-plan/manage-plan.component';
import { ManageAnnoucementsComponent } from './superAdmin/manage-annoucements/manage-annoucements.component';
import { ManageNewsComponent } from './superAdmin/manage-news/manage-news.component';
import { MasterBrokerComponent } from './superAdmin/master-broker/master-broker.component';
import { AddUserComponent } from './superAdmin/master-broker/add-user/add-user.component';
import { AdminthemeComponent } from './superAdmin/admintheme/admintheme.component';

//broker
import { DashboardComponent } from './broker/dashboard/dashboard.component';
import { EmployerDashboardComponent } from './broker/employer-dashboard/employer-dashboard.component';
import { BrokerManageMasterEmployerComponent } from './broker/broker-manage-master-employer/broker-manage-master-employer.component';
import { AddBrokerUserComponent } from './broker/add-broker-user/add-broker-user.component';
import { UserManagementComponent } from './broker/user-management/user-management.component';
import { EmployeeUserManagementComponent } from './broker/employee-user-management/employee-user-management.component';
import { BrokerManageMasterComponent } from './broker/broker-manage-master/broker-manage-master.component';
import { AdminmastermodulesComponent } from './superAdmin/adminmastermodules/adminmastermodules.component';
import { SubModulesComponent } from './superAdmin/adminmastermodules/sub-modules/sub-modules.component';

import { HrInfoDashboardComponent } from './hr_info/hr-info-dashboard/hr-info-dashboard.component';

import { BrokeruserDashboardComponent } from './broker/brokeruser-dashboard/brokeruser-dashboard.component';
import { EmployeruserDashboardComponent } from './broker/employeruser-dashboard/employeruser-dashboard.component';

import { PoliciesComponent } from './superAdmin/policies/policies.component';
import { PolicieslistingComponent } from './superAdmin/policieslisting/policieslisting.component';
import { EndorsementrequestComponent } from './broker/endorsementrequest/endorsementrequest.component';
import { EndoresementRequestComponent } from './broker/endoresement-request/endoresement-request.component';

import { FamilyContructComponent } from './broker/employeruser-dashboard/family-contruct/family-contruct.component';
import { EmployeeBenefitComponent } from './broker/employeruser-dashboard/employee-benefit/employee-benefit.component';
import { FamilyEnrollmentComponent } from './broker/employeruser-dashboard/family-contruct/family-enrollment/family-enrollment.component';

import { BrokerManageMasterEditComponent } from './broker/broker-manage-master/broker-manage-master-edit/broker-manage-master-edit.component';
import { BrokerManageMasterViewComponent } from './broker/broker-manage-master/broker-manage-master-view/broker-manage-master-view.component';

import { PolicyEditComponent } from './broker/policy-edit/policy-edit.component';

import { ClaimStatusComponent } from './broker/claim-status/claim-status.component';
import { ClaimIntimationComponent } from './broker/claim-intimation/claim-intimation.component';
import { ClaimDocumentComponent } from './broker/claim-document/claim-document.component';

import { ClaimsexecutiveuserDashboardComponent } from './broker/add-broker-user/claimsexecutiveuser-dashboard/claimsexecutiveuser-dashboard.component';

import { ClaimIntimationClientexetutiveuserComponent } from './broker/claim-intimation-clientexetutiveuser/claim-intimation-clientexetutiveuser.component';
import { ProductFeatureMasterComponent } from './broker/product-feature-master/product-feature-master.component';

import { FlexConfiguratorComponent } from './broker/flex-configurator/flex-configurator.component';
import { FlexConfiguratorEditComponent } from './broker/flex-configurator-edit/flex-configurator-edit.component';

import { PolicyDetailsFamilyComponent } from './broker/employeruser-dashboard/family-contruct/family-enrollment/policy-details-family/policy-details-family.component';

import { BrokerInsuranceCompanyComponent } from './broker/broker-insurance-company/broker-insurance-company.component';
import { NomineeUploadComponent } from './broker/nominee-upload/nominee-upload.component';

// Client user execute lisiting
import { ListingComponent } from './broker/claim-intimation-clientexetutiveuser/listing/listing.component';
import { UploadPolicyCoverageComponent } from './broker/upload-policy-coverage/upload-policy-coverage.component';
import { EndorsmentReportComponent } from './broker/endorsment-report/endorsment-report.component';
import { FlexConfiguratorDashboardComponent } from './broker/flex-configurator-dashboard/flex-configurator-dashboard.component';

// Employee user
import { FlexPlanComponent } from './broker/flex-plan/flex-plan.component';
import { FlexPlanSingleComponent } from './broker/flex-plan/flex-plan-single/flex-plan-single.component';
import { FlexPlanMultipleComponent } from './broker/flex-plan/flex-plan-multiple/flex-plan-multiple.component';
import { FlexPlanSummaryComponent } from './broker/flex-plan/flex-plan-summary/flex-plan-summary.component';
import { MultiplePlanComponent } from './broker/flex-plan/multiple-plan/multiple-plan.component';
import { CarInsuranceComponent } from './broker/car-insurance/car-insurance.component';
import { HealthInsuranceComponent } from './broker/health-insurance/health-insurance.component';
import { WellBeingComponent } from './broker/well-being/well-being.component';
import { ContactUsInfoComponent } from './broker/contact-us-info/contact-us-info.component';
import { SinglePlanComponent } from './broker/flex-plan/single-plan/single-plan.component';
import { WellnessComponent } from './broker/wellness/wellness.component';
import { WellnessBenefitComponent } from './broker/wellness-benefit/wellness-benefit.component';
import { NetworkHospitalComponent } from './broker/network-hospital/network-hospital.component';
import { EmployerBenefitWellnessComponent } from './broker/employer-benefit-wellness/employer-benefit-wellness.component';
import { AncillaryComponent } from './broker/ancillary/ancillary.component';
import { AncillaryDetailsComponent } from './broker/ancillary-details/ancillary-details.component';
import { NetworksComponent } from './broker/networks/networks.component';
import { FormCenterComponent } from './broker/form-center/form-center.component';
import { BrokerNetworksComponent } from './broker/broker-networks/broker-networks.component';
import { BrokerTpaTriggerComponent } from './broker/broker-tpa-trigger/broker-tpa-trigger.component';
import { BrokerTpaTriggerLogsComponent } from './broker/broker-tpa-trigger-logs/broker-tpa-trigger-logs.component';
import { BrokerIntimateClaimsComponent } from './broker/broker-intimate-claims/broker-intimate-claims.component';
import { OverallClaimsComponent } from './broker/overall-claims/overall-claims.component';
import { EmployeeEcardComponent } from './broker/employee-ecard/employee-ecard.component';
import { EmployerEcardComponent } from './broker/employer-ecard/employer-ecard.component';
import { BrokerEcardComponent } from './broker/broker-ecard/broker-ecard.component';
import { EmployeeFaqComponent } from './broker/employee-faq/employee-faq.component';
import { BrokerEcardUploadComponent } from './broker/broker-ecard-upload/broker-ecard-upload.component';
import { EcardFileUploadComponent } from './broker/ecard-file-upload/ecard-file-upload.component';
import { EcardDetailsComponent } from './broker/ecard-details/ecard-details.component';
import { ClaimViewComponent } from './broker/claim-view/claim-view.component';
import { BrokerReportComponent } from './broker/broker-report/broker-report.component';
import { FlexReportComponent } from './broker/flex-report/flex-report.component';
import { ClaimPortalComponent } from './broker/claim-portal/claim-portal.component';

import { DashboardAncillaryComponent } from './broker/dashboard-ancillary/dashboard-ancillary.component';
import { EmployeeClaimsPortalComponent } from './broker/employee-claims-portal/employee-claims-portal.component';
import { EmployeeIntimateClaimsComponent } from './broker/employee-intimate-claims/employee-intimate-claims.component';
import { CdUpdationComponent } from './broker/cd-updation/cd-updation.component';
import { BenchmarkingReportComponent } from './broker/benchmarking-report/benchmarking-report.component';
import { ClaimsUtilizationAnalysisComponent } from './broker/claims-utilization-analysis/claims-utilization-analysis.component';

// Saml2
import { Saml2Component } from './auth/saml2/saml2.component';
import { LogoutComponent } from './commonComponents/logout/logout.component';

import { GmcFlexPlanComponent } from './broker/flex-plan/gmc-flex-plan/gmc-flex-plan.component';
import { OpdFlexPlanComponent } from './broker/flex-plan/opd-flex-plan/opd-flex-plan.component';
import { GpaFlexPlanComponent } from './broker/flex-plan/gpa-flex-plan/gpa-flex-plan.component';
import { GtlFlexPlanComponent } from './broker/flex-plan/gtl-flex-plan/gtl-flex-plan.component';

import { FaqModuleComponent } from './broker/faq-module/faq-module.component';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'SuperAdmin-dashboard', component: SuperAdminDashboard },
  { path: 'Client-dashboard', component: ClientDashboard },
  { path: 'Admin-dashboard', component: AdminDashboard },
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'home', component: HomeComponent },
  { path: 'clientCreation', component: ClientCreationComponent },
  { path: 'pricing', component: PricingComponent },
  { path: 'profile', component: ProfileComponent },
  { path: 'admin-users', component: UsersComponent },
  { path: 'admin-users-Client', component: UsersClientComponent },
  { path: 'employee-Dependency', component: EmployeeDependencyComponent },
  { path: 'policy-details', component: PolicyDetailsComponent },
  { path: 'verify-mobile', component: VerifymobileComponent },
  { path: 'verify-email', component: VerifyemailComponent },
  { path: 'admin-market-data', component: MarketDataComponent },
  { path: 'admin-tpa-details', component: TpaDetailsComponent },
  { path: 'admin-insurance-company-insurer', component: InsuranceCompanyInsurerComponent },
  { path: 'select-product', component: SelectProductComponent },
  { path: 'select-product-mobile', component: SelectProductMobileComponent },
  { path: 'admin-product-details', component: ProductDetailsComponent },
  { path: 'admin-subproduct-details', component: SubproductDetailsComponent },
  { path: 'topup-policy-details', component: TopupPolicyDetailsComponent },
  { path: 'gpa-policy-details', component: GpaPolicyDetailsComponent },
  { path: 'gtl-policy-details', component: GtlPolicyDetailsComponent },
  { path: 'opd-policy-details', component: OpdPolicyDetailsComponent },
  { path: 'go-to-policies', component: GoToPoliciesComponent },
  { path: 'admin-policy-conversion-gmc', component: PolicyConversionGmcComponent },
  { path: 'admin-policy-conversion-gpa', component: PolicyConversionGpaComponent },
  { path: 'admin-policy-conversion-gtl', component: PolicyConversionGtlComponent },
  { path: 'admin-policy-conversion-opd', component: PolicyConversionOpdComponent },
  { path: 'admin-policy-finalize-gmc', component: PolicyFinalizeGmcComponent},
  { path: 'admin-policy-finalize-gpa', component: PolicyFinalizeGpaComponent},
  { path: 'admin-policy-finalize-gtl', component: PolicyFinalizeGtlComponent},
  { path: 'admin-policy-finalize-opd', component: PolicyFinalizeOpdComponent},
  { path: 'survey-login', component: SurveyLoginComponent},
  { path: 'survey-register', component: SurveyRegisterComponent},
  { path: 'survey-verify-email', component: SurveyEmailVerificationComponent},
  { path: 'survey-select-product', component: SurveySelectProductComponent},
  { path: 'survey-gmc-policy-details', component: SurveyGmcPolicyDetailsComponent},
  { path: 'survey-gpa-policy-details', component: SurveyGpaPolicyDetailsComponent},
  { path: 'survey-gtl-policy-details', component: SurveyGtlPolicyDetailsComponent},
  { path: 'survey-opd-policy-details', component: SurveyOpdPolicyDetailsComponent},
  { path: 'about-us', component: AboutUsComponent},
  { path: 'contact-us', component: ContactUsComponent},
  { path: 'about-company', component: AboutCompanyComponent },
  { path: 'leadership-team', component: LeadershipTeamComponent },
  { path: 'why-neodigit', component: WhyNeodigitComponent },
  { path: 'vision-mission', component: VisionMissionComponent },
  { path: 'eb-portal', component: EbPortalComponent },
  { path: 'health-benefits-tool', component: HealthBenefitsToolComponent },
  { path: 'benefits-consulting', component: BenefitsConsultingComponent },
  { path: 'automation', component: AutomationComponent },
  { path: 'communication', component: CommunicationComponent },
  { path: 'wellbeing', component: WellbeingComponent },
  { path: 'customized-solution', component: CustomizedSolutionComponent },
  { path: 'affinity', component: AffinityComponent },

  { path: 'gmc-flex-plan', component: GmcFlexPlanComponent },
  { path: 'opd-flex-plan', component: OpdFlexPlanComponent },
  { path: 'gpa-flex-plan', component: GpaFlexPlanComponent },
  { path: 'gtl-flex-plan', component: GtlFlexPlanComponent },

  { path: 'schedule-demo', component: ScheduleDemoComponent},
  { path: 'benefit-benchmarking', component: BenefitBenchmarkingComponent},
  { path: 'admin-survey-manager', component: SurveyManagerComponent},
  { path: 'admin-manage-country', component: ManageCountryComponent},
  { path: 'admin-manage-state', component: ManageStateComponent},
  { path: 'admin-manage-city', component: ManageCityComponent},
  { path: 'admin-manage-broker', component: ManageBrokerComponent},
  { path: 'admin-manage-industry', component: ManageIndustryComponent},
  { path: 'admin-manage-user-types', component: ManageUserTypesComponent},
  { path: 'admin-manage-plan', component: ManagePlanComponent},
  { path: 'admin-manage-annoucements', component: ManageAnnoucementsComponent},
  { path: 'admin-manage-news', component: ManageNewsComponent},
  { path: 'admin-document-type', component: ManageDocumentTypeComponent},
  { path: 'broker-dashboard', component: DashboardComponent},
  { path: 'admin-manage-master-broker', component: MasterBrokerComponent},
  { path: 'admin-manage-master-broker/broker-user', component: AddUserComponent},
  { path: 'broker-manage-master-employer', component: BrokerManageMasterEmployerComponent},
  { path: 'employer-dashboard', component: EmployerDashboardComponent},
  { path: 'broker-manage-master-employer/employer-user', component: AddBrokerUserComponent},
  { path: 'admin-manage-theme', component: AdminthemeComponent},
  { path: 'admin-mastermodules', component: AdminmastermodulesComponent},
  { path: 'broker-manage-master-employer/user-management', component: UserManagementComponent},
  { path: 'employee-user-management', component: EmployeeUserManagementComponent},
  { path: 'broker-manage-master', component: BrokerManageMasterComponent},
  { path: 'admin-mastermodules/sub-modules', component: SubModulesComponent},
  { path: 'hr-info-dashboard', component: HrInfoDashboardComponent},
  { path: 'brokeruser-dashboard', component: BrokeruserDashboardComponent},
  { path: 'employeruser-dashboard', component: EmployeruserDashboardComponent},
  { path: 'policies', component: PoliciesComponent},
  { path: 'listpolicies', component: PolicieslistingComponent},
  { path: 'member-upload', component: EndorsementrequestComponent},
  { path: 'endoresement-request', component: EndoresementRequestComponent},
  { path: 'employeruser-dashboard/family-contruct', component: FamilyContructComponent},
  { path: 'employeruser-dashboard/employee_benefit', component: EmployeeBenefitComponent},
  { path: 'employeruser-dashboard/family-enrollment', component: FamilyEnrollmentComponent},    
  { path: 'broker-manage-master-edit', component: BrokerManageMasterEditComponent},
  { path: 'broker-manage-master-view', component: BrokerManageMasterViewComponent},
  { path: 'policy-edit', component: PolicyEditComponent},
  { path: 'claim-status', component: ClaimStatusComponent},
  { path: 'claim-intimation', component: ClaimIntimationComponent},
  { path: 'claim-document', component: ClaimDocumentComponent},
  { path: 'claimsexecutiveuser-dashboard', component: ClaimsexecutiveuserDashboardComponent},
  { path: 'executive-user/claim-intimation', component: ClaimIntimationClientexetutiveuserComponent},
  { path: 'product-feature-master', component: ProductFeatureMasterComponent},
  { path: 'flex-configurator', component: FlexConfiguratorComponent},
  { path: 'flex-configurator-edit', component: FlexConfiguratorEditComponent},
  { path: 'employeruser-dashboard/family-enrollment/policy-details-family', component: PolicyDetailsFamilyComponent}, 
  { path: 'broker-insurance-company', component: BrokerInsuranceCompanyComponent}, 
  { path: 'upload-policy-coverage', component: UploadPolicyCoverageComponent},
  { path: 'endoresement-report', component: EndorsmentReportComponent},
  { path: 'flex-configurator-dashboard', component: FlexConfiguratorDashboardComponent},
  { path: 'nominee-upload', component: NomineeUploadComponent},
  { path: 'flex-plan', component: FlexPlanComponent},
  { path: 'flex-plan-single', component: FlexPlanSingleComponent},
  { path: 'flex-plan-multiple', component: FlexPlanMultipleComponent},
  { path: 'authsaml/:id', component: Saml2Component},
  { path: 'logout', component: LogoutComponent},
  { path: 'flex-plan-summary', component: FlexPlanSummaryComponent},
  { path: 'multiple-plan', component: MultiplePlanComponent},
  { path: 'insurance', component: CarInsuranceComponent},
  { path: 'health-insurance', component: HealthInsuranceComponent},
  { path: 'well-being', component: WellBeingComponent},
  { path: 'contact-us-info', component: ContactUsInfoComponent},
  { path: 'single-plan', component: SinglePlanComponent},
  { path: 'wellness', component: WellnessComponent},
  { path: 'wellness-benefit', component: WellnessBenefitComponent},
  { path: 'employer-wellness-benefit', component: EmployerBenefitWellnessComponent},
  { path: 'network-hospital', component: NetworkHospitalComponent},
  { path: 'broker-ancillary', component: AncillaryComponent},
  { path: 'broker-ancillary-details', component: AncillaryDetailsComponent},
  { path: 'networks', component: NetworksComponent},
  { path: 'broker-networks', component: BrokerNetworksComponent},
  { path: 'form-center', component: FormCenterComponent},
  { path: 'broker-tpa-trigger', component: BrokerTpaTriggerComponent},
  { path: 'broker-tpa-trigger-logs', component: BrokerTpaTriggerLogsComponent},
  { path: 'broker-intimate-claims', component: BrokerIntimateClaimsComponent},
  { path: 'overall-claims', component: OverallClaimsComponent},
  { path: 'portal-claims', component: ClaimPortalComponent},
  { path: 'employee-ecard', component: EmployeeEcardComponent},
  { path: 'employer-ecard', component: EmployerEcardComponent},
  { path: 'broker-report', component: BrokerReportComponent},
  { path: 'broker-ecard', component: BrokerEcardComponent},
  { path: 'broker-ecard-upload', component: BrokerEcardUploadComponent},
  { path: 'ecard-file-upload', component: EcardFileUploadComponent},
  { path: 'ecard-details', component: EcardDetailsComponent},
  { path: 'employee-faq', component: EmployeeFaqComponent},
  { path: 'claim-view', component: ClaimViewComponent},
  { path: 'flex-report', component: FlexReportComponent},
  { path: 'personal-care', component: DashboardAncillaryComponent},
  { path: 'employee-claim-portal', component: EmployeeClaimsPortalComponent},
  { path: 'employee-intimate-claims', component: EmployeeIntimateClaimsComponent},
  { path: 'cd-updation', component: CdUpdationComponent},
  { path: 'benchmarking-report', component: BenchmarkingReportComponent},
  { path: 'claims-utilization-analysis', component: ClaimsUtilizationAnalysisComponent},
  { path: 'faq-module', component: FaqModuleComponent},
  
  // Client user execute lisiting
  { path: 'client-user-listing', component: ListingComponent}, 
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
