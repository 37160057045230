import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { Service } from './service';
import { Subject } from 'rxjs';
import * as pbi from 'powerbi-client';


@Component({
  selector: 'app-benchmarking-report',
  templateUrl: './benchmarking-report.component.html',
  styleUrls: ['./benchmarking-report.component.css']
})
export class BenchmarkingReportComponent implements OnInit {

  @ViewChild('reportContainer', { static: true }) reportContainer: ElementRef;

  show = false;
  fullScreen = true;
  template = ``;

  user_role: any;
  validmsg: any;
  resstatus = localStorage.getItem('resstatus');
  type_name = localStorage.getItem('type_name');
  username = localStorage.getItem('username');

  embedUrl:any;
  AccessBearerToken:any;
  report_id:any;

  constructor(private fb: FormBuilder, private dataservice: Service, private route: Router) {
    this.route.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.user_role = localStorage.getItem('type_name');
      }
      if (this.resstatus == null) {
        this.route.navigate(['/home']);
      }
    });
  }

  ngOnInit(): void {
    this.getInfo();
  }

  getInfo() {
    var postdata = {
      "tokenable_id": localStorage.getItem('tokenable_id')      
    };

    this.dataservice.getInfo(postdata)
      .subscribe((result) => {
        if (result.status == true) {
          this.embedUrl = result.data.embedUrl;
          this.AccessBearerToken = result.data.AccessBearerToken;
          this.report_id = result.data.report_id;
          this.embedPowerBIReport();
        }
      }, (error) => {
        if (error.error.message == 'Unauthenticated.') {
          localStorage.clear();
          localStorage.setItem('resstatus', 'false');
          this.route.navigate(['/home']);
        }
      });
  }

  embedPowerBIReport(): void {
    const embedConfig: pbi.IEmbedConfiguration = {
      type: 'report',
      id: this.report_id,
      embedUrl: this.embedUrl,
      accessToken: this.AccessBearerToken, // Replace with your Power BI access token
      tokenType: pbi.models.TokenType.Embed,
      settings: {
        filterPaneEnabled: false,
        navContentPaneEnabled: true,
      }
    };

    const powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
    powerbi.embed(this.reportContainer.nativeElement, embedConfig);
  }

}
