<!-- Body -->
<div class="container-fluid page-body-wrapper">

    <!-- Sidebar -->
    <app-sidebar></app-sidebar>

    <!-- Main Wrapper -->
    <div class="main-panel">
        <div class="content-wrapper pt-3 cardbg">
            <ngx-loader [show]="show" [fullScreen] = "fullScreen" [template]="template" style="z-index: 500000; position: absolute;"> Loading... </ngx-loader>

            <h3 class="bs-title">{{page_title}}</h3>      
            <hr class="mt-0" style="height: 0px">     
            
            <!-- Headings -->
            <div class="row mt-3">

                <!-- Image -->
                <div class="col-sm-3">
                    <img src="../../../assets/img/employee/OPD 10.png" style="width: 100%;" alt="image" />
                </div>

                <div class="col-sm-9"> 

                    <div class="p-3" style="border-radius: 8px;" [ngClass]="{'gmcbg': policyname_color == 'gmc_dropdown' || policyname_color == 'gmc_topup_dropdown' || policyname_color == 'gmc_dropdown_other', 
                    'opdbg': policyname_color == 'opd_dropdown' || policyname_color == 'opd_topup_dropdown',
                    'gpabg': policyname_color == 'gpa_dropdown' || policyname_color == 'gpa_topup_dropdown', 
                    'gtlbg': policyname_color == 'gtl_dropdown' ||  policyname_color == 'gtl_topup_dropdown'}">
                        <div class="row m-0" style="border: dashed 2px #fff; padding: 10px;">
                            <div class="col-sm-4 mr-0 text-center text-white" style="border-right: dashed 2px #fff; display: flex;">
                                <img src="../../../../assets/img/employee/emp_pays.png" style="width: 70px; height: 40px; position: relative; top: 3px; margin-right: 20px;" alt="image" />
                                <div>
                                    <label>Employer pays </label><br>
                                    <label *ngIf="hide_employer_contribution == 0"> {{employer_pay_premium != undefined ? employer_pay_premium : 0}} </label>
                                    <label *ngIf="hide_employer_contribution == 1"> - </label>
                                </div>                                
                            </div>
                            <div class="col-sm-4 mr-0 text-center text-white" style="border-right: dashed 2px #fff; display: flex;">
                                <img src="../../../../assets/img/employee/you_pay.png" style="width: 70px; height: 40px; position: relative; top: 3px; margin-right: 10px;" alt="image" />
                                <div>
                                    <label>You Pay Premium   </label><br>
                                    <label>{{commonservice.moneyformatter(you_pay_premium != undefined ? you_pay_premium : 0)}}
                                        <span *ngIf="you_pay_premium != undefined ? you_pay_premium : 0">(</span>
                                        {{ extractedString != undefined ? extractedString : tax }}
                                        <span *ngIf="you_pay_premium != undefined ? you_pay_premium : 0">)</span>
                                    </label>
                                    <!-- <label>{{commonservice.moneyformatter(you_pay_premium)}} </label> -->
                                </div>
                            </div>
                            <div class="col-sm-4 text-center text-white" style="display: flex;">
                                <img src="../../../../assets/img/employee/purchase.png" style="width: 70px; height: 40px; position: relative; top: 3px; margin-right: 20px;" alt="image" />
                                <div>
                                    <label>Total benefits cost</label><br>
                                    <!-- <label>{{my_purchase_value}} </label> -->
                                    <label>{{commonservice.moneyformatter(my_purchase_value != undefined ? my_purchase_value : 0)}}
                                        <span *ngIf="my_purchase_value != undefined ? my_purchase_value : 0">(</span>
                                        {{ extractedString != undefined ? extractedString : tax }}
                                        <span *ngIf="my_purchase_value != undefined ? my_purchase_value : 0">)</span>
                                    </label>
                                </div>                                
                            </div>
                        </div>
                    </div>

                    <!-- Description -->
                    <p class="mt-3">
                        {{page_desc}}
                        <!-- Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's 
                        standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to 
                        make a type specimen book. It has survived not only five centuries,Add Voluntary OPD Benefits to claim Teleconsultation 
                        in-Clinic Consultation, Dental, Vision, Diagnostic
                        EAP standalone, Max Limit of  <b><span style="color: var(--bs-sidebar);">1,00,000/-</span></b> -->
                    </p>
                                        
                </div>                
                                
            </div>                       

            <!-- Table -->
            <!-- <table class="table table-striped" id="pricingTable" style="table-layout: fixed;">
                <thead>
                    <tr style="border: none; background: none;">
                        <th style="background: none; border: none;"></th>
                        <ng-container *ngFor="let plan of plan_Array; let i = index;">
                            <th class="nonselectedcolumn" [style.background-color]="plan.bgcolor" [style.color]="plan.color">
                                <input type="radio" id="plan_{{i}}" name="plan" class="custom_radio"
                                (click)="handleCheckbox(i,0)" />
                                {{ plan.plan_name }}
                            </th>
                        </ng-container>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let row of TableArray">
                        <td>{{ row.title }}</td>    
                      <ng-container *ngFor="let column of getColumns()">
                        <ng-container *ngIf="column !== 'description' && column !== 'master_id'">
                          <td style="white-space: break-spaces;">{{ row[column] }}</td>
                        </ng-container>
                      </ng-container>
                    </tr>
                </tbody>                
              </table>               -->

              <table class="table table-striped" id="pricingTable" style="table-layout: fixed;">
                <thead>
                  <tr style="border: none; background: none;">
                    <th style="background: none; border: none;"></th>
                    <ng-container *ngFor="let plan of plan_Array; let i = index;">
                      <th class="nonselectedcolumn" [style.background-color]="plan.bgcolor" [style.color]="plan.color">
                        <input type="checkbox" id="plan_{{i}}" name="plan" class="custom_checkbox" (click)="handleCheckbox(i, 0)" />
                        {{ plan.plan_name }}
                      </th>
                    </ng-container>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of TableArray">
                    <td>{{ row.title }}</td>
                    <ng-container *ngFor="let column of getColumns()">
                      <ng-container *ngIf="column !== 'description' && column !== 'master_id'">
                        <td [innerHTML]="row[column].includes('Premium') ? row[column].split('Premium').join('<br/>Premium') : (row[column])" style="line-height: 18px; white-space: break-spaces;"></td>
                      </ng-container>
                    </ng-container>
                  </tr>
                </tbody>
              </table>
                            

            <!-- Disclaimer -->
            <div class="my-3">
                <div class="accordion" id="myAccordion">
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingOne">
                            <button type="button" class="accordion-button" data-bs-toggle="collapse" data-bs-target="#collapseOne" [ngClass]="{'gmcbg': policyname_color == 'gmc_dropdown' || policyname_color == 'gmc_topup_dropdown' || policyname_color == 'gmc_dropdown_other', 
                            'opdbg': policyname_color == 'opd_dropdown' || policyname_color == 'opd_topup_dropdown',
                            'gpabg': policyname_color == 'gpa_dropdown' || policyname_color == 'gpa_topup_dropdown', 
                            'gtlbg': policyname_color == 'gtl_dropdown' ||  policyname_color == 'gtl_topup_dropdown'}"  style="color: white;">
                                *Disclaimer
                            </button>
                        </h2>
                        <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#myAccordion">
                            <div class="card-body accorCardbody" style="font-size: 13px;">
                                {{disclaimer}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            
            <!-- Buttons -->
            <div>
                <button class="btn btn-primary" style="float: right; margin-left: 10px;" 
                        [ngClass]="{'gmcbg': policyname_color == 'gmc_dropdown' || policyname_color == 'gmc_topup_dropdown' || policyname_color == 'gmc_dropdown_other', 
                        'opdbg': policyname_color == 'opd_dropdown' || policyname_color == 'opd_topup_dropdown',
                        'gpabg': policyname_color == 'gpa_dropdown' || policyname_color == 'gpa_topup_dropdown', 
                        'gtlbg': policyname_color == 'gtl_dropdown' ||  policyname_color == 'gtl_topup_dropdown'}"                        
                        (click)="gotoflagfun()">Confirm</button>    
                <label style="color: #005963; float: right; position: relative; top: 10px;"><b>Additional To Pay - {{commonservice.moneyformatter(additional_pay != undefined ? additional_pay : 0)}}/-</b></label>

                <button class="btn btn-primary" style="background-color: #2C2C2C; color:#fff;" (click)="gotoback()">
                    <!-- <img src="../../../../assets/img/employee/v_back.png" alt="img" style="margin-right: 5px;" /> -->
                    Back
                </button>

                <button class="btn btn-primary" style="background-color: #2C2C2C; color:#fff; margin-left: 10px;" (click)="skipfun()">
                    <!-- <img src="../../../../assets/img/employee/v_skip.png" alt="img" style="margin-right: 5px;" /> -->
                    Skip
                </button>

                <!-- <button class="btn btn-primary" style="background-color: #2C2C2C; color:#fff; margin-left: 10px;" (click)="gotoenrollment()">
                    <img src="../../../../assets/img/employee/v_edit.png" alt="img" style="margin-right: 5px;" />
                    Edit
                </button> -->

                <!-- <button class="btn btn-primary" style="background-color: #2C2C2C; color:#fff; margin-left: 10px;">
                    <img src="../../../../assets/img/employee/v_reset.png" alt="img" style="margin-right: 5px;" />
                    Reset
                </button> -->
            </div>

        </div>
        <app-footer></app-footer>
    </div>

</div>