<!-- Body -->
<div class="container-fluid page-body-wrapper">

    <!-- Sidebar -->
    <app-sidebar></app-sidebar>

    <!-- Main Wrapper -->
    <div class="main-panel">
        <div class="content-wrapper pt-3">            
            <!-- <h3 class="bs-title">Claim Utilization Analysis</h3>
            <hr class="mb-2 mt-4" style="height: 0px"> -->

            <img src="../../../assets/img/dashboard/c1.png" alt="img" style="width: 100%;" />

            <img src="../../../assets/img/dashboard/c2.png" alt="img" style="margin-top: 30px; width: 100%;" />

            <img src="../../../assets/img/dashboard/c3.png" alt="img" style="margin-top: 30px; width: 100%;" />

            <img src="../../../assets/img/dashboard/c4.png" alt="img" style="margin-top: 30px; width: 100%;" />

        </div>    
        <app-footer></app-footer>
    </div>    
</div>