<!-- Body -->
<div class="container-fluid page-body-wrapper">

    <!-- Sidebar -->
    <app-sidebar></app-sidebar>

    <!-- Main Wrapper -->
    <div class="main-panel">
        <div class="content-wrapper pt-3 cardbg">
            <ngx-loader [show]="show" [fullScreen] = "fullScreen" [template]="template" style="z-index: 500000; position: absolute;"> Loading... </ngx-loader>
            
            <h3 class="bs-title">{{title_text}}</h3>      
            <hr class="mt-0" style="height: 0px">     
            
            <div class="row mt-3">

                <!-- Image -->
                <div class="col-sm-3">
                    <img [src]="getImageSrc()" style="width: 100%;" alt="image" />
                </div>

                <div class="col-sm-9"> 

                    <div class="p-3" style="border-radius: 8px;" [ngClass]="{'gmcbg': policyname == 'gmc_dropdown' || policyname == 'gmc_dropdown_other', 'opdbg' : policyname == 'opd_dropdown', 'gpabg': policyname == 'gpa_dropdown', 'gtlbg': policyname == 'gtl_dropdown'}">
                        <div class="row m-0" style="border: dashed 2px #fff; padding: 10px;">
                            <div class="col-sm-4 mr-0 text-center text-white" style="border-right: dashed 2px #fff; display: flex;">
                                <img src="../../../../assets/img/employee/emp_pays.png" style="width: 70px; height: 40px; position: relative; top: 3px; margin-right: 20px;" alt="image" />
                                <div>
                                    <label>Employer pays </label><br>
                                    <label *ngIf="addfeature == 0 && hide_employer_contribution == 1">- </label>
                                    <label *ngIf="addfeature == 0 && hide_employer_contribution == 0">{{commonservice.moneyformatter(employer_pay_premium)}} <br> ({{this.tax}}) </label>
                                    <label *ngIf="addfeature == 1 && contribution == 1">{{commonservice.moneyformatter(employer_pay_premium)}} <br> ({{this.tax}}) </label>
                                    <label *ngIf="addfeature == 1 && contribution == 2">{{commonservice.moneyformatter(total_pay_employer)}} <br> ({{this.tax}}) </label>
                                    <!-- <label> ₹ {{this.employer_pay_premium}} ({{this.tax}}) </label> -->
                                </div>                                
                            </div>
                            <div class="col-sm-4 mr-0 text-center text-white" style="border-right: dashed 2px #fff; display: flex;">
                                <img src="../../../../assets/img/employee/you_pay.png" style="width: 70px; height: 40px; position: relative; top: 3px; margin-right: 15px;" alt="image" />
                                <div>
                                    <label>You Pay Premium   </label><br>
                                    <label *ngIf="addfeature == 0">{{commonservice.moneyformatter(you_pay_premium != undefined ? you_pay_premium : 0)}} <br> ({{this.tax}}) </label>
                                    <label *ngIf="addfeature == 1  && contribution == 2">{{commonservice.moneyformatter(you_pay_premium != undefined ? you_pay_premium : 0)}} <br> ({{this.tax}}) </label>
                                    <label *ngIf="addfeature == 1 && contribution == 1">{{commonservice.moneyformatter(total_pay != undefined ? total_pay : 0)}} <br> ({{this.tax}}) </label>
                                </div>
                            </div>
                            <div class="col-sm-4 text-center text-white" style="display: flex;">
                                <img src="../../../../assets/img/employee/purchase.png" style="width: 70px; height: 40px; position: relative; top: 3px; margin-right: 20px;" alt="image" />
                                <div>
                                    <label>Total benefits cost</label><br>
                                    <label *ngIf="addfeature == 0">{{commonservice.moneyformatter(my_purchase != undefined ? my_purchase : 0)}} <br> ({{this.tax}}) </label>
                                    <label *ngIf="addfeature == 1 && contribution == 2"> {{commonservice.moneyformatter(my_purchase != undefined ? my_purchase : 0)}} <br> ({{this.tax}}) </label>
                                    <label *ngIf="addfeature == 1 && contribution == 1">  {{commonservice.moneyformatter(total_pay != undefined ? total_pay : 0)}} <br> ({{this.tax}}) </label>
                                    <!-- <label> ₹ {{my_purchase}} ({{this.tax}}) </label> -->
                                </div>                                
                            </div>
                        </div>
                    </div>

                    <!-- Description -->
                    <p class="mt-3" *ngFor="let disclaim of disclaimer"> {{disclaim.plan_description}} </p>
                    <p class="mt-3" *ngIf="disclaimer.length == 0"> {{policy_description}} </p>

                    <div class="table-responsive">
                        <table class="table table-bordered table-striped custom-table" style="table-layout: fixed; border-top: none; width: 70%; margin: auto;">
                            <thead>
                                <tr style="border: none; background: none;">
                                    <th style="background: none; border: none;"></th>
                                    <th class="selectedcolumn" [ngClass]="{'gmcbg': policyname == 'gmc_dropdown' || policyname == 'gmc_dropdown_other', 'opdbg' : policyname == 'opd_dropdown', 'gpabg': policyname == 'gpa_dropdown', 'gtlbg': policyname == 'gtl_dropdown'}">
                                        <input type="checkbox" class="form-check-input" name="plan" id="plan" checked style="position: relative; top: -5px; border: solid 1px #fff; pointer-events: none;" />
                                        Base Plan
                                    </th>                                    
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let row of OPDarray; let i = index">
                                    <td class="blue-text">
                                        <span *ngIf="row['col_2'] != ''">{{ row['col_1'] }}</span>                                        
                                    </td>
                                    <td>
                                        <span *ngIf="row['col_2'] != 'Button'"> {{ row['col_2'] }}</span>
                                        <span *ngIf="row['col_2'] == 'Button'" class="btnselected">
                                            Selected
                                        </span>
                                    </td>                                                                                
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    
                </div>                
                                
            </div>    


            <!-- Addition Feature Discount -->
            <div class="my-3" *ngIf="addtional_featureDiscount.length > 0 || addtional_featureDiscount.length != ''">
                <div class="accordion" id="additionalFeatureDiscount">
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingOne">
                            <button type="button" class="accordion-button" data-bs-toggle="collapse" data-bs-target="#collapseAdditionDiscount" [ngClass]="{'gmcbg': policyname == 'gmc_dropdown' || policyname == 'gmc_dropdown_other', 'opdbg' : policyname == 'opd_dropdown', 'gpabg': policyname == 'gpa_dropdown', 'gtlbg': policyname == 'gtl_dropdown'}" style="color: white;">
                                Additional Feature Discount
                            </button>
                        </h2>
                        <div id="collapseAdditionDiscount" class="accordion-collapse show" data-bs-parent="#additionalFeatureDiscount">
                            <div class="card-body accorCardbody" style="font-size: 13px;">
                                {{addtional_featureDiscount.description}} -  {{commonservice.moneyformatter(addtional_featureDiscount.credit_amount)}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Additional Feature -->
            <div class="my-3" *ngIf="addtional_featureObject.length > 0 || addtional_featureObject.length != ''">
                <div class="accordion" id="maddtional_feature_Accordion">
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingOne">
                            <button type="button" class="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseTwo" [ngClass]="{'gmcbg': policyname == 'gmc_dropdown' || policyname == 'gmc_dropdown_other', 'opdbg' : policyname == 'opd_dropdown', 'gpabg': policyname == 'gpa_dropdown', 'gtlbg': policyname == 'gtl_dropdown'}" style="color: white;">
                                Additional Feature
                            </button>
                        </h2>
                        <div id="collapseTwo" class="accordion-collapse collapse show" data-bs-parent="#maddtional_feature_Accordion">
                            <div class="card-body accorCardbody">
                                <div class="row">
                                    <div class="col-sm-6 pt-4" style="font-size: 13px">
                                        <input type="checkbox" class="form-check-input" id="addfeature" (change)="additionalfeature($event)" [checked]="is_check_flag === 1" />
                                        Add <b>{{plan_name}}</b> upto ₹ <b>{{spouse_suminsured}}/-</b>
                                    </div>
                                    <div class="col-sm-6">
                                        <div style="background-color: #0000000d; padding: 15px; border-radius: 15px; font-size: 13px; text-align: center;">
                                            Additional Sum Insured of <br>
                                            <b>{{commonservice.moneyformatter(spouse_suminsured)}}</b><br>
                                            Flex Debit of <b>{{commonservice.moneyformatter(spouse_premium)}} {{spouse_premium_tax}}</b>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Enhance -->
            <div class="my-3">
                <div class="accordion" id="enhanceArr_Accordion" *ngIf="enhance_array.length > 0">
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingOne_enhanceArr">
                            <button type="button" class="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseTwo_enhanceArr" [ngClass]="{'gmcbg': policyname == 'gmc_dropdown' || policyname == 'gmc_dropdown_other', 'opdbg' : policyname == 'opd_dropdown', 'gpabg': policyname == 'gpa_dropdown', 'gtlbg': policyname == 'gtl_dropdown'}" style="color: white;">
                                Enhance Details
                            </button>
                        </h2>
                        <div id="collapseTwo_enhanceArr" class="accordion-collapse collapse show" data-bs-parent="#enhanceArr_Accordion">
                            <div class="card-body accorCardbody">
                                <div class="row">
                                    <div class="col-sm-4" *ngFor="let enhance of enhance_array; let p = index">
                                        <div style="padding: 10px; border-radius: 10px; border: solid 1px #dadada; font-size: 13px;background: #f5f5f5;" [ngClass]="{'mb-3': p > 3}">
                                            <div style="padding: 10px; border-radius: 10px; position: relative;" [ngStyle]="{'background-color': enhance.color_code}">
                                                <input type="checkbox" class="form-check-input" id="enhancechk" style="position: absolute; left: 10px; cursor: pointer;" (change)="enhancechk($event, enhance)" [checked]="enhance.is_select == '1'" />
                                                <span style="margin-left: 20px;" [ngStyle]="{'color': getTextColor(enhance.color_code, policyname === 'gmc_dropdown' ? 'GMC' : policyname === 'gpa_dropdown' ? 'GPA' : 'GTL')}">{{enhance.title}}</span>
                                            </div>
                                            <div style="margin-top: 10px;">Enhance SumInsured - 
                                                <span style="text-indent: 20px;">{{commonservice.moneyformatter(enhance.suminsured)}}</span>
                                            </div>
                                            <div>Additional SumInsured - 
                                                <span style="text-indent: 20px;">{{commonservice.moneyformatter(enhance.enhance_SI)}}</span>
                                            </div>
                                            <div [ngStyle]="{'background-color': enhance.color_code, 'color': getTextColor(enhance.color_code, policyname === 'gmc_dropdown' ? 'GMC' : policyname === 'gpa_dropdown' ? 'GPA' : 'GTL')}" style="margin: auto; display: block; text-align: center; font-size: 16px; margin-top: 10px; padding: 5px; border-radius: 10px; position: relative;">
                                                Premium <br>                                                
                                                <b>{{commonservice.moneyformatter(enhance.additionalPremiumEmployee)}}</b>
                                            </div>
                                        </div>                                        
                                    </div>                                                                        
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Disclaimer -->
            <div class="my-3" *ngIf="disclaimer.length > 0 || disclaimer.length != ''">
                <div class="accordion" id="myAccordion">
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingOne">
                            <button type="button" class="accordion-button" data-bs-toggle="collapse" data-bs-target="#collapseOne" [ngClass]="{'gmcbg': policyname == 'gmc_dropdown' || policyname == 'gmc_dropdown_other', 'opdbg' : policyname == 'opd_dropdown', 'gpabg': policyname == 'gpa_dropdown', 'gtlbg': policyname == 'gtl_dropdown'}" style="color: white;">
                                Disclaimer
                            </button>
                        </h2>
                        <div id="collapseOne" class="accordion-collapse show" data-bs-parent="#myAccordion">
                            <div class="card-body accorCardbody" style="font-size: 13px;" *ngFor="let disclaim of disclaimer">
                                {{disclaim.disclaimer}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div>               
                <button class="btn btn-primary" style="float: right; margin-left: 10px;" [ngClass]="{'gmcbg': policyname == 'gmc_dropdown' || policyname == 'gmc_dropdown_other', 'opdbg' : policyname == 'opd_dropdown', 'gpabg': policyname == 'gpa_dropdown', 'gtlbg': policyname == 'gtl_dropdown'}" (click)="Onsubmit()">Next</button>    
                <button class="btn btn-primary" style="background-color: #2C2C2C; color:#fff;" (click)="gotoback()" >
                    <!-- <img src="../../../../assets/img/employee/v_back.png" alt="img" style="margin-right: 5px;"/> -->
                    Back
                </button>
                <button class="btn btn-primary" style="background-color: #2C2C2C; color:#fff; margin-left: 10px;" (click)="gotoback()">Change Family Selection</button>
            </div>

            <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':spousepopup}">
                <div class="modal-dialog" role="document" style="max-width: 800px;">                    
                    <div class="modal-content">                       
                        <div class="modal-header" [ngClass]="{'gmcbg': policyname == 'gmc_dropdown' || policyname == 'gmc_dropdown_other', 'opdbg' : policyname == 'opd_dropdown', 'gpabg': policyname == 'gpa_dropdown', 'gtlbg': policyname == 'gtl_dropdown'}">
                            <h4 class="modal-title text-white p-0">Add Member Spouse
                                <button type="button" class="btn btn-danger" (click)="closealert()" style="position: absolute;right: 10px; top: 10px"> &#10006;</button>
                            </h4>
                        </div>
                        <div class="modal-body" style="overflow-x: hidden; padding: 30px;">
                            
                            <div class="row">
                                <div class="col-sm-4">
                                    <div class="form-group">
                                        <label class="bs-form-label"> Date Of Birth <span class="redstar">*</span></label>
                                        <input type="date" id="rel_dob" class="form-control" [max]="dob_future" placeholder="dd/mm/yyyy" pattern="\d{2}/\d{2}/\d{4}" />                                    
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="rel_first_name"> First Name <span class="redstar">*</span></label>
                                        <input type="text" id="rel_first_name" class="form-control" placeholder="First Name" />
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="rel_last_name"> Last Name</label>
                                        <input type="text" id="rel_last_name" class="form-control" placeholder="Last Name" />
                                    </div>
                                </div>
                            </div>
                                                        
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-primary" [ngClass]="{'gmcbg': policyname == 'gmc_dropdown' || policyname == 'gmc_dropdown_other', 'opdbg' : policyname == 'opd_dropdown', 'gpabg': policyname == 'gpa_dropdown', 'gtlbg': policyname == 'gtl_dropdown'}" (click)="savestepspouse()"> Submit</button>
                            <button type="button" class="btn btn-primary" style="background: #F95F53;" (click)="closealert()"> Close</button>
                        </div>
                    </div>                    
                </div>
            </div>

        </div>
        <app-footer></app-footer>
    </div>

</div>