<div class="row mt-3">
    <div class="col-sm-3 col_sm_3_custom">
        <div class="card" style="background: #fff; border-radius: 10px; padding: 20px;">
            <h5 class="h5" title="Total Clients">Total Clients</h5>
            <div class="row">
                <div class="col-sm-6">
                    <img src="../../../assets/img/employee/icon1.png" alt="img" />
                </div>
                <div class="col-sm-6">
                    <label class="label1">306</label>                                                                                                
                </div>                            
                <div class="col-sm-12">                                
                    <label style="color: #00D63C; float: right; margin-left: 5px;"> YoY (20%) </label>
                    <img src="../../../assets/img/employee/green.png" alt="img" style="height: 15px; float: right;" />
                </div>
            </div>
        </div>
    </div>
    <div class="col-sm-3 col_sm_3_custom">
        <div class="card" style="border-radius: 10px; padding: 20px;">
            <h5 class="h5" title="Total Employees">Total Employees</h5>
            <div class="row">
                <div class="col-sm-6">
                    <img src="../../../assets/img/employee/icon2.png" alt="img" />
                </div>
                <div class="col-sm-6">
                    <label class="label1">0.92 Mn</label>                                                              
                </div>
                <div class="col-sm-12">                                
                    <label style="color: #00D63C; float: right; margin-left: 5px;"> YoY (15%) </label>
                    <img src="../../../assets/img/employee/green.png" alt="img" style="height: 15px; float: right;" />
                </div>
            </div>
        </div>
    </div>
    <div class="col-sm-3 col_sm_3_custom">
        <div class="card" style="border-radius: 10px; padding: 20px;">
            <h5 class="h5" title="Total Lives(self + Dependents)">Total Lives(self + Dependents)</h5>
            <div class="row">
                <div class="col-sm-6">
                    <img src="../../../assets/img/employee/icon3.png" alt="img" />
                </div>
                <div class="col-sm-6">
                    <label class="label1">0.92 Mn</label>
                </div>
                <div class="col-sm-12">                                
                    <label style="color: #00D63C; float: right; margin-left: 5px;"> YoY (10%) </label>
                    <img src="../../../assets/img/employee/green.png" alt="img" style="height: 15px; float: right;" />
                </div>
            </div>
        </div>
    </div>
    <div class="col-sm-3 col_sm_3_custom">
        <div class="card" style="border-radius: 10px; padding: 20px;">
            <h5 class="h5" title="Total Revenue">Total Revenue</h5>
            <div class="row">
                <div class="col-sm-6">
                    <img src="../../../assets/img/employee/icon4.png" alt="img" />
                </div>
                <div class="col-sm-6">
                    <label class="label1">115 Mn</label>
                </div>
                <div class="col-sm-12">                                
                    <label style="color: #00D63C; float: right; margin-left: 5px;"> YoY (50%) </label>
                    <img src="../../../assets/img/employee/green.png" alt="img" style="height: 15px; float: right;" />
                </div>
            </div>
        </div>
    </div>
    <div class="col-sm-3 col_sm_3_custom mtpolicies">
        <div class="card" style="border-radius: 10px; padding: 20px;">
            <h5 class="h5" title="Total Policies">Total Policies</h5>
            <div class="row">
                <div class="col-sm-6">
                    <img src="../../../assets/img/employee/icon5.png" alt="img" />
                </div>
                <div class="col-sm-6">
                    <label class="label1">306</label>
                </div>
                <div class="col-sm-12">                                
                    <label style="color: #00D63C; float: right; margin-left: 5px;"> YoY (30%) </label>
                    <img src="../../../assets/img/employee/green.png" alt="img" style="height: 15px; float: right;" />
                </div>
            </div>
        </div>
    </div>
    <div class="col-sm-3 col_sm_3_custom mt-4">
        <div class="card" style="border-radius: 10px; padding: 20px;">
            <h5 class="h5" title="Total Premium">Total Premium</h5>
            <div class="row">
                <div class="col-sm-6">
                    <img src="../../../assets/img/employee/icon6.png" alt="img" />
                </div>
                <div class="col-sm-6">
                    <label class="label1">2295 Mn</label>
                </div>
                <div class="col-sm-12">                                
                    <label style="color: #00D63C; float: right; margin-left: 5px;"> YoY (7%) </label>
                    <img src="../../../assets/img/employee/green.png" alt="img" style="height: 15px; float: right;" />
                </div>
            </div>
        </div>
    </div>
    <div class="col-sm-3 col_sm_3_custom mt-4">
        <div class="card" style="border-radius: 10px; padding: 20px;">
            <h5 class="h5" title="Avg Revenue">Avg Revenue</h5>
            <div class="row">
                <div class="col-sm-6">
                    <img src="../../../assets/img/employee/icon7.png" alt="img" />
                </div>
                <div class="col-sm-6">
                    <label class="label1">0.38 Mn</label>
                </div>
                <div class="col-sm-12">                                
                    <label style="color: #00D63C; float: right; margin-left: 5px;"> YoY (20%) </label>
                    <img src="../../../assets/img/employee/green.png" alt="img" style="height: 15px; float: right;" />
                </div>
            </div>
        </div>
    </div>
    <div class="col-sm-3 col_sm_3_custom mt-4">
        <div class="card" style="border-radius: 10px; padding: 20px;">
            <h5 class="h5" title="Avg No of Lives Per client">Avg No of Lives Per client</h5>
            <div class="row">
                <div class="col-sm-6">
                    <img src="../../../assets/img/employee/icon8.png" alt="img" />
                </div>
                <div class="col-sm-6">
                    <label class="label1" title="11,900">3K</label>
                </div>
                <div class="col-sm-12">                                
                    <label style="color: #00D63C; float: right; margin-left: 5px;"> YoY (30%) </label>
                    <img src="../../../assets/img/employee/green.png" alt="img" style="height: 15px; float: right;" />
                </div>
            </div>
        </div>
    </div>
    <div class="col-sm-3 col_sm_3_custom mt-4">
        <div class="card" style="border-radius: 10px; padding: 20px;">
            <h5 class="h5" title="Avg No of Employees Per client">Avg No of Employees Per client</h5>
            <div class="row">
                <div class="col-sm-6">
                    <img src="../../../assets/img/employee/icon9.png" alt="img" />
                </div>
                <div class="col-sm-6">
                    <label class="label1">3K</label>
                </div>
                <div class="col-sm-12">                                
                    <label style="color: #00D63C; float: right; margin-left: 5px;"> YoY (20%) </label>
                    <img src="../../../assets/img/employee/green.png" alt="img" style="height: 15px; float: right;" />
                </div>
            </div>
        </div>
    </div>
    <div class="col-sm-3 col_sm_3_custom mt-4">
        <div class="card" style="border-radius: 10px; padding: 20px;">
            <h5 class="h5" title="Retention Ratio Premium">Retention Ratio Premium</h5>
            <div class="row">
                <div class="col-sm-6">
                    <img src="../../../assets/img/employee/icon10.png" alt="img" style="padding: 11px; background: #c7d1d5; border-radius: 6px;" />
                </div>
                <div class="col-sm-6">
                    <label class="label1">80%</label>
                </div>
                <div class="col-sm-12">                                
                    <label style="color: #00D63C; float: right; margin-left: 5px;"> YoY (10%) </label>
                    <img src="../../../assets/img/employee/green.png" alt="img" style="height: 15px; float: right;" />
                </div>
            </div>
        </div>
    </div>
</div>

<!-- ------------------------------------------------------------ -->

<div class="row mt-4">

    <div class="col-md-6 col-lg-4 col-custom">
        <div class="card graphdiv">
            <h5>Premium Summary</h5>
            <div style="margin: auto;" [chart]="pieChart1"></div>
            <button class="viewmorebtn">View More</button>                    
        </div>
    </div>
    
    <div class="col-md-6 col-lg-4 col-custom">
        <div class="card graphdiv">
            <h5>Revenue</h5>
            <div style="margin: auto;" [chart]="pieChart2"></div>
            <button class="viewmorebtn">View More</button>                    
        </div>
    </div>

    <div class="col-md-6 col-lg-4 col-custom">
        <div class="card graphdiv">
            <h5>New Vs Renewed</h5>
            <div style="margin: auto;" [chart]="pieChart3"></div>
            <button class="viewmorebtn">View More</button>                    
        </div>
    </div>

    <div class="col-md-6 col-lg-4 col-custom">
        <div class="card graphdiv">
            <h5>Sales Funnel</h5>
            <img src="../../../assets/img/employee/funnel.png" alt="funnel" class="funnelimg" />
            <span class="total">450</span>
            <span class="renewal">350</span>
            <span class="oppurunity">100</span>
            <button class="viewmorebtn">View More</button>                    
        </div>
    </div>

    <!-- <div class="col-md-6 col-lg-4 col-custom" style="margin-bottom: 20px;">
        <div class="card graphdiv">
            <h5>Claim ratio</h5>
            <div style="margin: auto;" [chart]="areaChart"></div>
            <button class="viewmorebtn">View More</button>                    
        </div>
    </div> -->

    <div class="col-md-6 col-lg-4 col-custom" style="margin-bottom: 20px;">
        <div class="card graphdiv">
            <h5 title="Top 10 Insurers Portfolio Performance">Top 10 Insurers Portfolio Performance</h5>
            <div style="margin: auto;" [chart]="barChart1"></div>
            <button class="viewmorebtn">View More</button>                    
        </div>
        <div style="display: flex; position: relative; left: 10px; top: -25px;">
            <label style="font-size: 12px; margin-right: 5px;">As on Today</label>
            <div class="toggle-switch">
              <input type="checkbox" id="toggle" class="toggle-input">
              <label for="toggle" class="toggle-label"></label>
            </div>
            <label style="font-size: 12px; margin-left: 5px;">Projected</label>            
        </div>

    </div>

    <!-- <div class="col-md-6 col-lg-4 col-custom" style="margin-bottom: 20px;">
        <div class="card graphdiv">
            <h5 title="Top 5 TPA Portfolio">Top 5 TPA Portfolio </h5>
            <select class="form-select" style="width: 150px; background-color: #E8EFF9; height: 30px;">
                <option>Premium</option>
            </select>
            <div style="margin: auto;" [chart]="barChart2"></div>
            <button class="viewmorebtn">View More</button>                    
        </div>
        <div style="display: flex; position: relative; left: 10px; top: -25px;">
            <label style="font-size: 12px; margin-right: 5px;">As on Today</label>
            <div class="toggle-switch">
              <input type="checkbox" id="toggle" class="toggle-input">
              <label for="toggle" class="toggle-label"></label>
            </div>
            <label style="font-size: 12px; margin-left: 5px;">Projected</label>            
        </div>

    </div>

    <div class="col-md-6 col-lg-4 col-custom" style="margin-bottom: 20px;">
        <div class="card graphdiv">
            <h5 title="Top 5 Providers Portfolio">Top 5 Providers Portfolio</h5>
            <select class="form-select" style="width: 150px; background-color: #E8EFF9; height: 30px;">
                <option>Premium</option>
            </select>
            <div style="margin: auto;" [chart]="barChart3"></div>
            <button class="viewmorebtn">View More</button>                    
        </div>
        <div style="display: flex; position: relative; left: 10px; top: -25px;">
            <label style="font-size: 12px; margin-right: 5px;">As on Today</label>
            <div class="toggle-switch">
              <input type="checkbox" id="toggle" class="toggle-input">
              <label for="toggle" class="toggle-label"></label>
            </div>
            <label style="font-size: 12px; margin-left: 5px;">Projected</label>            
        </div>

    </div> -->

    <div class="col-md-6 col-lg-4 col-custom">
        <div class="card graphdiv">
            <h5>Customer Mix</h5>
            <div style="margin: auto;" [chart]="donutChart1"></div>
            <button class="viewmorebtn">View More</button>                    
        </div>
    </div>

    <div class="col-md-6 col-lg-4 col-custom">
        <div class="card graphdiv">
            <h5>RM Mix</h5>
            <div style="margin: auto;" [chart]="donutChart2"></div>
            <button class="viewmorebtn">View More</button>                    
        </div>
    </div>

    <div class="col-md-6 col-lg-4 col-custom">
        <div class="card graphdiv">
            <h5>Insurers Mix</h5>
            <div style="margin: auto;" [chart]="donutChart3"></div>
            <button class="viewmorebtn">View More</button>                    
        </div>
    </div>

    <!-- <div class="col-md-6 col-lg-4 col-custom">
        <div class="card graphdiv">
            <h5>Product Mix</h5>
            <div style="margin: auto;" [chart]="donutChart4"></div>
            <button class="viewmorebtn">View More</button>                    
        </div>
    </div> -->

    <!-- <div class="col-sm-6">
        <div class="card graphdiv" style="width: 100%;">
            <h5>Top 5 TPA Portfolio</h5>        
            <div class="row">
                <div class="col-sm-6">
                    <div style="margin: auto;" [chart]="donutChart5"></div>
                </div>
                <div class="col-sm-6">
                    <div style="margin: auto;" [chart]="donutChart6"></div>
                </div>
            </div>                                                
            <button class="viewmorebtn">View More</button>                    
        </div>
    </div> -->

    <div class="col-sm-6">
        <div class="card graphdiv" style="width: 100%;">
            <h5>Top 5 Providers Portfolio</h5>        
            <div class="row">
                <div class="col-sm-6">
                    <div style="margin: auto;" [chart]="donutChart7"></div>
                </div>
                <div class="col-sm-6">
                    <div style="margin: auto;" [chart]="donutChart8"></div>
                </div>
            </div>                                                
            <button class="viewmorebtn">View More</button>                    
        </div>
    </div>
    
</div>  