import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class TpaDetailsService {

  AUTH_API = environment.apiURL;

  constructor(private http: HttpClient) { }
  
  AddInfo(data: any): Observable<any> {

    var filedata = new FormData();
    filedata.append("tokenable_id",data.tokenable_id);
    filedata.append("broker_id",data.broker_id);
    filedata.append("client_id",data.client_id);    
    filedata.append("sub_type_id",data.sub_type_id);
    // filedata.append("file_type",data.file_type);
    filedata.append("help_file",data.help_file);

    const httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'help/store', 
      filedata
    , httpOptions);
  }  

  InfoDelete(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'help/destroy/',
      data
    , httpOptions);
  }

  InfoSingle(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'help/show/', 
      data
    , httpOptions);
  }

  InfoUpdate(data: any): Observable<any> {

    var filedata = new FormData();
    filedata.append("tokenable_id",data.tokenable_id);
    filedata.append("broker_id",data.broker_id);
    filedata.append("faq_id",data.faq_id);
    filedata.append("client_id",data.client_id);    
    filedata.append("sub_type_id",data.sub_type_id);
    // filedata.append("file_type",data.file_type);
    filedata.append("question",data.questions);
    filedata.append("answer",data.answers);
    // filedata.append("help_file",data.help_file);

    const httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'help/update/',
      filedata
    , httpOptions);
  }

  getInfo(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'help-list', 
    data,
      httpOptions);
  }

  getBroker(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.get(this.AUTH_API + 'masterbroker-list', 
      httpOptions);
  }  

  InfoActiveDeactivate(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'help/activedeactive/',
      data
    , httpOptions);
  }

  getInfoClient(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'masteremployer/listbyuserwise', 
      data,
      httpOptions);
  }

  getpolicytype(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'help-policy-subtype',
      data
    , httpOptions);
  }

}