import { Component, OnInit } from '@angular/core';
import { Service } from './service';

@Component({
  selector: 'app-employee-faq',
  templateUrl: './employee-faq.component.html',
  styleUrls: ['./employee-faq.component.css']
})
export class EmployeeFaqComponent implements OnInit {

  accounts_faqs = [
    {
      question: "How do I open a new account?",
      answer: "You can open a new account by visiting your nearest branch with valid ID proof, address proof, and a passport-sized photo. Alternatively, you can apply online through our website.",
      active: false
    },
    {
      question: "What are the minimum balance requirements for savings accounts?",
      answer: "The minimum balance requirement varies depending on the type of savings account. Please refer to our savings account page for detailed information.",
      active: false
    },
    {
      question: "How can I update my contact details on my account?",
      answer: "You can update your contact details through our mobile app, online banking portal, or by visiting a branch with valid ID proof.",
      active: false
    },
    {
      question: "What should I do if I forget my account login credentials?",
      answer: "If you forget your login credentials, you can reset them using the 'Forgot Password' option on our login page. Alternatively, contact customer support for assistance.",
      active: false
    },
    {
      question: "How do I close my account?",
      answer: "To close your account, visit your nearest branch with valid ID proof and a written application. Ensure all dues are cleared before proceeding.",
      active: false
    }
  ];  

  deposits_faqs = [
    {
      question: "What types of deposit accounts do you offer?",
      answer: "We offer savings accounts, fixed deposits, recurring deposits, and term deposits. Each type has its own features and benefits.",
      active: false
    },
    {
      question: "What is the interest rate on fixed deposits?",
      answer: "The interest rates on fixed deposits vary depending on the tenure and the deposit amount. Please check our website for the latest rates.",
      active: false
    },
    {
      question: "How can I renew my fixed deposit?",
      answer: "Fixed deposits can be renewed automatically if you have opted for auto-renewal, or you can manually renew them through our mobile app, online banking, or by visiting a branch.",
      active: false
    },
    {
      question: "Can I withdraw my fixed deposit before maturity?",
      answer: "Yes, you can withdraw your fixed deposit before maturity. However, a penalty may apply depending on the terms and conditions of your deposit.",
      active: false
    },
    {
      question: "Is the interest earned on deposits taxable?",
      answer: "Yes, interest earned on deposits is taxable as per government rules. TDS (Tax Deducted at Source) is applicable if the interest exceeds the specified limit.",
      active: false
    }
  ];

  payments_faqs = [
    {
      question: "What payment methods are supported?",
      answer: "We support UPI, NEFT, RTGS, IMPS, debit cards, credit cards, and internet banking for payments.",
      active: false
    },
    {
      question: "How do I make a UPI payment?",
      answer: "You can make a UPI payment using our mobile app or any third-party UPI app by entering the recipient's UPI ID or scanning a QR code.",
      active: false
    },
    {
      question: "What is the daily transaction limit for online payments?",
      answer: "The daily transaction limit varies by payment method. UPI has a limit of ₹1,00,000, while NEFT and RTGS limits depend on your account type.",
      active: false
    },
    {
      question: "Can I schedule future payments?",
      answer: "Yes, you can schedule payments for a future date through our internet banking portal or mobile app.",
      active: false
    },
    {
      question: "What should I do if my payment fails?",
      answer: "If your payment fails, check your account balance and network connection. If the issue persists, contact our customer support team for assistance.",
      active: false
    }
  ];
  
  loans_faqs = [
    {
      question: "What types of loans do you offer?",
      answer: "We offer personal loans, home loans, car loans, education loans, and business loans.",
      active: false
    },
    {
      question: "How do I apply for a loan?",
      answer: "You can apply for a loan by visiting our branch, contacting our loan advisor, or submitting an application online through our website.",
      active: false
    },
    {
      question: "What is the interest rate on personal loans?",
      answer: "The interest rate on personal loans varies based on the loan amount and tenure. Please visit our website or contact our branch for detailed information.",
      active: false
    },
    {
      question: "Can I prepay my loan before the tenure ends?",
      answer: "Yes, prepayment is allowed for most loans. Prepayment charges, if any, will be mentioned in the loan agreement.",
      active: false
    },
    {
      question: "What documents are required for a loan application?",
      answer: "You need to provide ID proof, address proof, income proof, and other relevant documents as specified for the loan type.",
      active: false
    }
  ];
  
  investment_faqs = [
    {
      question: "What investment options do you provide?",
      answer: "We provide fixed deposits, mutual funds, equity-linked savings schemes (ELSS), and government bonds.",
      active: false
    },
    {
      question: "How do I start investing in mutual funds?",
      answer: "You can start investing in mutual funds through our investment portal or by visiting our branch for expert guidance.",
      active: false
    },
    {
      question: "Are my investments insured?",
      answer: "Investments are subject to market risks and are not insured. Please read the offer documents carefully before investing.",
      active: false
    },
    {
      question: "Can I change my investment portfolio?",
      answer: "Yes, you can modify your investment portfolio anytime through our online portal or by contacting your financial advisor.",
      active: false
    },
    {
      question: "What is the lock-in period for tax-saving investments?",
      answer: "The lock-in period for tax-saving investments like ELSS is three years, while it may vary for other instruments.",
      active: false
    }
  ];
  
  insurance_faqs = [
    {
      question: "What types of insurance policies do you offer?",
      answer: "We offer life insurance, health insurance, vehicle insurance, and travel insurance policies.",
      active: false
    },
    {
      question: "How do I file an insurance claim?",
      answer: "To file a claim, contact our claims department and submit the required documents, including the claim form and relevant proofs.",
      active: false
    },
    {
      question: "Is cashless hospitalization available?",
      answer: "Yes, cashless hospitalization is available at our network hospitals. Please check the list of hospitals on our website.",
      active: false
    },
    {
      question: "Can I change the nominee for my insurance policy?",
      answer: "Yes, you can update the nominee details by submitting a written request along with relevant documents to your nearest branch.",
      active: false
    },
    {
      question: "What is the policy renewal process?",
      answer: "You can renew your policy online through our portal or by visiting a branch. Ensure renewal before the expiry date to maintain coverage.",
      active: false
    }
  ];

  spls_faqs = [
    {
      question: "What special services do you offer?",
      answer: "We provide services like safe deposit lockers, wealth management, and personalized financial advice.",
      active: false
    },
    {
      question: "How can I avail locker facilities?",
      answer: "Locker facilities are available at select branches. Visit the branch to check availability and complete the required formalities.",
      active: false
    },
    {
      question: "Do you offer financial planning consultations?",
      answer: "Yes, we offer financial planning consultations with certified advisors to help you achieve your financial goals.",
      active: false
    },
    {
      question: "Are concierge services available?",
      answer: "Yes, premium account holders can avail of concierge services. Please contact your branch manager for more details.",
      active: false
    },
    {
      question: "What are your wealth management services?",
      answer: "Our wealth management services include portfolio management, investment advisory, and estate planning tailored to your needs.",
      active: false
    }
  ];
  
  InfoArray:any = [];

  constructor(private dataservice: Service) { }

  ngOnInit(): void {
    this.getInfo();
  }

  toggleAnswer(faq: any) {
    faq.active = !faq.active;
  }
  
  getInfo() {
    var postdata = {
      "tokenable_id": localStorage.getItem('tokenable_id')
    };

    this.dataservice.getInfo(postdata)
      .subscribe((result: any) => {
       
        this.InfoArray = result.data;

        interface InfoItem {
          sub_type_policy_idfk: number | string;
          policy_sub_type: string;
          questions: string;
          answers: string;
        }
        
        interface TransformedItem {
            sub_type_policy_idfk: number | string;
            policy_sub_type: string;
            qa: { question: string; answer: string }[];
        }
        
        // Transform the array
        const transformedArray = Object.values(
          this.InfoArray.reduce(
              (acc: { [key: string]: TransformedItem }, item: InfoItem) => {
                  if (!acc[item.sub_type_policy_idfk]) {
                      acc[item.sub_type_policy_idfk] = {
                          sub_type_policy_idfk: item.sub_type_policy_idfk,
                          policy_sub_type: item.policy_sub_type,
                          qa: [],
                      };
                  }
                  acc[item.sub_type_policy_idfk].qa.push({
                      question: item.questions,
                      answer: item.answers,
                  });
                  return acc;
              },
              {}
          )
        );

        this.InfoArray = transformedArray;

      }, (error) => {

        this.InfoArray = [];

      });
  }

}
