<!-- Body -->
<div class="container-fluid page-body-wrapper">

    <!-- Sidebar -->
    <app-sidebar></app-sidebar>

    <!-- Main Wrapper -->
    <div class="main-panel">
        <div class="content-wrapper pt-3">

            <h3 class="bs-title" style="line-height: unset;">Dashboard
                <!-- <select class="form-select" style="float: right; width: 260px;">
                    <option>ALL</option>
                </select> -->
            </h3>
            <hr class="mb-2" style="height: 0px">
            

            <div class="col-sm-12">                
                <ul class="nav nav-tabs" id="myTab" role="tablist"
                    style="border-radius: 10px 10px 0px 0px;">
                    <li class="nav-item liwidth active" role="presentation">
                        <button class="nav-link w100 active" id="Add-tab" data-bs-toggle="tab" data-bs-target="#Add"
                            type="button" role="tab" aria-controls="home" aria-selected="true">Group Mediclaim</button>
                    </li>
                    <li class="nav-item liwidth" role="presentation">
                        <button class="nav-link w100" id="OPD-tab" data-bs-toggle="tab"
                            data-bs-target="#OPD" type="button" role="tab" aria-controls="OPD"
                            aria-selected="false">OPD</button>
                    </li>
                    <li class="nav-item liwidth" role="presentation">
                        <button class="nav-link w100" id="Remove-tab" data-bs-toggle="tab" 
                            data-bs-target="#Remove" type="button" role="tab" aria-controls="Remove"
                            aria-selected="false">Group Personal Accident</button>
                    </li>
                    <li class="nav-item liwidth" role="presentation">
                        <button class="nav-link w100" id="Corrections-tab" data-bs-toggle="tab"
                            data-bs-target="#Corrections" type="button" role="tab" aria-controls="Corrections"
                            aria-selected="false">Group Term Life</button>
                    </li>
                    <li class="nav-item liwidth" role="presentation">
                        <button class="nav-link w100" id="EDLI-tab" data-bs-toggle="tab"
                            data-bs-target="#EDLI" type="button" role="tab" aria-controls="EDLI"
                            aria-selected="false">EDLI</button>
                    </li>                     
                </ul>

                <!-- Tabs Content -->
                <div class="tab-content tabUI" id="myTabContent" style="padding-bottom: 20px;">
                    <!-- Tab Group Mediclaim -->
                    <div class="tab-pane show active" id="Add" role="tabpanel" aria-labelledby="Add-tab">                        
                        <app-gmc-dashboard></app-gmc-dashboard>                       
                    </div>

                    <!-- Tab OPD -->
                    <div class="tab-pane fade" id="OPD" role="tabpanel" aria-labelledby="OPD-tab" >                       
                        <app-opd-dashboard></app-opd-dashboard>                                           
                    </div>

                    <!-- Tab Personal Accident -->
                    <div class="tab-pane fade" id="Remove" role="tabpanel" aria-labelledby="Remove-tab" >                        
                        <app-gpa-dashboard></app-gpa-dashboard>                   
                    </div>

                    <!-- Tab Group Term Life -->
                    <div class="tab-pane fade" id="Corrections" role="tabpanel" aria-labelledby="Corrections-tab" >                        
                        <app-gtl-dashboard></app-gtl-dashboard>
                    </div>

                    <!-- Tab EDLI -->
                    <div class="tab-pane fade" id="EDLI" role="tabpanel" aria-labelledby="EDLI-tab" >                        
                        <!-- <app-edli></app-edli> -->
                         Comming Soon...
                    </div>
                </div>
            </div>            

        </div>
        <app-footer></app-footer>
    </div>

</div>