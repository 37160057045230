<!-- Body -->
<div class="container-fluid page-body-wrapper">

    <!-- Sidebar -->
    <app-sidebar></app-sidebar>

    <!-- Main Wrapper -->
    <div class="main-panel">
        <div class="content-wrapper pt-3">
        
            <ngx-loader [show]="show" [fullScreen] = "fullScreen" [template]="template" style="z-index: 500000; position: absolute;"> Loading... </ngx-loader>        
                
            <h3 class="bs-title" *ngIf="addflag == 0"><span class="spanbs-title">Insurance-company/insurer</span>
            <button type="button" class="btn btn-primary" (click)="openPopupAdd()" role="button" style="float:right" *ngIf="writeaccess == 1">+ Add</button>
            </h3>
            <h3 class="bs-title" *ngIf="addflag == 1">Add <span class="spanbs-title">Insurance-company/insurer</span>
                <button type="button" class="btn btn-primary" (click)="closePopupAdd()" role="button" style="float:right">Back</button>
            </h3>
            <h3 class="bs-title" *ngIf="addflag == 2">Edit <span class="spanbs-title">Insurance-company/insurer</span>
                <button type="button" class="btn btn-primary" (click)="closePopupAdd()" role="button" style="float:right">Back</button>
            </h3>
            
            <hr class="mb-2 mt-4" style="height: 0px">

            <!-- List -->
            <div class="table-responsive" *ngIf="addflag == 0">
                <table class="table table-sm row-border" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                    <thead>
                    <tr>
                        <th style="width:5%">SrNo</th>
                        <th>Name</th>                        
                        <th>Status</th>
                        <th style="text-align: center;" *ngIf="writeaccess == 1">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let group of InfoArray; let i = index">
                        <td style="width:5%">{{i+1}}</td>
                        <td>{{group['name']}}</td>                                          
                        <td>
                            <span *ngIf="group['is_active'] == 1" class="btn btn-inverse-success btn-fw">Active</span>
                            <span *ngIf="group['is_active'] == 0" class="btn btn-inverse-danger btn-fw">Deactive</span>
                        </td>
                        <td style="width: 15%" align="center" *ngIf="writeaccess == 1">                    
                            <img src="../../../assets/img/common icons/edit_new.png" id="edituser" (click)="openPopupEdit(group)" role="button" title="Edit" />
                            <img *ngIf="group['is_active'] == 1" src="../../../assets/img/selectproduct/deactive.png" id="deleteuser" (click)="openPopupDeactivate(group)" class="ml-4" role="button" title="Deactive" />
                            <img *ngIf="group['is_active'] == 0" src="../../../assets/img/policy/tickicon.png" id="deleteuser" (click)="openPopupActivate(group)" class="ml-4" role="button" title="Active" />
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>

            <!-- Add Form -->
            <div *ngIf="addflag == 1">                                        
                <form [formGroup]="AddInfo">                    
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label class="bs-form-label" for="tag_name">Select Product <span class="redstar">*</span></label>
                                <ng-select formControlName="tag_name" id="tag_name" 
                                                [items]="ProductInfoArray" 
                                                bindLabel="tag_name" 
                                                bindValue="product_id"
                                                [ngClass]="{'control-red': AddInfoSubmitted && getAddInformationFormControls.tag_name.errors}"
                                                required>                                    
                                </ng-select>                          
                                <ng-container
                                    *ngIf="AddInfoSubmitted && getAddInformationFormControls.tag_name.errors">
                                    <p class="error"
                                        *ngIf="AddInfoSubmitted && getAddInformationFormControls.tag_name.errors.required">
                                        Product is required
                                    </p>
                                </ng-container>
                            </div>
                        </div>

                        <div class="col-sm-6">
                            <div class="form-group">
                                <label class="bs-form-label" for="contact_person">Contact person <span class="redstar">*</span></label>
                                <input type="text" class="form-control" formControlName="contact_person" id="contact_person" placeholder="Contact person"
                                [ngClass]="{'control-red': AddInfoSubmitted && getAddInformationFormControls.contact_person.errors}"
                                required />
                                <ng-container
                                    *ngIf="AddInfoSubmitted && getAddInformationFormControls.contact_person.errors">
                                    <p class="error"
                                        *ngIf="AddInfoSubmitted && getAddInformationFormControls.contact_person.errors.required">
                                        Contact person is required
                                    </p>
                                </ng-container>
                            </div>
                        </div> 

                        <div class="col-sm-6 mt-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="name">Insurer Name <span class="redstar">*</span></label>
                                <input type="text" class="form-control" formControlName="name" id="name" placeholder="Name"
                                [ngClass]="{'control-red': AddInfoSubmitted && getAddInformationFormControls.name.errors}"
                                required />
                                <ng-container
                                    *ngIf="AddInfoSubmitted && getAddInformationFormControls.name.errors">
                                    <p class="error"
                                        *ngIf="AddInfoSubmitted && getAddInformationFormControls.name.errors.required">
                                        Name is required
                                    </p>
                                </ng-container>
                            </div>
                        </div> 
                        
                        <div class="col-sm-6 mt-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="broker_idfk">Broker <span class="redstar">*</span></label>
                                <select class="form-select" formControlName="broker_idfk" id="broker_idfk">
                                    <option value="">Select Broker</option>
                                    <option *ngFor="let brokerlist of InfoArrayBroker; let i = index" value="{{brokerlist.id}}">{{brokerlist.name}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </form>
                <div class="col-sm-12" align="center">
                    <button type="button" class="btn btn-primary mt-3" (click)="onSubmitAdd()" role="button">Save</button>                    
                </div>
            </div>          

            <!-- Edit Form -->
            <div *ngIf="addflag == 2">
                <form [formGroup]="EditInfo">                    
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label class="bs-form-label" for="tag_name_edit">Select Product <span class="redstar">*</span></label>
                                <ng-select formControlName="tag_name_edit" id="tag_name_edit" 
                                                [items]="ProductInfoArray" 
                                                bindLabel="tag_name" 
                                                bindValue="product_id"
                                                [ngClass]="{'control-red': EditInfoSubmitted && getEditInformationFormControls.tag_name_edit.errors}"
                                                required>                                    
                                </ng-select>                          
                                <ng-container
                                    *ngIf="EditInfoSubmitted && getEditInformationFormControls.tag_name_edit.errors">
                                    <p class="error"
                                        *ngIf="EditInfoSubmitted && getEditInformationFormControls.tag_name_edit.errors.required">
                                        Product is required
                                    </p>
                                </ng-container>
                            </div>
                        </div>

                        <div class="col-sm-6">
                            <div class="form-group">
                                <label class="bs-form-label" for="contact_person_edit">Contact person <span class="redstar">*</span></label>
                                <input type="text" class="form-control" formControlName="contact_person_edit" id="contact_person_edit" placeholder="Contact person"
                                [ngClass]="{'control-red': EditInfoSubmitted && getEditInformationFormControls.contact_person_edit.errors}"
                                required />
                                <ng-container
                                    *ngIf="EditInfoSubmitted && getEditInformationFormControls.contact_person_edit.errors">
                                    <p class="error"
                                        *ngIf="EditInfoSubmitted && getEditInformationFormControls.contact_person_edit.errors.required">
                                        Contact person is required
                                    </p>
                                </ng-container>
                            </div>
                        </div>            
                        <div class="col-sm-6 mt-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="name_edit">Name <span class="redstar">*</span></label>
                                <input type="text" class="form-control" formControlName="name_edit" id="name_edit" placeholder="Name"
                                [ngClass]="{'control-red': EditInfoSubmitted && getEditInformationFormControls.name_edit.errors}"
                                required />
                                <ng-container
                                    *ngIf="EditInfoSubmitted && getEditInformationFormControls.name_edit.errors">
                                    <p class="error"
                                        *ngIf="EditInfoSubmitted && getEditInformationFormControls.name_edit.errors.required">
                                        Name is required
                                    </p>
                                </ng-container>
                            </div>
                        </div>
                        <div class="col-sm-6 mt-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="broker_idfk_edit">Broker <span class="redstar">*</span></label>
                                <select class="form-select" formControlName="broker_idfk_edit" id="broker_idfk_edit">
                                    <option value="">Select Broker</option>
                                    <option *ngFor="let brokerlist of InfoArrayBroker; let i = index" value="{{brokerlist.id}}">{{brokerlist.name}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-sm-6 mt-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="edit_is_active">Status <span class="redstar">*</span></label>
                                <select class="form-select" formControlName="edit_is_active" id="edit_is_active" required>
                                    <option [value]="1">Active</option>
                                    <option [value]="0">Deactive</option>
                                </select>                    
                            </div>
                        </div>
                        <input type="hidden" class="form-control" formControlName="edit_id" id="edit_id" />
                    </div>
                </form>
                <div class="col-sm-12" align="center">
                    <button type="button" class="btn btn-primary mt-3" (click)="onSubmitEdit()" role="button">Update</button>                    
                </div>
            </div>            

            <!-- Deactivate Form -->
            <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyleDeactivate}">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">Deactivate Master Module</h4>
                        </div>
                        <form [formGroup]="DeactivateInfo">
                            <div class="modal-body">
                                Are you sure you want to Deactivate?
                            </div>
                            <input type="hidden" class="form-control" formControlName="delete_id" id="delete_id" />
                            <div class="modal-footer">
                                <button type="button" class="btn btn-primary" (click)="onSubmitDeactivate()"
                                    role="button">Deactivate</button>
                                <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <!-- Activate Form -->
            <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyleActivate}">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">Activate Master Module</h4>
                        </div>
                        <form [formGroup]="ActivateInfo">
                            <div class="modal-body">
                                Are you sure you want to Activate?
                            </div>
                            <input type="hidden" class="form-control" formControlName="active_delete_id" id="active_delete_id" />
                            <div class="modal-footer">
                                <button type="button" class="btn btn-primary" (click)="onSubmitActivate()"
                                    role="button">Activate</button>
                                <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        
        </div>
        <app-footer></app-footer>
    </div>
</div>