import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class Service {

  AUTH_API = environment.apiURL;

  constructor(private http: HttpClient) { } 

  getInfo(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'employee-help-list', 
    data,
      httpOptions);
  }

}