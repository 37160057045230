import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { InsuranceCompanyInsurerService } from '../insurance-company-insurer/insurance-company-insurer';
import {Subject} from 'rxjs';
import { ToastService } from '../../toast.service';

@Component({
  selector: 'app-insurance-company-insurer',
  templateUrl: './insurance-company-insurer.component.html',
  styleUrls: ['./insurance-company-insurer.component.css']
})
export class InsuranceCompanyInsurerComponent implements OnInit {

  addflag: any = 0;

  show = false;
  fullScreen = true;
  template = ``;

  user_role: any;
  validmsg: any;
  resstatus = localStorage.getItem('resstatus');
  type_name = localStorage.getItem('type_name');

  public AddInfo:FormGroup;
  AddInfoSubmitted: boolean = false;

  public EditInfo:FormGroup;
  EditInfoSubmitted: boolean = false;

  public DeactivateInfo:FormGroup;
  public ActivateInfo:FormGroup;
  
  InfoArray: any = [];   
  ProductInfoArray: any = [];   

  displayStyle_add = "none";
  displayStyle_edit = "none";
  displayStyleDeactivate = "none";
  displayStyleActivate = "none";

  readaccess   : any = 0;
  writeaccess  : any = 0;
  deleteaccess :  any = 0;
  modulename = 'Insurance Company';

  InfoArrayBroker: any = [];  

  dtOptions: DataTables.Settings = {
    destroy: true,
    columnDefs: [
      { orderable: true, className: 'reorder', targets: 0 },
      { orderable: false, targets: '_all' }
    ]
  };
  dtTrigger: Subject<any> = new Subject<any>();  

  constructor(private fb: FormBuilder, private dataservice: InsuranceCompanyInsurerService, private route: Router, private toastService: ToastService) {
    this.route.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.user_role = localStorage.getItem('type_name');
      }
      if (this.resstatus == null) {
        this.route.navigate(['/home']);
      }
    });
   }

   ngOnInit(): void {

    this.initGroup();

    setTimeout(() => {
      $('.dataTables_wrapper .dataTables_filter input').attr('placeholder', 'Search...');
    }, 1000);

  }

  initGroup(){

    this.InfoArray = [];
    this.getInfo();
    this.getProductInfo();
    this.assignrights();
    this.getBroker();
    
    // Form element defined below
    this.AddInfo = this.fb.group({
      contact_person: new FormControl('', Validators.required),
      name: new FormControl('', Validators.required),
      tag_name: new FormControl('', Validators.required),
      broker_idfk: new FormControl('', Validators.required)
    });

    // Form element defined below
    this.EditInfo = this.fb.group({
      contact_person_edit: new FormControl('', Validators.required),
      name_edit: new FormControl('', Validators.required),
      tag_name_edit: new FormControl('', Validators.required),
      broker_idfk_edit: new FormControl('', Validators.required),
      edit_id: new FormControl(''),
      edit_is_active: new FormControl(''),
    });

    // Form element defined below
    this.DeactivateInfo = this.fb.group({
      delete_id: new FormControl('')      
    });

    // Form element defined below
    this.ActivateInfo = this.fb.group({
      active_delete_id: new FormControl('')      
    });

  }

  //Add
  get getAddInformationFormControls() {
    return this.AddInfo.controls;
  }

  openPopupAdd(){
    this.addflag = 1;    
  }  

  closePopupAdd(){
    this.addflag = 0;      
    window.location.reload();    
  }  

  getBroker(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;
    
    this.dataservice.getBroker()
        .subscribe((result: any) => {          
          
          this.show = false;
          this.InfoArrayBroker = result.data; 

        }, (error) => {          
          
          this.InfoArrayBroker = [];
          
      });
  }

  onSubmitAdd() {

    this.AddInfoSubmitted = true;
    if (this.AddInfoSubmitted && this.AddInfo.valid) {
      
      this.show = true;
      this.fullScreen = true;
      this.template = ``; 
      
      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "contact_person":this.AddInfo.controls['contact_person'].value,
        "name":this.AddInfo.controls['name'].value,
        "broker_idfk":this.AddInfo.controls['broker_idfk'].value,
        "product_id":this.AddInfo.controls['tag_name'].value,
        "client_id":localStorage.getItem('client_id')
      };
      // console.log(postdata);

      this.dataservice.AddInfo(postdata)
        .subscribe((result: any) => {
          
          // console.log(result);

          if (result.status == true) {  
            
            this.show = false;
            this.toastService.showSuccess('Success', 'Details Added Successfully!');        

            setTimeout(() => {
              window.location.reload();
            },3000);

            this.getInfo();
            this.dtTrigger.unsubscribe();
            this.validmsg = result.message;            

          }else {            
            this.show = false;         
          }
        }, (error) => {          

          if(error.error.message == 'Unauthenticated.'){
            localStorage.clear();
            localStorage.setItem('resstatus', 'false');
            this.route.navigate(['/login']);
          }
          
          this.toastService.showFailed('Failed', 'There was some error !');                      
      });
      
    } 
  }

  //Edit
  get getEditInformationFormControls() {
    return this.EditInfo.controls;
  }

  openPopupEdit(res: any){          

    this.addflag = 2;

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id":res.insure_comp_id
    };

    this.dataservice.InfoSingle(postdata)
      .subscribe((result: any) => {
        
        // console.log(result.data[0]['product_id_fk']);

        this.EditInfo.controls['contact_person_edit'].setValue(result.data[0]['contact_person']);
        this.EditInfo.controls['name_edit'].setValue(result.data[0]['name']);
        this.EditInfo.controls['broker_idfk_edit'].setValue(result.data[0]['broker_idfk']);
        this.EditInfo.controls['tag_name_edit'].setValue(result.data[0]['product_id_fk']);
        this.EditInfo.controls['edit_is_active'].setValue(result.data[0]['is_active']);
        this.EditInfo.controls['edit_id'].setValue(result.data[0]['insure_comp_id']);        

      }, (error) => {          
               
    });

  }

  onSubmitEdit(){

    let id = $('#edit_id').val();
   
    this.EditInfoSubmitted = true;
    if (this.EditInfoSubmitted && this.EditInfo.valid) {

      this.show = true;
      this.fullScreen = true;
      this.template = ``; 
      
      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "id": id,
        "contact_person":this.EditInfo.controls['contact_person_edit'].value,
        "broker_idfk":this.EditInfo.controls['broker_idfk_edit'].value,
        "name":this.EditInfo.controls['name_edit'].value,
        "product_id":this.EditInfo.controls['tag_name_edit'].value,
        "client_id":localStorage.getItem('client_id'),
        "is_active":this.EditInfo.controls['edit_is_active'].value
      };

      this.dataservice.InfoUpdate(postdata)
        .subscribe((result: any) => {
          
          if (result.status == true) {            
            
            this.show = false;
            this.closePopup();         
            this.toastService.showSuccess('Success', 'Details Updated Successfully!');        

            setTimeout(() => {
              window.location.reload();
            },3000);


          }else {
              this.show = false;
          }
        }, (error) => {          
          this.closePopup();         
          this.toastService.showFailed('Failed', 'There was some error !');                          
      });

    }

  }

  //Delete
  openPopupDeactivate(res: any) {
    this.displayStyleDeactivate = "block";  
    
    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id":res.insure_comp_id
    };

    this.dataservice.InfoSingle(postdata)
      .subscribe((result: any) => {
        
        // console.log(result);
        this.DeactivateInfo.controls['delete_id'].setValue(result.data[0]['insure_comp_id']);
        
      }, (error) => {          
               
    });

  }

  onSubmitDeactivate(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;

    let id = $('#delete_id').val();

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id": id,
      "is_active": 0
    };

    this.dataservice.InfoActiveDeactivate(postdata)
      .subscribe((result: any) => {
        
        if (result.status == true) {            
          
          this.show = false;
          this.toastService.showSuccess('Success', 'Details Deactivated Successfully!'); 
          setTimeout(() => {
            window.location.reload();
          },3000);

          this.closePopup();       
          this.getInfo();
          this.dtTrigger.unsubscribe();

        } else {
          // console.log(result);            
        }
      }, (error) => {  
        this.show = false;  
        this.closePopup();
        this.toastService.showFailed('Failed', 'There was some error !');                             
    });

  }

  //Delete
  openPopupActivate(res: any) {
    this.displayStyleActivate = "block";  
    
    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id":res.insure_comp_id
    };

    this.dataservice.InfoSingle(postdata)
      .subscribe((result: any) => {
        
        // console.log(result);
        this.ActivateInfo.controls['active_delete_id'].setValue(result.data[0]['insure_comp_id']);
        
      }, (error) => {          
               
    });

  }

  onSubmitActivate(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;

    let id = $('#active_delete_id').val();

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id": id,
      "is_active": 1
    };

    this.dataservice.InfoActiveDeactivate(postdata)
      .subscribe((result: any) => {
        
        if (result.status == true) {            
          
          this.show = false;

          this.toastService.showSuccess('Success', 'Details Activated Successfully!');     
          setTimeout(() => {
            window.location.reload();
          },3000);
              
          this.closePopup();       
          this.getInfo();
          this.dtTrigger.unsubscribe();

        } else {
          // console.log(result);            
        }
      }, (error) => {  
        this.show = false;  
        this.closePopup();
        this.toastService.showFailed('Failed', 'There was some error !');                      
    });

  }

  //Common functions
  getInfo(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``; 

    this.dataservice.getInfo()
        .subscribe((result: any) => {          
          
          this.show = false;
          // console.log(result);
          this.InfoArray = result.data; 
          this.dtTrigger.next();

        }, (error) => {          
          
          this.InfoArray = [];
          
      });
  }

  getProductInfo(){
    this.dataservice.getProductInfo()
        .subscribe((result: any) => {          
          
          // console.log(result);
          this.ProductInfoArray = result; 

        }, (error) => {          
          
          this.ProductInfoArray = [];
          
      });
  }

  closePopup() {    

    this.displayStyle_add = "none";
    this.displayStyle_edit = "none";
    this.displayStyleDeactivate = "none";
    this.displayStyleActivate = "none";  
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  assignrights(){
    let module_info_arr:any = localStorage.getItem("module_info");
    let module_info = JSON.parse(module_info_arr);
    for(var i=0;i<module_info.length;i++){
      if(module_info[i]['child'].length==0){
        if(module_info[i]['module_content']==this.modulename){
          this.writeaccess = module_info[i]['module_write'];
          this.readaccess = module_info[i]['module_read'];
          this.deleteaccess = module_info[i]['module_delete'];
        }
      }else{
        for(var j=0;j<module_info[i]['child'].length;j++){
          let json = module_info[i]['child'][j];
          if(json['module_content']==this.modulename){
            this.writeaccess = json['module_write'];
            this.readaccess = json['module_read'];
            this.deleteaccess = json['module_delete'];
          }
        }
      }
    }
  }

}
