<!-- Body -->
<div class="container-fluid page-body-wrapper">

    <!-- Sidebar -->
    <app-sidebar></app-sidebar>

    <!-- Main Wrapper -->
    <div class="main-panel">
        <div class="content-wrapper pt-3">
            <ngx-loader [show]="show" [fullScreen] = "fullScreen" [template]="template" style="z-index: 500000; position: absolute;"> Loading... </ngx-loader>

            <h3 class="bs-title">Health Benefit Program - Insurance
                <!-- <span class="spanfloat">
                    <a href="">Need Assistance for Medical Queries</a>
                </span> -->
                <!-- <button class="btn btn-primary editbtn">
                    Edit
                </button>-->
                <!-- <button class="btn btn-warning resetbtn">Reset</button> -->
                <button class="btn btn-warning editbtn" (click)="openmodal()">Product Features</button>
                <!-- <button class="btn btn-warning editbtn" (click)="openCoverages()">Coverages</button> -->
                <!-- <button class="btn btn-warning editbtn" (click)="openOPDBenefits()">OPD Benfits</button> -->
            </h3>
            <hr class="mb-2 mt-4" style="height: 0px">

            <!-- Tabs -->
            <div class="col-sm-12 mt-4">
                
                <!-- Tabs Headings -->
                <ul class="nav nav-tabs" id="myTab" role="tablist"
                    style="border-radius: 10px 10px 0px 0px;">
                    <li class="nav-item liwidth" role="presentation" *ngIf="gmcbasearray.length > 0">
                        <button class="nav-link w100" id="Add-tab" data-bs-toggle="tab" data-bs-target="#Add"
                            type="button" role="tab" aria-controls="home" aria-selected="true">Group Mediclaim</button>
                    </li>
                    <li class="nav-item liwidth" role="presentation" *ngIf="opdbasearray.length > 0">
                        <button class="nav-link w100" id="OPD-tab" data-bs-toggle="tab"
                            data-bs-target="#OPD" type="button" role="tab" aria-controls="OPD"
                            aria-selected="false">OPD</button>
                    </li>
                    <li class="nav-item liwidth" role="presentation" *ngIf="gpabasearray.length > 0" >
                        <button class="nav-link w100" id="Remove-tab" data-bs-toggle="tab" 
                            data-bs-target="#Remove" type="button" role="tab" aria-controls="Remove"
                            aria-selected="false">Group Personal Accident</button>
                    </li>
                    <li class="nav-item liwidth" role="presentation" *ngIf="gtlbasearray.length > 0">
                        <button class="nav-link w100" id="Corrections-tab" data-bs-toggle="tab"
                            data-bs-target="#Corrections" type="button" role="tab" aria-controls="Corrections"
                            aria-selected="false">Group Term Life</button>
                    </li>                     
                </ul>

                <!-- Tabs Content -->
                <div class="tab-content tabUI" id="myTabContent" style="border: solid 1px #9e9e9e42;">
 
                    <!-- Tab Group Mediclaim -->
                    <div class="tab-pane fade" id="Add" role="tabpanel" aria-labelledby="Add-tab" *ngIf="gmcbasearray.length > 0">
                        <button class="rightbtn_gmc" id="rightbtn_gmc" (click)="hideAndExpand_gmc($event)">
                            <img src="../../../assets/img/employee/wrarrow.png" class="wrarrow_gmc" style="background-color: #005963; padding: 2px;" alt="img" />
                        </button>
                        <div class="row">

                            <div class="col-sm-7" id="colSm7_gmc" style="padding-left: 10px;">
                               
                                <!-- Tabs -->
                                <div class="bgdiv">                                
                                    <ul class="nav nav-tabs ul_gmc" id="myTab_gmcBanner" role="tablist">
                                        <li class="nav-item liwidth2" *ngFor="let policy of banner_slider; let i = index" role="presentation">
                                            <button 
                                                class="nav-link gmc_link" 
                                                [class.active]="i === activeIndex_banner_gmc" 
                                                [attr.id]="'policy-' + policy.policy_id + '-tab'"
                                                data-bs-toggle="tab" 
                                                [attr.data-bs-target]="'#policy-' + policy.policy_id"
                                                type="button" 
                                                role="tab" 
                                                [attr.aria-controls]="'policy-' + policy.policy_id" 
                                                [attr.aria-selected]="i === activeIndex_banner_gmc"
                                                (click)="setActiveTab(i,activeIndex_banner_gmc)">
                                                {{ policy.name }}
                                            </button>
                                        </li>
                                    </ul>                                
                                    <div class="tab-content ul_gmc_content" id="myTabContent_gmcBanner">
                                        <div *ngFor="let item of banner_slider; let i = index" class="tab-pane fade" 
                                            [class.show]="i === activeIndex_banner_gmc" 
                                            [class.active]="i === activeIndex_banner_gmc" 
                                            [id]="'policy-' + item.policy_id"
                                            role="tabpanel" 
                                            [attr.aria-labelledby]="item.policy_id + '-tab'">
                                        
                                            <h5 style="color: var(--bs-sidebar);">
                                                <div class="row">
                                                    <div class="col-sm-3 pt-2">
                                                        Family Construct
                                                    </div>
                                                    <div class="col-sm-6 pt-2">
                                                        <span *ngIf="item.relation.length > 1" class="textrelation" [title]="item.relation.toString()">
                                                            {{item.relation.toString()}}
                                                        </span>
                                                        <span *ngIf="item.relation.length === 1" [title]="item.relation.toString()">
                                                            {{item.relation.toString()}}
                                                        </span>
                                                    </div>
                                                    <div class="col-sm-3">
                                                        <div style="font-size: 12px; 
                                                                    cursor:pointer; padding: 10px; 
                                                                    width: fit-content; background: #005963; float: right;
                                                                    color:#fff; font-weight: bold; z-index: 100" 
                                                                    (click)="getemployeepoliciesmembers(item)">
                                                            View Members
                                                        </div>                                            
                                                    </div>
                                                </div>                                                                                               
                                            </h5>
                                            
                                            <h5 class="view_details">
                                                <div class="row">
                                                    <div class="col-sm-3">
                                                        Sum Insured
                                                    </div>
                                                    <div class="col-sm-6">
                                                        <span>
                                                            {{commonservice.moneyformatter_flex(item.cover)}}
                                                        </span>
                                                    </div>                
                                                </div>                                
                                            </h5>

                                            <h5 class="premium_details">
                                                <div class="row">
                                                    <div class="col-sm-3">
                                                        Employer Premium
                                                    </div>
                                                    <div class="col-sm-6">
                                                        <span>
                                                            <b>{{commonservice.moneyformatter(item.employer_pay_premium)}}</b><br>
                                                            <small> 
                                                                <span *ngIf="item.is_premium_tax==1">(Tax Included)</span>
                                                                <span *ngIf="item.is_premium_tax==0">(Tax Excluded)</span>
                                                            
                                                            </small>
                                                        </span>
                                                    </div>                                                                                                                                            
                                                </div>
                                            </h5>                                            
                                        </div>
                                    </div>                          
                                </div>
                                <!-- End -->

                                <h3 *ngIf="gmc_flex_plan.length > 0" class="bs-title mt-4" 
                                    style="color: #000; padding: 0px 10px; z-index: 100; position: relative;">
                                    Add on Coverages OR Modular Benefits
                                    <span class="spanfloat" (click)="modalCoverages(gmc_flex_plan)">
                                        <a style="color: red">See all
                                            <img src="../../../assets/img/employee/right.png" alt="image" class="rightarrow" />
                                        </a>
                                    </span>
                                </h3>

                                <!-- Slider 1 -->
                                <section id="multipleslider_gmc" class="intro-section appear-animate" *ngIf="gmc_flex_plan.length > 0">                                    
                                    <!-- Carousel HTML Structure -->
                                    <div id="imageCarousel_multiple_gmc" class="carousel slide" data-ride="carousel">
                                        <div class="carousel-inner">
                                            <!-- Generate carousel items dynamically -->
                                            <div class="carousel-item" *ngFor="let slide of gmc_flex_plan; let i = index;" [class.active]="i === activeIndex_benefit_gmc">
                                                <div class="d-flex">
                                                    <div class="col-4"  *ngFor="let item of slide; let j = index">
                                                        <div style="border-radius: 10px; text-align: center; border: solid 1px; background-color: #fff;">
                                                            <h1 class="bs-title text-white" style="font-size: 16px; padding: 12px; border-radius: 10px 10px 0px 0px; background-color: #1AADA9;">Active Plan</h1>
                                                            <h1 class="bs-title text-ellipsis-word" style="margin: 13px 0px;" title="{{item.benefit_name}}">{{item.benefit_name}}</h1>
                                                            <table class="table table-bordered tableborder" style="table-layout: fixed;">
                                                                <thead>
                                                                    <tr style="background: #fff; text-align: center;">
                                                                        <th class="p-2 fs13">Sum Insured</th>
                                                                        <th class="p-2 fs13">Premium</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr style="text-align: center;">
                                                                        <td class="p-2 fs13">{{commonservice.moneyformatter_flex(item.sum_insured)}}</td>
                                                                        <td class="p-2 fs13">
                                                                            <span *ngIf="item.total_premium !== '-'">{{commonservice.moneyformatter(item.total_premium)}}</span><br>                                                                                        
                                                                            <span *ngIf="item.is_premium_tax === 1 && item.total_premium !== '-'">(Tax Included)</span>
                                                                            <span *ngIf="item.is_premium_tax === 0 && item.total_premium !== '-'">(Tax Excluded)</span>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>                                                            
                                                            <button class="btn btn-primary my-2" style="border: solid 2px; margin: auto; display: block;" (click)="openOPDBenefits(item.flex_benefit_id, item.policy_id, 'gmc_dropdown')">More Details</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>                                                                                                                                                                                
                                        </div>
                                    
                                        <div class="pagination-numbers-gmc-ancillary d-flex align-items-center" style="float:right">
                                            <!-- Double Left Arrow: Moves to the first slide -->
                                            <button 
                                                type="button" 
                                                class="btn" 
                                                [disabled]="activeIndex_benefit_gmc === 0" 
                                                (click)="setActiveIndex_benefit_gmc(0)">
                                                «
                                            </button>
                                        
                                            <!-- Single Left Arrow: Moves to the previous slide -->
                                            <button 
                                                type="button" 
                                                class="btn" 
                                                [disabled]="activeIndex_benefit_gmc === 0" 
                                                (click)="setActiveIndex_benefit_gmc(activeIndex_benefit_gmc - 1)">
                                                ‹
                                            </button>
                                        
                                            <!-- Pagination Numbers -->
                                            <button 
                                                *ngFor="let slide of gmc_flex_plan; let i = index" 
                                                type="button" 
                                                data-bs-target="#imageCarousel_multiple_gmc" 
                                                [attr.data-bs-slide-to]="i" 
                                                [class.active-pagination-gmc-ancillary]="activeIndex_benefit_gmc === i"
                                                (click)="setActiveIndex_benefit_gmc(i)" 
                                                class="btn">
                                                {{ i + 1 }}
                                            </button>
                                        
                                            <!-- Single Right Arrow: Moves to the next slide -->
                                            <button 
                                                type="button" 
                                                class="btn" 
                                                [disabled]="activeIndex_benefit_gmc === gmc_flex_plan.length - 1" 
                                                (click)="setActiveIndex_benefit_gmc(activeIndex_benefit_gmc + 1)">
                                                ›
                                            </button>
                                        
                                            <!-- Double Right Arrow: Moves to the last slide -->
                                            <button 
                                                type="button" 
                                                class="btn" 
                                                [disabled]="activeIndex_benefit_gmc === gmc_flex_plan.length - 1" 
                                                (click)="setActiveIndex_benefit_gmc(gmc_flex_plan.length - 1)">
                                                »
                                            </button>
                                        </div>                                        
                                    </div>
                                </section>                                   
                                <!-- End --> 
                                
                                <!-- Slider 2 -->
                                <div style="margin-top: 20px; border-radius: 10px 10px 0px 0px;">
                                    <h3 class="bs-title fs16 mb-0" style="padding: 15px 10px; background-color: #BFBFBF; color: var(--bs-sidebar); border-radius: 10px 10px 0px 0px;" *ngIf="ancillaryData.length > 0">My Personal Care
                                        <span class="spanfloat">
                                            <a style="color: red">See all
                                            <img src="../../../assets/img/employee/right.png" alt="image" class="rightarrow" />
                                        </a>
                                        </span>
                                    </h3>
                                    <section id="multipleslider2" class="intro-section appear-animate"  *ngIf="ancillaryData.length > 0">
                                        <div class="thirddiv pb-0">
                                            <div id="imageCarousel_multiple1" class="carousel slide" data-ride="carousel">
                                                <div class="carousel-inner">
                                                    <!-- First Slide -->
                                                    <div class="carousel-item" *ngFor="let slide of ancillaryData; let i = index;" [class.active]="i === 0">
                                                        <div class="d-flex" style="margin-bottom: 0px; padding-bottom: 0px; background-color: #fff;">
                                                            <div class="sliderinnerdiv2" *ngFor="let item of slide; let j = index" (click)="gotopage(item.id)" style="cursor: pointer;">
                                                                <img src="{{item.ancillary_logo}}" class="d-block w-100 slider2img" alt="Car Image">
                                                                <p class="slider2p">{{item.ancillary_name}}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
    
                                                <div *ngIf="ancillaryData.length > 1">
                                                    <a class="carousel-control-prev" href="#imageCarousel_multiple1" role="button" data-slide="prev">
                                                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                                    </a>
                                                    <a class="carousel-control-next" href="#imageCarousel_multiple1" role="button" data-slide="next">
                                                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                                    </a>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </section>
                                    
                                </div>
                                <!-- End -->

                            </div>                            

                            <div class="col-sm-5" id="colSm5_gmc" style="background-color: #fff; border-radius: 4px;">
                                <h3 class="bs-title text-center mt-3 fs18" style="padding: 0px 10px;"> Plans Summary</h3>

                                <div class="mt-3">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <span style="color: #f5620d; font-weight: bold; font-size: 12px;  float: right;  position: relative; top: 4px;">
                                                {{commonservice.moneyformatter(gmc_total_youpay_premium)}} (Tax Included)
                                            </span>
                                            <span style="color: #20B157; font-weight: bold; font-size: 12px;">
                                                {{commonservice.moneyformatter(gmc_total_employer_pay_premium)}} (Tax Included)
                                            </span>
                                            <div class="progress">
                                                <div class="progress-bar bg-success p1" role="progressbar" style="width: {{gmc_employer_pay+'%'}}" attr.aria-valuenow="{{gmc_employer_pay}}" aria-valuemin="0" aria-valuemax="100"></div>
                                                <div class="progress-bar bg-warning p2" role="progressbar" style="width: {{gmc_you_pay+'%'}}" attr.aria-valuenow="{{gmc_you_pay}}" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                            <span style="color: #f5620d; font-weight: bold; font-size: 12px; position: relative; float: right;">
                                                You Pay Premium
                                            </span>
                                            <span style="color: #20B157; font-weight: bold; font-size: 12px;  position: relative; top: -2px;">
                                                Employer Pays
                                            </span>                        
                                        </div>                                        
                                    </div>
                                </div>

                                <!-- GMC BASE PLANS -->
                                <div class="mt-3" *ngFor="let row of gmcbasearray;">
                                    <h2 class="cardheading" style="background-color: #005963;">{{row.name}}</h2>
                                    <table class="table table-bordered table-sm tableborder" style="table-layout: fixed; border: solid 1px #dee2e6;">
                                        <thead>
                                            <tr>
                                                <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Relation</th>
                                                <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Cover</th>
                                                <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Premium</th>
                                            </tr>
                                        </thead>
                                        <tbody style="background: #fff;">
                                            <tr>
                                                <td rowspan="2" style="padding: 0px;">
                                                    <u style="list-style: none; line-height: 24px; text-decoration: none;">
                                                        <li *ngFor="let relations of row.relation;let last = last" [ngClass]="{'border-bottom': !last}">{{ relations }}</li>                                                
                                                    </u>                                           
                                                </td>
                                                <td style="padding-left:0px;padding-right:0px;">{{commonservice.moneyformatter_flex(row.cover)}}</td>
                                                <td style="padding-left:0px;padding-right:0px;">                     
                                                    <span *ngIf="row.premium != '-'">{{commonservice.moneyformatter(row.premium)}}</span><br>                                                                                        
                                                    <span *ngIf="row.is_premium_tax == '1' && row.premium != '-'" style="font-size: 10px;">(Tax Included)</span>
                                                    <span *ngIf="row.is_premium_tax == '0' && row.premium != '-'" style="font-size: 10px;">(Tax Excluded)</span>                                            
                                                </td>
                                            </tr>                                    
                                        </tbody>
                                    </table>                            
                                </div>

                                <!-- GMC VOLUNTARY PLANS -->
                                <div class="mt-3" *ngFor="let row of gmcvoluntaryplans;">
                                    <h2 class="cardheading" style="background-color: #005963;">{{row.name}}</h2>
                                    <table class="table table-bordered table-sm tableborder" style="table-layout: fixed; border: solid 1px #dee2e6;">
                                        <thead>
                                            <tr>
                                                <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Relation</th>
                                                <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Cover</th>
                                                <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Premium</th>
                                            </tr>
                                        </thead>
                                        <tbody style="background: #fff;">
                                            <tr>
                                                <td rowspan="2" style="padding: 0px;">
                                                    <u style="list-style: none; line-height: 24px; text-decoration: none;">
                                                        <li *ngFor="let relations of row.relation;let last = last" [ngClass]="{'border-bottom': !last}">{{ relations }}</li>                                                
                                                    </u>                                           
                                                </td>
                                                <td style="padding-left:0px;padding-right:0px;">{{commonservice.moneyformatter_flex(row.cover)}}</td>
                                                <td style="padding-left:0px;padding-right:0px;">                     
                                                    <span *ngIf="row.premium != '-'">{{commonservice.moneyformatter(row.premium)}}</span><br>                                                                                        
                                                    <span *ngIf="row.is_premium_tax == '1' && row.premium != '-'" style="font-size: 10px;">(Tax Included)</span>
                                                    <span *ngIf="row.is_premium_tax == '0' && row.premium != '-'" style="font-size: 10px;">(Tax Excluded)</span>                                            
                                                </td>
                                            </tr>                                    
                                        </tbody>
                                    </table>                            
                                </div>

                                <!-- GMC ADDITIONAL PLANS-->
                                <div class="mt-3" *ngFor="let row of gmcadditionalarray;">
                                    <h2 class="cardheading" style="background-color: #005963;">{{row.name}}</h2>
                                    <table class="table table-bordered table-sm tableborder" style="table-layout: fixed; border: solid 1px #dee2e6;">
                                        <thead>
                                            <tr>
                                                <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Relation</th>
                                                <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Cover</th>
                                                <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Premium</th>
                                            </tr>
                                        </thead>
                                        <tbody style="background: #fff;">
                                            <tr>
                                                <td rowspan="2" style="padding: 0px;">
                                                    <u style="list-style: none; line-height: 24px; text-decoration: none;">
                                                        <li *ngFor="let relations of row.relation;let last = last" [ngClass]="{'border-bottom': !last}">{{ relations }}</li>                                                
                                                    </u>                                           
                                                </td>
                                                <td style="padding-left:0px;padding-right:0px;">{{commonservice.moneyformatter_flex(row.cover)}}</td>
                                                <td style="padding-left:0px;padding-right:0px;">                     
                                                    <span *ngIf="row.premium != '-'">{{commonservice.moneyformatter(row.premium)}}</span><br>                                                                                        
                                                    <span *ngIf="row.is_premium_tax == '1' && row.premium != '-'" style="font-size: 10px;">(Tax Included)</span>
                                                    <span *ngIf="row.is_premium_tax == '0' && row.premium != '-'" style="font-size: 10px;">(Tax Excluded)</span>                                            
                                                </td>
                                            </tr>                                    
                                        </tbody>
                                    </table>                            
                                </div>

                                <!-- GMC Flex Plans -->
                                <div class="mt-3" *ngFor="let row of gmcFlexPlan;">
                                    <h2  *ngIf="row.gmc_base == 1" class="cardheading" style="background-color: #005963;">Add On Benefit - Base Policy</h2>
                                    <h2  *ngIf="row.gmc_base == 2" class="cardheading" style="background-color: #005963;">Add On Benefit - TopUp Policy</h2>
                                    <table class="table table-bordered table-sm tableborder" style="table-layout: fixed; border: solid 1px #dee2e6;">
                                        <thead>
                                            <tr>
                                                <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Relation</th>
                                                <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Cover</th>
                                                <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Premium</th>
                                            </tr>
                                        </thead>
                                        <tbody style="background: #fff;">
                                            <tr>
                                                <td style="padding-left:0px;padding-right:0px; text-wrap: wrap;">{{row.benefit_name}}</td>
                                                <td style="padding-left:0px;padding-right:0px;">{{commonservice.moneyformatter_flex(row.sum_insured)}}</td>
                                                <td style="padding-left:0px;padding-right:0px;">                     
                                                    <span *ngIf="row.total_premium != '-'">{{commonservice.moneyformatter(row.total_premium)}}</span><br>                                                                                        
                                                    <span *ngIf="row.is_premium_tax == '1' && row.total_premium != '-'" style="font-size: 10px;">(Tax Included)</span>
                                                    <span *ngIf="row.is_premium_tax == '0' && row.total_premium != '-'" style="font-size: 10px;">(Tax Excluded)</span>                                            
                                                </td>
                                            </tr>                                    
                                        </tbody>
                                    </table>                            
                                </div>
                                
                            </div>

                            <!-- Disclaimer -->
                            <div class="col-sm-12 mt-3 p-2"  *ngIf="gmc_disclaimer.length > 0 || gmc_disclaimer.length != ''">
                                <div class="accordion" id="myAccordion">
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingOne">
                                            <button type="button" class="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" style="color: var(--bs-sidebar); background-color: #dedede; font-weight: bold;">
                                                *Disclaimer
                                            </button>
                                        </h2>
                                        <div id="collapseOne" class="accordion-collapse show" data-bs-parent="#myAccordion">
                                            <div class="card-body accorCardbody" style="font-size: 13px;">
                                                {{gmc_disclaimer}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>                        
                    </div>

                    <!-- Tab OPD -->
                    <div class="tab-pane fade" id="OPD" role="tabpanel" aria-labelledby="OPD-tab" *ngIf="opdbasearray.length > 0">
                        <button class="rightbtn_opd" id="rightbtn_opd" (click)="hideAndExpand_opd($event)">
                            <img src="../../../assets/img/employee/wrarrow.png" class="wrarrow_opd" style="background-color: #00ACB1; padding: 2px;" alt="img" />
                        </button>
                        <div class="row">

                            <div class="col-sm-7" id="colSm7_opd" style="padding-left: 10px;">

                                <!-- Tabs -->
                                <div class="bgdiv">                                
                                    <ul class="nav nav-tabs ul_opd" id="myTab_opdBanner" role="tablist">
                                        <li class="nav-item liwidth2" *ngFor="let policy of banner_slider_opd; let i = index" role="presentation">
                                            <button 
                                                class="nav-link opd_link" 
                                                [class.active]="i === activeIndex_banner_opd" 
                                                [attr.id]="'policy-' + policy.policy_id + '-tab'"
                                                data-bs-toggle="tab" 
                                                [attr.data-bs-target]="'#policy-' + policy.policy_id"
                                                type="button" 
                                                role="tab" 
                                                [attr.aria-controls]="'policy-' + policy.policy_id" 
                                                [attr.aria-selected]="i === activeIndex_banner_opd"
                                                (click)="setActiveTab(i,activeIndex_banner_opd)"
                                                style="background-color: #00ACB1">
                                                {{ policy.name }}
                                            </button>
                                        </li>
                                    </ul>                                
                                    <div class="tab-content ul_opd_content" id="myTabContent_opdBanner">
                                        <div *ngFor="let item of banner_slider_opd; let i = index" class="tab-pane fade" 
                                            [class.show]="i === activeIndex_banner_opd" 
                                            [class.active]="i === activeIndex_banner_opd" 
                                            [id]="'policy-' + item.policy_id"
                                            role="tabpanel" 
                                            [attr.aria-labelledby]="item.policy_id + '-tab'">
                                        
                                            <h5 style="color: var(--bs-sidebar);">
                                                <div class="row">
                                                    <div class="col-sm-3 pt-2">
                                                        Family Construct
                                                    </div>
                                                    <div class="col-sm-6 pt-2">
                                                        <span *ngIf="item.relation.length > 1" class="textrelation" [title]="item.relation.toString()">
                                                            {{item.relation.toString()}}
                                                        </span>
                                                        <span *ngIf="item.relation.length === 1" [title]="item.relation.toString()">
                                                            {{item.relation.toString()}}
                                                        </span>
                                                    </div>
                                                    <div class="col-sm-3">
                                                        <div style="font-size: 12px; 
                                                                    cursor:pointer; padding: 10px; 
                                                                    width: fit-content; background: #00ACB1; float: right;
                                                                    color:#fff; font-weight: bold; z-index: 100" 
                                                                    (click)="getemployeepoliciesmembers(item)">
                                                            View Members
                                                        </div>                                            
                                                    </div>
                                                </div>                                                                                               
                                            </h5>
                                            
                                            <h5 class="view_details">
                                                <div class="row">
                                                    <div class="col-sm-3">
                                                        Sum Insured
                                                    </div>
                                                    <div class="col-sm-6">
                                                        <span>
                                                            {{commonservice.moneyformatter_flex(item.cover)}}
                                                        </span>
                                                    </div>                
                                                </div>                                
                                            </h5>

                                            <h5 class="premium_details">
                                                <div class="row">
                                                    <div class="col-sm-3">
                                                        Employer Premium
                                                    </div>
                                                    <div class="col-sm-6">
                                                        <span>
                                                            <b>{{commonservice.moneyformatter(item.employer_pay_premium)}}</b><br>
                                                            <small> 
                                                                <span *ngIf="item.is_premium_tax==1">(Tax Included)</span>
                                                                <span *ngIf="item.is_premium_tax==0">(Tax Excluded)</span>
                                                            
                                                            </small>
                                                        </span>
                                                    </div>                                                                                                                                            
                                                </div>
                                            </h5>                                            
                                        </div>
                                    </div>                          
                                </div>
                                <!-- End -->
                               
                                <h3 *ngIf="opd_flex_plan.length > 0" class="bs-title mt-4" style="color: #000; padding: 0px 10px; z-index: 100; position: relative;">Add on Coverages OR Modular Benefits
                                    <span class="spanfloat" (click)="modalCoverages(opd_flex_plan)">
                                        <a style="color: red">See all
                                            <img src="../../../assets/img/employee/right.png" alt="image" class="rightarrow" />
                                        </a>
                                    </span>
                                </h3>

                                <!-- Slider 1 -->
                                <section id="multipleslider_opd" class="intro-section appear-animate" *ngIf="opd_flex_plan.length > 0">                                    
                                    <!-- Carousel HTML Structure -->
                                    <div id="imageCarousel_multiple_opd" class="carousel slide" data-ride="carousel">
                                        <div class="carousel-inner" style="margin-bottom: 20px;">
                                            <!-- Generate carousel items dynamically -->
                                            <div class="carousel-item" *ngFor="let slide of opd_flex_plan; let i = index;" [class.active]="i === activeIndex_benefit_opd">
                                                <div class="d-flex">
                                                    <div class="col-4"  *ngFor="let item of slide; let j = index">
                                                        <div style="border-radius: 10px; text-align: center; border: solid 1px; background-color: #fff;">
                                                            <h1 class="bs-title text-white" style="font-size: 16px; padding: 12px; border-radius: 10px 10px 0px 0px; background-color: #1AADA9;">Active Plan</h1>
                                                            <h1 class="bs-title text-ellipsis-word" style="margin: 13px 0px;" title="{{item.benefit_name}}">{{item.benefit_name}}</h1>
                                                            <table class="table table-bordered tableborder" style="table-layout: fixed;">
                                                                <thead>
                                                                    <tr style="background: #fff; text-align: center;">
                                                                        <th class="p-2 fs13">Sum Insured</th>
                                                                        <th class="p-2 fs13">Premium</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr style="text-align: center;">
                                                                        <td class="p-2 fs13">{{commonservice.moneyformatter_flex(item.sum_insured)}}</td>
                                                                        <td class="p-2 fs13">
                                                                            <span *ngIf="item.total_premium !== '-'">{{commonservice.moneyformatter(item.total_premium)}}</span><br>                                                                                        
                                                                            <span *ngIf="item.is_premium_tax === 1 && item.total_premium !== '-'">(Tax Included)</span>
                                                                            <span *ngIf="item.is_premium_tax === 0 && item.total_premium !== '-'">(Tax Excluded)</span>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                            <button class="btn btn-primary my-2" style="border: solid 2px; margin: auto; display: block;" (click)="openOPDBenefits(item.flex_benefit_id, item.policy_id, 'opd_dropdown')">More Details</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>                                                                                                                                                                                
                                        </div>                                      

                                        <!-- Pagination Numbers -->
                                        <div class="pagination-numbers-opd-ancillary d-flex align-items-center" style="float:right">
                                            <!-- Double Left Arrow: Moves to the first slide -->
                                            <button 
                                                type="button" 
                                                class="btn" 
                                                [disabled]="activeIndex_benefit_opd === 0" 
                                                (click)="setActiveIndex_benefit_opd(0)">
                                                «
                                            </button>
                                        
                                            <!-- Single Left Arrow: Moves to the previous slide -->
                                            <button 
                                                type="button" 
                                                class="btn" 
                                                [disabled]="activeIndex_benefit_opd === 0" 
                                                (click)="setActiveIndex_benefit_opd(activeIndex_benefit_opd - 1)">
                                                ‹
                                            </button>
                                        
                                            <!-- Pagination Numbers -->
                                            <button 
                                                *ngFor="let slide of opd_flex_plan; let i = index" 
                                                type="button" 
                                                data-bs-target="#imageCarousel_multiple_opd" 
                                                [attr.data-bs-slide-to]="i" 
                                                [class.active-pagination-opd-ancillary]="activeIndex_benefit_opd === i"
                                                (click)="setActiveIndex_benefit_opd(i)" 
                                                class="btn">
                                                {{ i + 1 }}
                                            </button>
                                        
                                            <!-- Single Right Arrow: Moves to the next slide -->
                                            <button 
                                                type="button" 
                                                class="btn" 
                                                [disabled]="activeIndex_benefit_opd === opd_flex_plan.length - 1" 
                                                (click)="setActiveIndex_benefit_opd(activeIndex_benefit_opd + 1)">
                                                ›
                                            </button>
                                        
                                            <!-- Double Right Arrow: Moves to the last slide -->
                                            <button 
                                                type="button" 
                                                class="btn" 
                                                [disabled]="activeIndex_benefit_opd === opd_flex_plan.length - 1" 
                                                (click)="setActiveIndex_benefit_opd(opd_flex_plan.length - 1)">
                                                »
                                            </button>
                                        </div>

                                    </div>
                                </section>                                   
                                <!-- End --> 
                                
                                <!-- Slider 2 -->
                                <div style="margin-top: 20px; border-radius: 10px 10px 0px 0px;">
                                    <h3 class="bs-title fs16 mb-0" style="padding: 15px 10px; background-color: #BFBFBF; color: var(--bs-sidebar); border-radius: 10px 10px 0px 0px;" *ngIf="ancillaryData.length > 0">My Personal Care
                                        <span class="spanfloat">
                                            <a style="color: red">See all
                                            <img src="../../../assets/img/employee/right.png" alt="image" class="rightarrow" />
                                        </a>
                                        </span>
                                    </h3>
                                    <section id="multipleslider2" class="intro-section appear-animate"  *ngIf="ancillaryData.length > 0">
                                        <div class="thirddiv pb-0">
                                            <div id="imageCarousel_multiple1" class="carousel slide" data-ride="carousel">
                                                <div class="carousel-inner">
                                                    <!-- First Slide -->
                                                    <div class="carousel-item" *ngFor="let slide of ancillaryData; let i = index;" [class.active]="i === 0">
                                                        <div class="d-flex" style="margin-bottom: 0px; padding-bottom: 0px; background-color: #fff;">
                                                            <div class="sliderinnerdiv2" *ngFor="let item of slide; let j = index" (click)="gotopage(item.id)" style="cursor: pointer;">
                                                                <img src="{{item.ancillary_logo}}" class="d-block w-100 slider2img" alt="Car Image">
                                                                <p class="slider2p">{{item.ancillary_name}}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
    
                                                <div *ngIf="ancillaryData.length > 1">
                                                    <a class="carousel-control-prev" href="#imageCarousel_multiple1" role="button" data-slide="prev">
                                                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                                    </a>
                                                    <a class="carousel-control-next" href="#imageCarousel_multiple1" role="button" data-slide="next">
                                                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                                    </a>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </section>
                                    
                                </div>
                                <!-- End -->

                            </div>                            

                            <div class="col-sm-5" id="colSm5_opd" style="background-color: #fff; border-radius: 4px;">
                                <h3 class="bs-title text-center mt-3 fs18" style="padding: 0px 10px;"> Plans Summary</h3>

                                <div class="mt-3">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <span style="color: #f5620d; font-weight: bold; font-size: 12px;  float: right;  position: relative; top: 4px;">
                                                {{commonservice.moneyformatter(opd_total_youpay_premium)}} (Tax Included)
                                            </span>
                                            <span style="color: #20B157; font-weight: bold; font-size: 12px;">
                                                {{commonservice.moneyformatter(opd_total_employer_pay_premium)}} (Tax Included)
                                            </span>
                                            <div class="progress">
                                                <div class="progress-bar bg-success p1" role="progressbar" style="width: {{opd_employer_pay+'%'}}" attr.aria-valuenow="{{opd_employer_pay}}" aria-valuemin="0" aria-valuemax="100"></div>
                                                <div class="progress-bar bg-warning p2" role="progressbar" style="width: {{opd_you_pay+'%'}}" attr.aria-valuenow="{{opd_you_pay}}" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                            <span style="color: #f5620d; font-weight: bold; font-size: 12px; position: relative; float: right;">
                                                You Pay Premium
                                            </span>
                                            <span style="color: #20B157; font-weight: bold; font-size: 12px;  position: relative; top: -2px;">
                                                Employer Pays
                                            </span>                        
                                        </div>                                        
                                    </div>
                                </div>

                                <!-- GMC BASE PLANS -->
                                <div class="mt-3" *ngFor="let row of opdbasearray;">
                                    <h2 class="cardheading" style="background-color: #00ACB1;">{{row.name}}</h2>
                                    <table class="table table-bordered table-sm tableborder" style="table-layout: fixed; border: solid 1px #dee2e6;">
                                        <thead>
                                            <tr>
                                                <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Relation</th>
                                                <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Cover</th>
                                                <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Premium</th>
                                            </tr>
                                        </thead>
                                        <tbody style="background: #fff;">
                                            <tr>
                                                <td rowspan="2" style="padding: 0px;">
                                                    <u style="list-style: none; line-height: 24px; text-decoration: none;">
                                                        <li *ngFor="let relations of row.relation;let last = last" [ngClass]="{'border-bottom': !last}">{{ relations }}</li>                                                
                                                    </u>                                           
                                                </td>
                                                <td style="padding-left:0px;padding-right:0px;">{{commonservice.moneyformatter_flex(row.cover)}}</td>
                                                <td style="padding-left:0px;padding-right:0px;">                     
                                                    <span *ngIf="row.premium != '-'">{{commonservice.moneyformatter(row.premium)}}</span><br>                                                                                        
                                                    <span *ngIf="row.is_premium_tax == '1' && row.premium != '-'" style="font-size: 10px;">(Tax Included)</span>
                                                    <span *ngIf="row.is_premium_tax == '0' && row.premium != '-'" style="font-size: 10px;">(Tax Excluded)</span>                                            
                                                </td>
                                            </tr>                                    
                                        </tbody>
                                    </table>                            
                                </div>

                                <!-- GMC VOLUNTARY PLANS -->
                                <div class="mt-3" *ngFor="let row of opdvoluntaryplans;">
                                    <h2 class="cardheading" style="background-color: #00ACB1;">{{row.name}}</h2>
                                    <table class="table table-bordered table-sm tableborder" style="table-layout: fixed; border: solid 1px #dee2e6;">
                                        <thead>
                                            <tr>
                                                <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Relation</th>
                                                <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Cover</th>
                                                <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Premium</th>
                                            </tr>
                                        </thead>
                                        <tbody style="background: #fff;">
                                            <tr>
                                                <td rowspan="2" style="padding: 0px;">
                                                    <u style="list-style: none; line-height: 24px; text-decoration: none;">
                                                        <li *ngFor="let relations of row.relation;let last = last" [ngClass]="{'border-bottom': !last}">{{ relations }}</li>                                                
                                                    </u>                                           
                                                </td>
                                                <td style="padding-left:0px;padding-right:0px;">{{commonservice.moneyformatter_flex(row.cover)}}</td>
                                                <td style="padding-left:0px;padding-right:0px;">                     
                                                    <span *ngIf="row.premium != '-'">{{commonservice.moneyformatter(row.premium)}}</span><br>                                                                                        
                                                    <span *ngIf="row.is_premium_tax == '1' && row.premium != '-'" style="font-size: 10px;">(Tax Included)</span>
                                                    <span *ngIf="row.is_premium_tax == '0' && row.premium != '-'" style="font-size: 10px;">(Tax Excluded)</span>                                            
                                                </td>
                                            </tr>                                    
                                        </tbody>
                                    </table>                            
                                </div>

                                <!-- GMC ADDITIONAL PLANS-->
                                <div class="mt-3" *ngFor="let row of opdadditionalarray;">
                                    <h2 class="cardheading" style="background-color: #00ACB1;">{{row.name}}</h2>
                                    <table class="table table-bordered table-sm tableborder" style="table-layout: fixed; border: solid 1px #dee2e6;">
                                        <thead>
                                            <tr>
                                                <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Relation</th>
                                                <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Cover</th>
                                                <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Premium</th>
                                            </tr>
                                        </thead>
                                        <tbody style="background: #fff;">
                                            <tr>
                                                <td rowspan="2" style="padding: 0px;">
                                                    <u style="list-style: none; line-height: 24px; text-decoration: none;">
                                                        <li *ngFor="let relations of row.relation;let last = last" [ngClass]="{'border-bottom': !last}">{{ relations }}</li>                                                
                                                    </u>                                           
                                                </td>
                                                <td style="padding-left:0px;padding-right:0px;">{{commonservice.moneyformatter_flex(row.cover)}}</td>
                                                <td style="padding-left:0px;padding-right:0px;">                     
                                                    <span *ngIf="row.premium != '-'">{{commonservice.moneyformatter(row.premium)}}</span><br>                                                                                        
                                                    <span *ngIf="row.is_premium_tax == '1' && row.premium != '-'" style="font-size: 10px;">(Tax Included)</span>
                                                    <span *ngIf="row.is_premium_tax == '0' && row.premium != '-'" style="font-size: 10px;">(Tax Excluded)</span>                                            
                                                </td>
                                            </tr>                                    
                                        </tbody>
                                    </table>                            
                                </div>

                                <!-- GMC Flex Plans -->
                                <div class="mt-3" *ngFor="let row of opdFlexPlan;">
                                    <h2  *ngIf="row.opd_base == 1" class="cardheading" style="background-color: #00ACB1;">Add On Benefit - Base Policy</h2>
                                    <h2  *ngIf="row.opd_base == 2" class="cardheading" style="background-color: #00ACB1;">Add On Benefit - TopUp Policy</h2>
                                    <table class="table table-bordered table-sm tableborder" style="table-layout: fixed; border: solid 1px #dee2e6;">
                                        <thead>
                                            <tr>
                                                <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Relation</th>
                                                <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Cover</th>
                                                <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Premium</th>
                                            </tr>
                                        </thead>
                                        <tbody style="background: #fff;">
                                            <tr>
                                                <td style="padding-left:0px;padding-right:0px; text-wrap: wrap;">{{row.benefit_name}}</td>
                                                <td style="padding-left:0px;padding-right:0px;">{{commonservice.moneyformatter_flex(row.sum_insured)}}</td>
                                                <td style="padding-left:0px;padding-right:0px;">                     
                                                    <span *ngIf="row.total_premium != '-'">{{commonservice.moneyformatter(row.total_premium)}}</span><br>                                                                                        
                                                    <span *ngIf="row.is_premium_tax == '1' && row.total_premium != '-'" style="font-size: 10px;">(Tax Included)</span>
                                                    <span *ngIf="row.is_premium_tax == '0' && row.total_premium != '-'" style="font-size: 10px;">(Tax Excluded)</span>                                            
                                                </td>
                                            </tr>                                    
                                        </tbody>
                                    </table>                            
                                </div>
                                
                            </div>

                            <!-- Disclaimer -->
                            <div class="col-sm-12 mt-3 p-2"  *ngIf="opd_disclaimer.length > 0 || opd_disclaimer.length != ''">
                                <div class="accordion" id="myAccordion">
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingOne">
                                            <button type="button" class="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" style="color: var(--bs-sidebar); background-color: #dedede; font-weight: bold;">
                                                *Disclaimer
                                            </button>
                                        </h2>
                                        <div id="collapseOne" class="accordion-collapse show" data-bs-parent="#myAccordion">
                                            <div class="card-body accorCardbody" style="font-size: 13px;">
                                                {{opd_disclaimer}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>                        
                    </div>

                    <!-- Tab Personal Accident -->
                    <div class="tab-pane fade" id="Remove" role="tabpanel" aria-labelledby="Remove-tab" *ngIf="gpabasearray.length > 0">
                        <button class="rightbtn_gpa" id="rightbtn_gpa" (click)="hideAndExpand_gpa()">
                            <img src="../../../assets/img/employee/wrarrow.png" class="wrarrow_gpa" style="background-color: #005963; padding: 2px;" alt="img" />
                        </button>
                        <div class="row">

                            <div class="col-sm-7" id="colSm7_gpa" style="padding-left: 10px;">

                                <!-- Tabs -->
                                <div class="bgdiv">                                
                                    <ul class="nav nav-tabs ul_gpa" id="myTab_gpaBanner" role="tablist">
                                        <li class="nav-item liwidth2" *ngFor="let policy of banner_slider_gpa; let i = index" role="presentation">
                                            <button 
                                                class="nav-link gpa_link" 
                                                [class.active]="i === activeIndex_banner_gpa" 
                                                [attr.id]="'policy-' + policy.policy_id + '-tab'"
                                                data-bs-toggle="tab" 
                                                [attr.data-bs-target]="'#policy-' + policy.policy_id"
                                                type="button" 
                                                role="tab" 
                                                [attr.aria-controls]="'policy-' + policy.policy_id" 
                                                [attr.aria-selected]="i === activeIndex_banner_gpa"
                                                (click)="setActiveTab(i,activeIndex_banner_gpa)"
                                                style="background-color: #C8535E">
                                                {{ policy.name }}
                                            </button>
                                        </li>
                                    </ul>                                
                                    <div class="tab-content ul_gpa_content" id="myTabContent_gpaBanner">
                                        <div *ngFor="let item of banner_slider_gpa; let i = index" class="tab-pane fade" 
                                            [class.show]="i === activeIndex_banner_gpa" 
                                            [class.active]="i === activeIndex_banner_gpa" 
                                            [id]="'policy-' + item.policy_id"
                                            role="tabpanel" 
                                            [attr.aria-labelledby]="item.policy_id + '-tab'">
                                        
                                            <h5 style="color: var(--bs-sidebar);">
                                                <div class="row">
                                                    <div class="col-sm-3 pt-2">
                                                        Family Construct
                                                    </div>
                                                    <div class="col-sm-6 pt-2">
                                                        <span *ngIf="item.relation.length > 1" class="textrelation" [title]="item.relation.toString()">
                                                            {{item.relation.toString()}}
                                                        </span>
                                                        <span *ngIf="item.relation.length === 1" [title]="item.relation.toString()">
                                                            {{item.relation.toString()}}
                                                        </span>
                                                    </div>
                                                    <div class="col-sm-3">
                                                        <div style="font-size: 12px; 
                                                                    cursor:pointer; padding: 10px; 
                                                                    width: fit-content; background: #C8535E; float: right;
                                                                    color:#fff; font-weight: bold; z-index: 100" 
                                                                    (click)="getemployeepoliciesmembers(item)">
                                                            View Members
                                                        </div>                                            
                                                    </div>
                                                </div>                                                                                               
                                            </h5>
                                            
                                            <h5 class="view_details">
                                                <div class="row">
                                                    <div class="col-sm-3">
                                                        Sum Insured
                                                    </div>
                                                    <div class="col-sm-6">
                                                        <span>
                                                            {{commonservice.moneyformatter_flex(item.cover)}}
                                                        </span>
                                                    </div>                
                                                </div>                                
                                            </h5>

                                            <h5 class="premium_details">
                                                <div class="row">
                                                    <div class="col-sm-3">
                                                        Employer Premium
                                                    </div>
                                                    <div class="col-sm-6">
                                                        <span>
                                                            <b>{{commonservice.moneyformatter(item.employer_pay_premium)}}</b><br>
                                                            <small> 
                                                                <span *ngIf="item.is_premium_tax==1">(Tax Included)</span>
                                                                <span *ngIf="item.is_premium_tax==0">(Tax Excluded)</span>
                                                            
                                                            </small>
                                                        </span>
                                                    </div>                                                                                                                                            
                                                </div>
                                            </h5>                                            
                                        </div>
                                    </div>                          
                                </div>
                                <!-- End -->                            

                                <h3 *ngIf="gpa_flex_plan.length > 0" class="bs-title mt-4" style="color: #000; padding: 0px 10px; z-index: 100; position: relative;">Add on Coverages OR Modular Benefits
                                    <span class="spanfloat" (click)="modalCoverages(gpa_flex_plan)">
                                        <a style="color: red">See all
                                            <img src="../../../assets/img/employee/right.png" alt="image" class="rightarrow" />
                                        </a>
                                    </span>
                                </h3>

                                <!-- Slider 1 -->
                                <section id="multipleslider_gpa" class="intro-section appear-animate" *ngIf="gpaFlexPlan.length > 0">                                    
                                    <!-- Carousel HTML Structure -->
                                    <div id="imageCarousel_multiple_gpa" class="carousel slide" data-ride="carousel">
                                        <div class="carousel-inner" style="margin-bottom: 20px;">
                                            <!-- Generate carousel items dynamically -->
                                            <div class="carousel-item" *ngFor="let slide of gpaFlexPlan; let i = index;" [class.active]="i === activeIndex_benefit_gpa">
                                                <div class="d-flex">
                                                    <div class="col-4"  *ngFor="let item of slide; let j = index">
                                                        <div style="border-radius: 10px; text-align: center; border: solid 1px; background-color: #fff;">
                                                            <h1 class="bs-title text-white" style="font-size: 16px; padding: 12px; border-radius: 10px 10px 0px 0px; background-color: #C8535E;">Active Plan</h1>
                                                            <h1 class="bs-title text-ellipsis-word" style="margin: 13px 0px;" title="{{item.benefit_name}}">{{item.benefit_name}}</h1>
                                                            <table class="table table-bordered tableborder" style="table-layout: fixed;">
                                                                <thead>
                                                                    <tr style="background: #fff; text-align: center;">
                                                                        <th class="p-2 fs13">Sum Insured</th>
                                                                        <th class="p-2 fs13">Premium</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr style="text-align: center;">
                                                                        <td class="p-2 fs13">{{commonservice.moneyformatter_flex(item.sum_insured)}}</td>
                                                                        <td class="p-2 fs13">
                                                                            <span *ngIf="item.total_premium !== '-'">{{commonservice.moneyformatter(item.total_premium)}}</span><br>                                                                                        
                                                                            <span *ngIf="item.is_premium_tax === 1 && item.total_premium !== '-'">(Tax Included)</span>
                                                                            <span *ngIf="item.is_premium_tax === 0 && item.total_premium !== '-'">(Tax Excluded)</span>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                            <button class="btn btn-primary my-2" style="border: solid 2px; margin: auto; display: block;" (click)="openOPDBenefits(item.flex_benefit_id, item.policy_id, 'gpa_dropdown')">More Details</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>                                                                                                                                                                                
                                        </div>                                       

                                        <!-- Pagination Numbers -->
                                        <div class="pagination-numbers-gpa-ancillary d-flex align-items-center" style="float:right">
                                            <!-- Double Left Arrow: Moves to the first slide -->
                                            <button 
                                                type="button" 
                                                class="btn" 
                                                [disabled]="activeIndex_benefit_gpa === 0" 
                                                (click)="setActiveIndex_benefit_gpa(0)">
                                                «
                                            </button>
                                        
                                            <!-- Single Left Arrow: Moves to the previous slide -->
                                            <button 
                                                type="button" 
                                                class="btn" 
                                                [disabled]="activeIndex_benefit_gpa === 0" 
                                                (click)="setActiveIndex_benefit_gpa(activeIndex_benefit_gpa - 1)">
                                                ‹
                                            </button>
                                        
                                            <!-- Pagination Numbers -->
                                            <button 
                                                *ngFor="let slide of gpa_flex_plan; let i = index" 
                                                type="button" 
                                                data-bs-target="#imageCarousel_multiple_gpa" 
                                                [attr.data-bs-slide-to]="i" 
                                                [class.active-pagination-gpa-ancillary]="activeIndex_benefit_gpa === i"
                                                (click)="setActiveIndex_benefit_gpa(i)" 
                                                class="btn">
                                                {{ i + 1 }}
                                            </button>
                                        
                                            <!-- Single Right Arrow: Moves to the next slide -->
                                            <button 
                                                type="button" 
                                                class="btn" 
                                                [disabled]="activeIndex_benefit_gpa === gpa_flex_plan.length - 1" 
                                                (click)="setActiveIndex_benefit_gpa(activeIndex_benefit_gpa + 1)">
                                                ›
                                            </button>
                                        
                                            <!-- Double Right Arrow: Moves to the last slide -->
                                            <button 
                                                type="button" 
                                                class="btn" 
                                                [disabled]="activeIndex_benefit_gpa === gpa_flex_plan.length - 1" 
                                                (click)="setActiveIndex_benefit_gpa(gpa_flex_plan.length - 1)">
                                                »
                                            </button>
                                        </div>

                                    </div>
                                </section>     
                                <!-- End --> 
                                
                                <!-- Slider 2 -->
                                <div style="margin-top: 20px; border-radius: 10px 10px 0px 0px;">
                                    <h3 class="bs-title fs16 mb-0" style="padding: 15px 10px; background-color: #BFBFBF; color: var(--bs-sidebar); border-radius: 10px 10px 0px 0px;" *ngIf="ancillaryData.length > 0">My Personal Care
                                        <span class="spanfloat">
                                            <a style="color: red">See all
                                            <img src="../../../assets/img/employee/right.png" alt="image" class="rightarrow" />
                                        </a>
                                        </span>
                                    </h3>
                                    <section id="multipleslider4" class="intro-section appear-animate"  *ngIf="ancillaryData.length > 0">
                                        <div class="thirddiv pb-0">
                                            <div id="imageCarousel_multiple33" class="carousel slide" data-ride="carousel">
                                                <div class="carousel-inner">
                                                    <!-- First Slide -->
                                                    <div class="carousel-item" *ngFor="let slide of ancillaryData; let i = index;" [class.active]="i === 0">
                                                        <div class="d-flex" style="margin-bottom: 0px; padding-bottom: 0px; background-color: #fff;">
                                                            <div class="sliderinnerdiv2" *ngFor="let item of slide; let j = index" (click)="gotopage(item.id)" style="cursor: pointer;">
                                                                <img src="{{item.ancillary_logo}}" class="d-block w-100 slider2img" alt="Car Image">
                                                                <p class="slider2p">{{item.ancillary_name}}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
    
                                                <div *ngIf="ancillaryData.length > 1">
                                                    <a class="carousel-control-prev" href="#imageCarousel_multiple33" role="button" data-slide="prev">
                                                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                                    </a>
                                                    <a class="carousel-control-next" href="#imageCarousel_multiple33" role="button" data-slide="next">
                                                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                                    </a>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </section>
                                    
                                </div>
                                <!-- End -->

                            </div>
                            
                            <div class="col-sm-5" id="colSm5_gpa" style="background-color: #fff; border-radius: 4px;">

                                <h3 class="bs-title text-center mt-3 fs18" style="padding: 0px 10px;">Plans Summary</h3>
                                <!-- <p class="p-3 mb-0 text-white text-center fs14 leftheading">You Pay Premium ₹ 35,144.90 (Tax Included)</p>
                                <p class="p-3 text-white text-center fs14" style="background-color: #f8934c;">Employer pays ₹ 35,144.90 (Tax Included)</p> -->

                                <div class="mt-3">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <span style="color: #f5620d; font-weight: bold; font-size: 12px; float: right;  position: relative; top: 4px;">
                                                {{commonservice.moneyformatter(gpa_total_youpay_premium)}} (Tax Included)
                                            </span>
                                            <span style="color: #20B157; font-weight: bold; font-size: 12px;">
                                                {{commonservice.moneyformatter(gpa_total_employer_pay_premium)}} (Tax Included)
                                            </span>
                                            <div class="progress">
                                                <div class="progress-bar bg-success p1" role="progressbar" style="width: {{gpa_employer_pay+'%'}}" attr.aria-valuenow="{{gpa_employer_pay}}" aria-valuemin="0" aria-valuemax="100"></div>
                                                <div class="progress-bar bg-warning p2" role="progressbar" style="width: {{gpa_you_pay+'%'}}" attr.aria-valuenow="{{gpa_you_pay}}" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                            <span style="color: #f5620d; font-weight: bold; font-size: 12px; float: right; position: relative;">
                                                You Pay Premium
                                            </span>
                                            <span style="color: #20B157; font-weight: bold; font-size: 12px; top: -2px; position: relative; ">
                                                Employer Pays
                                            </span>                        
                                        </div>                                        
                                    </div>
                                </div>                                

                                <!-- GPA BASE PLAN-->
                                <div class="mt-3" *ngFor="let row of gpabasearray;">
                                    <h2 class="cardheading" style="background-color: #C8535E;">{{row.name}}</h2>
                                    <table class="table table-bordered table-sm" style="table-layout: fixed; border: solid 1px #dee2e6;">
                                        <thead>
                                            <tr>
                                                <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Relation</th>
                                                <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Cover</th>
                                                <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Premium</th>
                                            </tr>
                                        </thead>
                                        <tbody style="background: #fff;">
                                            <tr>
                                                <td rowspan="2" style="padding: 0px;">
                                                    <u style="list-style: none; line-height: 24px; text-decoration: none;">
                                                        <li *ngFor="let relations of row.relation;let last = last" [ngClass]="{'border-bottom': !last}">{{ relations }}</li>                                                
                                                    </u>                                           
                                                </td>
                                                <td style="padding-left:0px;padding-right:0px;">{{commonservice.moneyformatter_flex(row.cover)}}</td>
                                                <td style="padding-left:0px;padding-right:0px;">                     
                                                    <span *ngIf="row.premium != '-'">{{commonservice.moneyformatter(row.premium)}}</span><br>                                                                                        
                                                    <span *ngIf="row.is_premium_tax == '1' && row.premium != '-'" style="font-size: 10px;">(Tax Included)</span>
                                                    <span *ngIf="row.is_premium_tax == '0' && row.premium != '-'" style="font-size: 10px;">(Tax Excluded)</span>                                            
                                                </td>
                                            </tr>                                    
                                        </tbody>
                                    </table>                            
                                </div>                             

                                 <!-- GPA VOLUNTARY PLAN-->
                                 <div class="mt-3" *ngFor="let row of gpavoluntaryplans;">
                                    <h2 class="cardheading" style="background-color: #C8535E;">{{row.name}}</h2>
                                    <table class="table table-bordered table-sm" style="table-layout: fixed; border: solid 1px #dee2e6;">
                                        <thead>
                                            <tr>
                                                <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Relation</th>
                                                <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Cover</th>
                                                <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Premium</th>
                                            </tr>
                                        </thead>
                                        <tbody style="background: #fff;">
                                            <tr>
                                                <td rowspan="2" style="padding: 0px;">
                                                    <u style="list-style: none; line-height: 24px; text-decoration: none;">
                                                        <li *ngFor="let relations of row.relation;let last = last" [ngClass]="{'border-bottom': !last}">{{ relations }}</li>                                                
                                                    </u>                                           
                                                </td>
                                                <td style="padding-left:0px;padding-right:0px;">{{commonservice.moneyformatter_flex(row.cover)}}</td>
                                                <td style="padding-left:0px;padding-right:0px;">                     
                                                    <span *ngIf="row.premium != '-'">{{commonservice.moneyformatter(row.premium)}}</span><br>                                                                                        
                                                    <span *ngIf="row.is_premium_tax == '1' && row.premium != '-'" style="font-size: 10px;">(Tax Included)</span>
                                                    <span *ngIf="row.is_premium_tax == '0' && row.premium != '-'" style="font-size: 10px;">(Tax Excluded)</span>                                            
                                                </td>
                                            </tr>                                    
                                        </tbody>
                                    </table>                            
                                </div> 

                                 <!-- GPA ADDITIONAL FEATURES PLAN-->
                                 <div class="mt-3" *ngFor="let row of gpaadditionalarray;">
                                    <h2 class="cardheading" style="background-color: #C8535E;">{{row.name}}</h2>
                                    <table class="table table-bordered table-sm" style="table-layout: fixed; border: solid 1px #dee2e6;">
                                        <thead>
                                            <tr>
                                                <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Relation</th>
                                                <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Cover</th>
                                                <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Premium</th>
                                            </tr>
                                        </thead>
                                        <tbody style="background: #fff;">
                                            <tr>
                                                <td rowspan="2" style="padding: 0px;">
                                                    <u style="list-style: none; line-height: 24px; text-decoration: none;">
                                                        <li *ngFor="let relations of row.relation;let last = last" [ngClass]="{'border-bottom': !last}">{{ relations }}</li>                                                
                                                    </u>                                           
                                                </td>
                                                <td style="padding-left:0px;padding-right:0px;">{{commonservice.moneyformatter_flex(row.cover)}}</td>
                                                <td style="padding-left:0px;padding-right:0px;">                     
                                                    <span *ngIf="row.premium != '-'">{{commonservice.moneyformatter(row.premium)}}</span><br>                                                                                        
                                                    <span *ngIf="row.is_premium_tax == '1' && row.premium != '-'" style="font-size: 10px;">(Tax Included)</span>
                                                    <span *ngIf="row.is_premium_tax == '0' && row.premium != '-'" style="font-size: 10px;">(Tax Excluded)</span>                                            
                                                </td>
                                            </tr>                                    
                                        </tbody>
                                    </table>                            
                                </div> 
                                                           
                                 <!-- GPA Flex Plans -->
                                <div class="mt-3" *ngFor="let row of gpa_flex_plan;">
                                    <h2  *ngIf="row.gmc_base == 1" class="cardheading" style="background-color: #C8535E;">Add On Benefit - Base Policy</h2>
                                    <h2  *ngIf="row.gmc_base == 2" class="cardheading" style="background-color: #C8535E;">Add On Benefit - TopUp Policy</h2>
                                    <table class="table table-bordered table-sm tableborder" style="table-layout: fixed; border: solid 1px #dee2e6;">
                                        <thead>
                                            <tr>
                                                <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Relation</th>
                                                <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Cover</th>
                                                <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Premium</th>
                                            </tr>
                                        </thead>
                                        <tbody style="background: #fff;">
                                            <tr>
                                                <td style="padding-left:0px;padding-right:0px; text-wrap: wrap;">{{row.benefit_name}}</td>
                                                <td style="padding-left:0px;padding-right:0px;">{{commonservice.moneyformatter_flex(row.sum_insured)}}</td>
                                                <td style="padding-left:0px;padding-right:0px;">                     
                                                    <span *ngIf="row.total_premium != '-'">{{commonservice.moneyformatter(row.total_premium)}}</span><br>                                                                                        
                                                    <span *ngIf="row.is_premium_tax == '1' && row.total_premium != '-'" style="font-size: 10px;">(Tax Included)</span>
                                                    <span *ngIf="row.is_premium_tax == '0' && row.total_premium != '-'" style="font-size: 10px;">(Tax Excluded)</span>                                            
                                                </td>
                                            </tr>                                    
                                        </tbody>
                                    </table>                            
                                </div>

                                <span *ngFor="let gpaEnhancePlan of gpaEnhancePlan">
                                    <div *ngIf="gpaEnhancePlan.is_base == 0" style="margin-top: 10px;">
                                        <h2 class="cardheading" style="background-color: #C8535E;">Enhanced - {{gpaEnhancePlan.name}}</h2>
                                        <table class="table table-bordered table-sm tableborder" style="table-layout: fixed;">
                                            <thead>
                                                <tr>
                                                    <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Relation</th>
                                                    <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Cover</th>
                                                    <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Premium</th>
                                                </tr>
                                            </thead>
                                            <tbody style="background: #fff;">
                                                <tr>
                                                    <td rowspan="2" style="padding: 0px; text-wrap: wrap;">
                                                        <!-- {{gpaFlexPlan.benefit_name}} -->
                                                        <u style="list-style: none; line-height: 24px; text-decoration: none;">
                                                            <li *ngFor="let relations of gpaEnhancePlan.relation; let last = last" [ngClass]="{'border-bottom': !last}">{{ relations }}</li>                                                
                                                        </u>                                           
                                                    </td>
                                                    <td style="padding-left:0px;padding-right:0px;">{{commonservice.moneyformatter(gpaEnhancePlan.enhance_suminsured)}}</td>
                                                    <td style="padding-left:0px;padding-right:0px;">                     
                                                        <span *ngIf="gpaEnhancePlan.enhance_premium != '-'">{{commonservice.moneyformatter(gpaEnhancePlan.enhance_premium)}} - <br> {{commonservice.moneyformatter(gpaEnhancePlan.enhance_employer_premium)}} = <br> <b>{{commonservice.moneyformatter(gpaEnhancePlan.enhance_employee_premium)}} </b> </span><br>                                                                                        
                                                        <span *ngIf="gpaEnhancePlan.is_premium_tax == '1'" style="font-size: 10px;">(Tax Included)</span>
                                                        <span *ngIf="gpaEnhancePlan.is_premium_tax == '0'" style="font-size: 10px;">(Tax Excluded)</span>                                            
                                                    </td>
                                                </tr>                                    
                                            </tbody>
                                        </table>                            
                                    </div>
                                </span>

                            </div>

                            <!-- Disclaimer -->
                            <div class="col-sm-12 mt-3 p-2" *ngIf="gpa_disclaimer.length > 0 || gpa_disclaimer.length != ''">
                                <div class="accordion" id="myAccordion">
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingOne2">
                                            <button type="button" class="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne2" style="color: var(--bs-sidebar); background-color: #dedede; font-weight: bold;">
                                                *Disclaimer
                                            </button>
                                        </h2>
                                        <div id="collapseOne2" class="accordion-collapse show" data-bs-parent="#myAccordion">
                                            <div class="card-body accorCardbody" style="font-size: 13px;">
                                                {{gpa_disclaimer}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>                      
                    </div>

                    <!-- Tab Group Term Life -->
                    <div class="tab-pane fade" id="Corrections" role="tabpanel" aria-labelledby="Corrections-tab" *ngIf="gtlbasearray.length > 0">
                        <button class="rightbtn_gtl" id="rightbtn_gtl" (click)="hideAndExpand_gtl()">
                            <img src="../../../assets/img/employee/wrarrow.png" class="wrarrow_gtl" style="background-color: #005963; padding: 2px;" alt="img" />
                        </button>
                        <div class="row">

                            <div class="col-sm-7" id="colSm7_gtl" style="padding-left: 10px;">

                                <!-- Tabs -->
                                <div class="bgdiv">                                
                                    <ul class="nav nav-tabs ul_gtl" id="myTab_gtlBanner" role="tablist">
                                        <li class="nav-item liwidth2" *ngFor="let policy of banner_slider_gtl; let i = index" role="presentation">
                                            <button 
                                                class="nav-link gtl_link" 
                                                [class.active]="i === activeIndex_banner_gtl" 
                                                [attr.id]="'policy-' + policy.policy_id + '-tab'"
                                                data-bs-toggle="tab" 
                                                [attr.data-bs-target]="'#policy-' + policy.policy_id"
                                                type="button" 
                                                role="tab" 
                                                [attr.aria-controls]="'policy-' + policy.policy_id" 
                                                [attr.aria-selected]="i === activeIndex_banner_gtl"
                                                (click)="setActiveTab(i,activeIndex_banner_gtl)"
                                                style="background-color: #b30f00">
                                                {{ policy.name }}
                                            </button>
                                        </li>
                                    </ul>                                
                                    <div class="tab-content ul_gtl_content" id="myTabContent_gtlBanner">
                                        <div *ngFor="let item of banner_slider_gtl; let i = index" class="tab-pane fade" 
                                            [class.show]="i === activeIndex_banner_gtl" 
                                            [class.active]="i === activeIndex_banner_gtl" 
                                            [id]="'policy-' + item.policy_id"
                                            role="tabpanel" 
                                            [attr.aria-labelledby]="item.policy_id + '-tab'">
                                        
                                            <h5 style="color: var(--bs-sidebar);">
                                                <div class="row">
                                                    <div class="col-sm-3 pt-2">
                                                        Family Construct
                                                    </div>
                                                    <div class="col-sm-6 pt-2">
                                                        <span *ngIf="item.relation.length > 1" class="textrelation" [title]="item.relation.toString()">
                                                            {{item.relation.toString()}}
                                                        </span>
                                                        <span *ngIf="item.relation.length === 1" [title]="item.relation.toString()">
                                                            {{item.relation.toString()}}
                                                        </span>
                                                    </div>
                                                    <div class="col-sm-3">
                                                        <div style="font-size: 12px; 
                                                                    cursor:pointer; padding: 10px; 
                                                                    width: fit-content; background: #b30f00; float: right;
                                                                    color:#fff; font-weight: bold; z-index: 100" 
                                                                    (click)="getemployeepoliciesmembers(item)">
                                                            View Members
                                                        </div>                                            
                                                    </div>
                                                </div>                                                                                               
                                            </h5>
                                            
                                            <h5 class="view_details">
                                                <div class="row">
                                                    <div class="col-sm-3">
                                                        Sum Insured
                                                    </div>
                                                    <div class="col-sm-6">
                                                        <span>
                                                            {{commonservice.moneyformatter_flex(item.cover)}}
                                                        </span>
                                                    </div>                
                                                </div>                                
                                            </h5>

                                            <h5 class="premium_details">
                                                <div class="row">
                                                    <div class="col-sm-3">
                                                        Employer Premium
                                                    </div>
                                                    <div class="col-sm-6">
                                                        <span>
                                                            <b>{{commonservice.moneyformatter(item.employer_pay_premium)}}</b><br>
                                                            <small> 
                                                                <span *ngIf="item.is_premium_tax==1">(Tax Included)</span>
                                                                <span *ngIf="item.is_premium_tax==0">(Tax Excluded)</span>
                                                            
                                                            </small>
                                                        </span>
                                                    </div>                                                                                                                                            
                                                </div>
                                            </h5>                                            
                                        </div>
                                    </div>                          
                                </div>
                                <!-- End -->

                                <h3  *ngIf="gtl_flex_plan.length > 0" class="bs-title mt-4" style="color: #000; padding: 0px 10px; z-index: 100; position: relative;">Add on Coverages OR Modular Benefits
                                    <span class="spanfloat" (click)="modalCoverages(gtl_flex_plan)">
                                        <a style="color: red">See all
                                            <img src="../../../assets/img/employee/right.png" alt="image" class="rightarrow" />
                                        </a>
                                    </span>
                                </h3>

                                <!-- Slider 1 -->
                                <section id="multipleslider_gtl" class="intro-section appear-animate" *ngIf="gtlFlexPlan.length > 0">                                    
                                    <!-- Carousel HTML Structure -->
                                    <div id="imageCarousel_multiple_gtl" class="carousel slide" data-ride="carousel">
                                        <div class="carousel-inner" style="margin-bottom: 20px;">
                                            <!-- Generate carousel items dynamically -->
                                            <div class="carousel-item" *ngFor="let slide of gtlFlexPlan; let i = index;" [class.active]="i === activeIndex_benefit_gtl">
                                                <div class="d-flex">
                                                    <div class="col-4"  *ngFor="let item of slide; let j = index">
                                                        <div style="border-radius: 10px; text-align: center; border: solid 1px; background-color: #fff;">
                                                            <h1 class="bs-title text-white" style="font-size: 16px; padding: 12px; border-radius: 10px 10px 0px 0px; background-color: #b30f00;">Active Plan</h1>
                                                            <h1 class="bs-title text-ellipsis-word" style="margin: 13px 0px;" title="{{item.benefit_name}}">{{item.benefit_name}}</h1>
                                                            <table class="table table-bordered tableborder" style="table-layout: fixed;">
                                                                <thead>
                                                                    <tr style="background: #fff; text-align: center;">
                                                                        <th class="p-2 fs13">Sum Insured</th>
                                                                        <th class="p-2 fs13">Premium</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr style="text-align: center;">
                                                                        <td class="p-2 fs13">{{commonservice.moneyformatter_flex(item.sum_insured)}}</td>
                                                                        <td class="p-2 fs13">
                                                                            <span *ngIf="item.total_premium !== '-'">{{commonservice.moneyformatter(item.total_premium)}}</span><br>                                                                                        
                                                                            <span *ngIf="item.is_premium_tax === 1 && item.total_premium !== '-'">(Tax Included)</span>
                                                                            <span *ngIf="item.is_premium_tax === 0 && item.total_premium !== '-'">(Tax Excluded)</span>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                            <button class="btn btn-primary my-2" style="border: solid 2px; margin: auto; display: block;" (click)="openOPDBenefits(item.flex_benefit_id, item.policy_id, 'gtl_dropdown')">More Details</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>                                                                                                                                                                                
                                        </div>                                       

                                        <!-- Pagination Numbers -->
                                        <div class="pagination-numbers-gtl-ancillary d-flex align-items-center" style="float:right">
                                            <!-- Double Left Arrow: Moves to the first slide -->
                                            <button 
                                                type="button" 
                                                class="btn" 
                                                [disabled]="activeIndex_benefit_gtl === 0" 
                                                (click)="setActiveIndex_benefit_gtl(0)">
                                                «
                                            </button>
                                        
                                            <!-- Single Left Arrow: Moves to the previous slide -->
                                            <button 
                                                type="button" 
                                                class="btn" 
                                                [disabled]="activeIndex_benefit_gtl === 0" 
                                                (click)="setActiveIndex_benefit_gtl(activeIndex_benefit_gtl - 1)">
                                                ‹
                                            </button>
                                        
                                            <!-- Pagination Numbers -->
                                            <button 
                                                *ngFor="let slide of gtl_flex_plan; let i = index" 
                                                type="button" 
                                                data-bs-target="#imageCarousel_multiple_gtl" 
                                                [attr.data-bs-slide-to]="i" 
                                                [class.active-pagination-gtl-ancillary]="activeIndex_benefit_gtl === i"
                                                (click)="setActiveIndex_benefit_gtl(i)" 
                                                class="btn">
                                                {{ i + 1 }}
                                            </button>
                                        
                                            <!-- Single Right Arrow: Moves to the next slide -->
                                            <button 
                                                type="button" 
                                                class="btn" 
                                                [disabled]="activeIndex_benefit_gtl === gtl_flex_plan.length - 1" 
                                                (click)="setActiveIndex_benefit_gtl(activeIndex_benefit_gtl + 1)">
                                                ›
                                            </button>
                                        
                                            <!-- Double Right Arrow: Moves to the last slide -->
                                            <button 
                                                type="button" 
                                                class="btn" 
                                                [disabled]="activeIndex_benefit_gtl === gtl_flex_plan.length - 1" 
                                                (click)="setActiveIndex_benefit_gtl(gtl_flex_plan.length - 1)">
                                                »
                                            </button>
                                        </div>

                                    </div>
                                </section>    
                                <!-- End --> 
                                
                                <!-- Slider 2 -->
                                <div style="margin-top: 20px; border-radius: 10px 10px 0px 0px;">
                                    <h3 class="bs-title fs16 mb-0" style="padding: 15px 10px; background-color: #BFBFBF; color: var(--bs-sidebar); border-radius: 10px 10px 0px 0px;" *ngIf="ancillaryData.length > 0">My Personal Care
                                        <span class="spanfloat">
                                            <a style="color: red">See all
                                            <img src="../../../assets/img/employee/right.png" alt="image" class="rightarrow" />
                                        </a>
                                        </span>
                                    </h3>
                                    <section id="multipleslider3" class="intro-section appear-animate"  *ngIf="ancillaryData.length > 0">
                                        <div class="thirddiv pb-0">
                                            <div id="imageCarousel_multiple22" class="carousel slide" data-ride="carousel">
                                                <div class="carousel-inner">
                                                    <!-- First Slide -->
                                                    <div class="carousel-item" *ngFor="let slide of ancillaryData; let i = index;" [class.active]="i === 0">
                                                        <div class="d-flex" style="margin-bottom: 0px; padding-bottom: 0px; background-color: #fff;">
                                                            <div class="sliderinnerdiv2" *ngFor="let item of slide; let j = index" (click)="gotopage(item.id)" style="cursor: pointer;">
                                                                <img src="{{item.ancillary_logo}}" class="d-block w-100 slider2img" alt="Car Image">
                                                                <p class="slider2p">{{item.ancillary_name}}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
    
                                                <div *ngIf="ancillaryData.length > 1">
                                                    <a class="carousel-control-prev" href="#imageCarousel_multiple22" role="button" data-slide="prev">
                                                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                                    </a>
                                                    <a class="carousel-control-next" href="#imageCarousel_multiple22" role="button" data-slide="next">
                                                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                                    </a>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </section>
                                    
                                </div>
                                <!-- End -->

                            </div>                            

                            <div class="col-sm-5" id="colSm5_gtl" style="background-color: #fff; border-radius: 4px;">

                                <h3 class="bs-title text-center mt-3 fs18" style="padding: 0px 10px;">Plans Summary</h3>
                                <!-- <p class="p-3 mb-0 text-white text-center fs14 leftheading">You Pay Premium ₹ 35,144.90 (Tax Included)</p>
                                <p class="p-3 text-white text-center fs14" style="background-color: #f8934c;">Employer pays ₹ 35,144.90 (Tax Included)</p> -->

                                <div class="mt-3">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <span style="color: #f5620d; font-weight: bold; font-size: 12px; float: right; position: relative; top: 4px;">
                                                {{commonservice.moneyformatter(gtl_total_youpay_premium)}} (Tax Included)
                                            </span>
                                            <span style="color: #20B157; font-weight: bold; font-size: 12px;">
                                                {{commonservice.moneyformatter(gtl_total_employer_pay_premium)}} (Tax Included)
                                            </span>
                                            <div class="progress">
                                                <div class="progress-bar bg-success p1" role="progressbar" style="width: {{gtl_employer_pay+'%'}}" attr.aria-valuenow="{{gtl_employer_pay}}" aria-valuemin="0" aria-valuemax="100"></div>
                                                <div class="progress-bar bg-warning p2" role="progressbar" style="width: {{gtl_you_pay+'%'}}" attr.aria-valuenow="{{gtl_you_pay}}" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                            <span style="color: #f5620d; font-weight: bold; font-size: 12px; position: relative; float: right;">
                                                You Pay Premium
                                            </span>
                                            <span style="color: #20B157; font-weight: bold; font-size: 12px; position: relative; top: -2px;">
                                                Employer Pays
                                            </span>                        
                                        </div>                                        
                                    </div>
                                </div>  

                                <!-- GTL BASE PLAN-->
                                <div class="mt-3" *ngFor="let row of gtlbasearray;">
                                    <h2 class="cardheading" style="background-color: #b30f00;">{{row.name}}</h2>
                                    <table class="table table-bordered table-sm" style="table-layout: fixed; border: solid 1px #dee2e6;">
                                        <thead>
                                            <tr>
                                                <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Relation</th>
                                                <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Cover</th>
                                                <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Premium</th>
                                            </tr>
                                        </thead>
                                        <tbody style="background: #fff;">
                                            <tr>
                                                <td rowspan="2" style="padding: 0px;">
                                                    <u style="list-style: none; line-height: 24px; text-decoration: none;">
                                                        <li *ngFor="let relations of row.relation;let last = last" [ngClass]="{'border-bottom': !last}">{{ relations }}</li>                                                
                                                    </u>                                           
                                                </td>
                                                <td style="padding-left:0px;padding-right:0px;">{{commonservice.moneyformatter_flex(row.cover)}}</td>
                                                <td style="padding-left:0px;padding-right:0px;">                     
                                                    <span *ngIf="row.premium != '-'">{{commonservice.moneyformatter(row.premium)}}</span><br>                                                                                        
                                                    <span *ngIf="row.is_premium_tax == '1' && row.premium != '-'" style="font-size: 10px;">(Tax Included)</span>
                                                    <span *ngIf="row.is_premium_tax == '0' && row.premium != '-'" style="font-size: 10px;">(Tax Excluded)</span>                                            
                                                </td>
                                            </tr>                                    
                                        </tbody>
                                    </table>                            
                                </div>

                                <!-- GTL VOLUNTARY PLAN-->
                                <div class="mt-3" *ngFor="let row of gtlvoluntaryplans;">
                                    <h2 class="cardheading" style="background-color: #b30f00;">{{row.name}}</h2>
                                    <table class="table table-bordered table-sm" style="table-layout: fixed; border: solid 1px #dee2e6;">
                                        <thead>
                                            <tr>
                                                <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Relation</th>
                                                <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Cover</th>
                                                <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Premium</th>
                                            </tr>
                                        </thead>
                                        <tbody style="background: #fff;">
                                            <tr>
                                                <td rowspan="2" style="padding: 0px;">
                                                    <u style="list-style: none; line-height: 24px; text-decoration: none;">
                                                        <li *ngFor="let relations of row.relation;let last = last" [ngClass]="{'border-bottom': !last}">{{ relations }}</li>                                                
                                                    </u>                                           
                                                </td>
                                                <td style="padding-left:0px;padding-right:0px;">{{commonservice.moneyformatter_flex(row.cover)}}</td>
                                                <td style="padding-left:0px;padding-right:0px;">                     
                                                    <span *ngIf="row.premium != '-'">{{commonservice.moneyformatter(row.premium)}}</span><br>                                                                                        
                                                    <span *ngIf="row.is_premium_tax == '1' && row.premium != '-'" style="font-size: 10px;">(Tax Included)</span>
                                                    <span *ngIf="row.is_premium_tax == '0' && row.premium != '-'" style="font-size: 10px;">(Tax Excluded)</span>                                            
                                                </td>
                                            </tr>                                    
                                        </tbody>
                                    </table>                            
                                </div>

                                <!-- GTL ADDITIONAL FEATURES PLAN-->
                                <div class="mt-3" *ngFor="let row of gtladditionalarray;">
                                    <h2 class="cardheading" style="background-color: #b30f00;">{{row.name}}</h2>
                                    <table class="table table-bordered table-sm" style="table-layout: fixed; border: solid 1px #dee2e6;">
                                        <thead>
                                            <tr>
                                                <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Relation</th>
                                                <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Cover</th>
                                                <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Premium</th>
                                            </tr>
                                        </thead>
                                        <tbody style="background: #fff;">
                                            <tr>
                                                <td rowspan="2" style="padding: 0px;">
                                                    <u style="list-style: none; line-height: 24px; text-decoration: none;">
                                                        <li *ngFor="let relations of row.relation;let last = last" [ngClass]="{'border-bottom': !last}">{{ relations }}</li>                                                
                                                    </u>                                           
                                                </td>
                                                <td style="padding-left:0px;padding-right:0px;">{{commonservice.moneyformatter_flex(row.cover)}}</td>
                                                <td style="padding-left:0px;padding-right:0px;">                     
                                                    <span *ngIf="row.premium != '-'">{{commonservice.moneyformatter(row.premium)}}</span><br>                                                                                        
                                                    <span *ngIf="row.is_premium_tax == '1' && row.premium != '-'" style="font-size: 10px;">(Tax Included)</span>
                                                    <span *ngIf="row.is_premium_tax == '0' && row.premium != '-'" style="font-size: 10px;">(Tax Excluded)</span>                                            
                                                </td>
                                            </tr>                                    
                                        </tbody>
                                    </table>                            
                                </div>                                

                                 <!-- GTL Flex Plans -->
                                 <div class="mt-3" *ngFor="let row of gtl_flex_plan;">
                                    <h2  *ngIf="row.gmc_base == 1" class="cardheading" style="background-color: #b30f00;">Add On Benefit - Base Policy</h2>
                                    <h2  *ngIf="row.gmc_base == 2" class="cardheading" style="background-color: #b30f00;">Add On Benefit - TopUp Policy</h2>
                                    <table class="table table-bordered table-sm tableborder" style="table-layout: fixed; border: solid 1px #dee2e6;">
                                        <thead>
                                            <tr>
                                                <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Relation</th>
                                                <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Cover</th>
                                                <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Premium</th>
                                            </tr>
                                        </thead>
                                        <tbody style="background: #fff;">
                                            <tr>
                                                <td style="padding-left:0px;padding-right:0px; text-wrap: wrap;">{{row.benefit_name}}</td>
                                                <td style="padding-left:0px;padding-right:0px;">{{commonservice.moneyformatter_flex(row.sum_insured)}}</td>
                                                <td style="padding-left:0px;padding-right:0px;">                     
                                                    <span *ngIf="row.total_premium != '-'">{{commonservice.moneyformatter(row.total_premium)}}</span><br>                                                                                        
                                                    <span *ngIf="row.is_premium_tax == '1' && row.total_premium != '-'" style="font-size: 10px;">(Tax Included)</span>
                                                    <span *ngIf="row.is_premium_tax == '0' && row.total_premium != '-'" style="font-size: 10px;">(Tax Excluded)</span>                                            
                                                </td>
                                            </tr>                                    
                                        </tbody>
                                    </table>                            
                                </div>

                                <span *ngFor="let gtlEnhancePlan of gtlEnhancePlan">
                                    <div *ngIf="gtlEnhancePlan.is_base == 0" style="margin-top: 10px;">
                                        <h2 class="cardheading" style="background-color: #b30f00;">Enhanced - {{gtlEnhancePlan.name}}</h2>
                                        <table class="table table-bordered table-sm tableborder" style="table-layout: fixed;">
                                            <thead>
                                                <tr>
                                                    <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Relation</th>
                                                    <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Cover</th>
                                                    <th style="padding: 8px; background-color: #F4F4F4; color: #000; font-size: 12px;">Premium</th>
                                                </tr>
                                            </thead>
                                            <tbody style="background: #fff;">
                                                <tr>
                                                    <td rowspan="2" style="padding: 0px; text-wrap: wrap;">
                                                        <!-- {{gpaFlexPlan.benefit_name}} -->
                                                        <u style="list-style: none; line-height: 24px; text-decoration: none;">
                                                            <li *ngFor="let relations of gtlEnhancePlan.relation; let last = last" [ngClass]="{'border-bottom': !last}">{{ relations }}</li>                                                
                                                        </u>                                           
                                                    </td>
                                                    <td style="padding-left:0px;padding-right:0px;">{{commonservice.moneyformatter(gtlEnhancePlan.enhance_suminsured)}}</td>
                                                    <td style="padding-left:0px;padding-right:0px;">                     
                                                        <span *ngIf="gtlEnhancePlan.enhance_premium != '-'">{{commonservice.moneyformatter(gtlEnhancePlan.enhance_premium)}} - <br> {{commonservice.moneyformatter(gtlEnhancePlan.enhance_employer_premium)}} = <br> <b>{{commonservice.moneyformatter(gtlEnhancePlan.enhance_employee_premium)}} </b> </span><br>                                                                                        
                                                        <span *ngIf="gtlEnhancePlan.is_premium_tax == '1'" style="font-size: 10px;">(Tax Included)</span>
                                                        <span *ngIf="gtlEnhancePlan.is_premium_tax == '0'" style="font-size: 10px;">(Tax Excluded)</span>                                            
                                                    </td>
                                                </tr>                                    
                                            </tbody>
                                        </table>                            
                                    </div>
                                </span>

                            </div>

                            <!-- Disclaimer -->
                            <div class="col-sm-12 mt-3 p-2"  *ngIf="gtl_disclaimer.length > 0 || gtl_disclaimer.length != ''">
                                <div class="accordion" id="myAccordion">
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingOne3">
                                            <button type="button" class="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne3" style="color: var(--bs-sidebar); background-color: #dedede; font-weight: bold;">
                                                *Disclaimer
                                            </button>
                                        </h2>
                                        <div id="collapseOne3" class="accordion-collapse show" data-bs-parent="#myAccordion">
                                            <div class="card-body accorCardbody" style="font-size: 13px;">
                                                {{gtl_disclaimer}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>                    

                </div>
            </div>
            <!-- End -->

            <!-- GMC Right Popup -->
            <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':rightModal}">
                <div class="modal-dialog" role="document">
                    <div class="modal-content" style="right: 0px; position: fixed; top: 0px; width: 500px; z-index: 1000000;">                       
                        <div class="modal-body p-0">
                        
                            <!-- Tabs -->
                            <div class="col-sm-12">

                                <!-- Tabs Headings -->
                                <ul class="nav nav-tabs" id="myTab2" role="tablist">
                                    <li class="nav-item liwidth2" role="presentation">
                                        <button class="nav-link active w100" id="Features-tab" data-bs-toggle="tab" data-bs-target="#Features"
                                            type="button" role="tab" aria-controls="Features" aria-selected="true">GMC
                                        </button>
                                    </li>
                                    <li class="nav-item liwidth2" role="presentation">
                                        <button class="nav-link w100" id="New_Hospital-tab" data-bs-toggle="tab" data-bs-target="#New_Hospital" 
                                        type="button" role="tab" aria-controls="New_Hospital" aria-selected="false">OPD</button>
                                    </li>
                                    <li class="nav-item liwidth2" role="presentation">
                                        <button class="nav-link w100" id="Claim_Process-tab" data-bs-toggle="tab"
                                            data-bs-target="#Claim_Process" type="button" role="tab" aria-controls="Claim_Process"
                                            aria-selected="false">GPA</button>
                                    </li>
                                    <li class="nav-item liwidth2" role="presentation">
                                        <button class="nav-link w100" id="FAQs-tab" data-bs-toggle="tab"
                                            data-bs-target="#FAQs" type="button" role="tab" aria-controls="FAQs"
                                            aria-selected="false">GTL</button>
                                    </li>
                                </ul>

                                <!-- Tabs Content -->
                                <div class="tab-content tabUI2" id="myTabContent2" style="height: calc(100vh - 90px); overflow-y: auto;">

                                    <!-- Tab Features -->
                                    <div class="tab-pane fade show active" id="Features" role="tabpanel" aria-labelledby="Features-tab" style="font-size: 12px;">
                                        
                                        <!-- <div class="colordiv">
                                            <div class="greenheading">PF Included</div>
                                            <img src="../../../assets/img/employee/ellipse_700.png" class="ellipse" style="width: 300px;" />
                                            <div class="greenp" style="height: 200px; overflow-y: auto;">
                                                <div *ngFor="let gmc_arr_included of gmc_feature_arr.pf_included; let i = index">
                                                    <div>{{gmc_arr_included['title']}} - {{gmc_arr_included['content']}}</div>                                                    
                                                    <hr *ngIf="i != gmc_feature_arr.pf_included.length">
                                                </div>
                                            </div>                                            
                                        </div>
                                        
                                        <div class="colordiv">
                                            <div class="redheading">PF Excluded</div>
                                            <img src="../../../assets/img/employee/ellipse_red.png" class="ellipse" />                                            
                                            <div class="redp" style="height: 200px; overflow-y: auto;">
                                                <div *ngFor="let gmc_arr_excluded of gmc_feature_arr.pf_excluded; let i = index">
                                                    <div>{{gmc_arr_excluded['title']}} - {{gmc_arr_excluded['content']}}</div>                                                    
                                                    <hr *ngIf="i != gmc_feature_arr.pf_excluded.length">
                                                </div>
                                            </div>
                                        </div>

                                        <div class="colordiv mt-3">
                                            <div class="redheading">PF Optional</div>
                                            <img src="../../../assets/img/employee/ellipse_violet.png" class="ellipse" />                                            
                                            <div class="violetp" style="height: 200px; overflow-y: auto;">
                                                <div *ngFor="let gmc_arr_optinal of gmc_feature_arr.pf_optional; let i = index">
                                                    <div>{{gmc_arr_optinal['title']}} - {{gmc_arr_optinal['content']}}</div>                                                    
                                                    <hr *ngIf="i != gmc_arr_optinal.pf_excluded.length">
                                                </div>
                                            </div>
                                        </div> -->

                                        <div style="border: solid 5px green; padding: 10px; background: #fff;">
                                            <div style="text-align: center;
                                            background: green;
                                            color: #fff;
                                            border-radius: 50px;
                                            width: fit-content;
                                            display: block;
                                            margin: auto;
                                            padding: 3px 50px;
                                            text-transform: capitalize !important;
                                            margin-top: -30px;
                                            font-size: 16px;">Included</div>
                                            <div class="greenp" style="height: 200px; overflow-y: auto; margin-top: 20px;">
                                                <div *ngFor="let gmc_arr_included of gmc_feature_arr.pf_included; let i = index">
                                                    <div><b>{{gmc_arr_included['title']}}</b> - {{gmc_arr_included['content']}}</div>                                                    
                                                    <hr *ngIf="i != gmc_feature_arr.pf_included.length">
                                                </div>
                                            </div> 
                                        </div>
										
										
										<div style="border: solid 5px red; padding: 10px; background: #fff; margin-top: 50px;">
                                            <div style="text-align: center;
                                            background: red;
                                            color: #fff;
                                            border-radius: 50px;
                                            width: fit-content;
                                            display: block;
                                            margin: auto;
                                            padding: 3px 50px;
                                            text-transform: capitalize !important;
                                            margin-top: -30px;
                                            font-size: 16px;">Excluded</div>
                                            <div class="greenp" style="height: 200px; overflow-y: auto; margin-top: 20px;">
                                                <div *ngFor="let gmc_arr_excluded of gmc_feature_arr.pf_excluded; let i = index">
                                                    <div><b>{{gmc_arr_excluded['title']}}</b> - {{gmc_arr_excluded['content']}}</div>                                                    
                                                    <hr *ngIf="i != gmc_feature_arr.pf_excluded.length">
                                                </div>
                                            </div> 
                                        </div>
										
										
										<div style="border: solid 5px rgb(145, 0, 108); padding: 10px; background: #fff; margin-top: 50px;">
                                            <div style="text-align: center;
                                            background: rgb(145, 0, 108);
                                            color: #fff;
                                            border-radius: 50px;
                                            width: fit-content;
                                            display: block;
                                            margin: auto;
                                            padding: 3px 50px;
                                            text-transform: capitalize !important;
                                            margin-top: -30px;
                                            font-size: 16px;">Optional</div>
                                            <div class="greenp" style="height: 200px; overflow-y: auto; margin-top: 20px;">
                                                <div *ngFor="let gmc_arr_optinal of gmc_feature_arr.pf_optional; let i = index">
                                                    <div><b>{{gmc_arr_optinal['title']}}</b> - {{gmc_arr_optinal['content']}}</div>                                                    
                                                    <hr *ngIf="i != gmc_feature_arr.pf_optional.length">
                                                </div>
                                            </div> 
                                        </div>

                                    </div>

                                    <!-- Tab New_Hospital -->
                                    <div class="tab-pane fade" id="New_Hospital" role="tabpanel" aria-labelledby="New_Hospital-tab">
                                        <div style="border: solid 5px green; padding: 10px; background: #fff;">
                                            <div style="text-align: center;
                                            background: green;
                                            color: #fff;
                                            border-radius: 50px;
                                            width: fit-content;
                                            display: block;
                                            margin: auto;
                                            padding: 3px 50px;
                                            text-transform: capitalize !important;
                                            margin-top: -30px;
                                            font-size: 16px;">Included</div>
                                            <div class="greenp" style="height: 200px; overflow-y: auto; margin-top: 20px;">
                                                <div *ngFor="let opd_arr_included of opd_feature_arr.pf_included; let i = index">
                                                    <div><b>{{opd_arr_included['title']}}</b> - {{opd_arr_included['content']}}</div>                                                    
                                                    <hr *ngIf="i != opd_feature_arr.pf_included.length">
                                                </div>
                                            </div> 
                                        </div>
										
										
										<div style="border: solid 5px red; padding: 10px; background: #fff; margin-top: 50px;">
                                            <div style="text-align: center;
                                            background: red;
                                            color: #fff;
                                            border-radius: 50px;
                                            width: fit-content;
                                            display: block;
                                            margin: auto;
                                            padding: 3px 50px;
                                            text-transform: capitalize !important;
                                            margin-top: -30px;
                                            font-size: 16px;">Excluded</div>
                                            <div class="greenp" style="height: 200px; overflow-y: auto; margin-top: 20px;">
                                                <div *ngFor="let opd_arr_excluded of opd_feature_arr.pf_excluded; let i = index">
                                                    <div><b>{{opd_arr_excluded['title']}}</b> - {{opd_arr_excluded['content']}}</div>                                                    
                                                    <hr *ngIf="i != opd_feature_arr.pf_excluded.length">
                                                </div>
                                            </div> 
                                        </div>
										
										
										<div style="border: solid 5px rgb(145, 0, 108); padding: 10px; background: #fff; margin-top: 50px;">
                                            <div style="text-align: center;
                                            background: rgb(145, 0, 108);
                                            color: #fff;
                                            border-radius: 50px;
                                            width: fit-content;
                                            display: block;
                                            margin: auto;
                                            padding: 3px 50px;
                                            text-transform: capitalize !important;
                                            margin-top: -30px;
                                            font-size: 16px;">Optional</div>
                                            <div class="greenp" style="height: 200px; overflow-y: auto; margin-top: 20px;">
                                                <div *ngFor="let opd_arr_optinal of opd_feature_arr.pf_optional; let i = index">
                                                    <div><b>{{opd_arr_optinal['title']}}</b> - {{opd_arr_optinal['content']}}</div>                                                    
                                                    <hr *ngIf="i != opd_feature_arr.pf_optional.length">
                                                </div>
                                            </div> 
                                        </div>
                                    </div>                                                                        

                                    <!-- Tab Claim_Process -->
                                    <div class="tab-pane fade" id="Claim_Process" role="tabpanel" aria-labelledby="Claim_Process-tab">
                                        <div style="border: solid 5px green; padding: 10px; background: #fff;">
                                            <div style="text-align: center;
                                            background: green;
                                            color: #fff;
                                            border-radius: 50px;
                                            width: fit-content;
                                            display: block;
                                            margin: auto;
                                            padding: 3px 50px;
                                            text-transform: capitalize !important;
                                            margin-top: -30px;
                                            font-size: 16px;">Included</div>
                                            <div class="greenp" style="height: 200px; overflow-y: auto; margin-top: 20px;">
                                                <div *ngFor="let gpa_arr_included of gpa_feature_arr.pf_included; let i = index">
                                                    <div><b>{{gpa_arr_included['title']}}</b> - {{gpa_arr_included['content']}}</div>                                                    
                                                    <hr *ngIf="i != gpa_feature_arr.pf_included.length">
                                                </div>
                                            </div> 
                                        </div>
										
										
										<div style="border: solid 5px red; padding: 10px; background: #fff; margin-top: 50px;">
                                            <div style="text-align: center;
                                            background: red;
                                            color: #fff;
                                            border-radius: 50px;
                                            width: fit-content;
                                            display: block;
                                            margin: auto;
                                            padding: 3px 50px;
                                            text-transform: capitalize !important;
                                            margin-top: -30px;
                                            font-size: 16px;">Excluded</div>
                                            <div class="greenp" style="height: 200px; overflow-y: auto; margin-top: 20px;">
                                                <div *ngFor="let gpa_arr_excluded of gpa_feature_arr.pf_excluded; let i = index">
                                                    <div><b>{{gpa_arr_excluded['title']}}</b> - {{gpa_arr_excluded['content']}}</div>                                                    
                                                    <hr *ngIf="i != gpa_feature_arr.pf_excluded.length">
                                                </div>
                                            </div> 
                                        </div>
										
										
										<div style="border: solid 5px rgb(145, 0, 108); padding: 10px; background: #fff; margin-top: 50px;">
                                            <div style="text-align: center;
                                            background: rgb(145, 0, 108);
                                            color: #fff;
                                            border-radius: 50px;
                                            width: fit-content;
                                            display: block;
                                            margin: auto;
                                            padding: 3px 50px;
                                            text-transform: capitalize !important;
                                            margin-top: -30px;
                                            font-size: 16px;">Optional</div>
                                            <div class="greenp" style="height: 200px; overflow-y: auto; margin-top: 20px;">
                                                <div *ngFor="let gpa_arr_optinal of gpa_feature_arr.pf_optional; let i = index">
                                                    <div><b>{{gpa_arr_optinal['title']}}</b> - {{gpa_arr_optinal['content']}}</div>                                                    
                                                    <hr *ngIf="i != gpa_feature_arr.pf_optional.length">
                                                </div>
                                            </div> 
                                        </div>

                                    </div>                                    

                                    <!-- Tab FAQs -->
                                    <div class="tab-pane fade" id="FAQs" role="tabpanel" aria-labelledby="FAQs-tab">
                                        <div style="border: solid 5px green; padding: 10px; background: #fff;">
                                            <div style="text-align: center;
                                            background: green;
                                            color: #fff;
                                            border-radius: 50px;
                                            width: fit-content;
                                            display: block;
                                            margin: auto;
                                            padding: 3px 50px;
                                            text-transform: capitalize !important;
                                            margin-top: -30px;
                                            font-size: 16px;">Included</div>
                                            <div class="greenp" style="height: 200px; overflow-y: auto; margin-top: 20px;">
                                                <div *ngFor="let gtl_arr_included of gtl_feature_arr.pf_included; let i = index">
                                                    <div><b>{{gtl_arr_included['title']}}</b> - {{gtl_arr_included['content']}}</div>                                                    
                                                    <hr *ngIf="i != gtl_feature_arr.pf_included.length">
                                                </div>
                                            </div> 
                                        </div>
										
										
										<div style="border: solid 5px red; padding: 10px; background: #fff; margin-top: 50px;">
                                            <div style="text-align: center;
                                            background: red;
                                            color: #fff;
                                            border-radius: 50px;
                                            width: fit-content;
                                            display: block;
                                            margin: auto;
                                            padding: 3px 50px;
                                            text-transform: capitalize !important;
                                            margin-top: -30px;
                                            font-size: 16px;">Excluded</div>
                                            <div class="greenp" style="height: 200px; overflow-y: auto; margin-top: 20px;">
                                                <div *ngFor="let gtl_arr_excluded of gtl_feature_arr.pf_excluded; let i = index">
                                                    <div><b>{{gtl_arr_excluded['title']}}</b> - {{gtl_arr_excluded['content']}}</div>                                                    
                                                    <hr *ngIf="i != gtl_feature_arr.pf_excluded.length">
                                                </div>
                                            </div> 
                                        </div>
										
										
										<div style="border: solid 5px rgb(145, 0, 108); padding: 10px; background: #fff; margin-top: 50px;">
                                            <div style="text-align: center;
                                            background: rgb(145, 0, 108);
                                            color: #fff;
                                            border-radius: 50px;
                                            width: fit-content;
                                            display: block;
                                            margin: auto;
                                            padding: 3px 50px;
                                            text-transform: capitalize !important;
                                            margin-top: -30px;
                                            font-size: 16px;">Optional</div>
                                            <div class="greenp" style="height: 200px; overflow-y: auto; margin-top: 20px;">
                                                <div *ngFor="let gtl_arr_optinal of gtl_feature_arr.pf_optional; let i = index">
                                                    <div><b>{{gtl_arr_optinal['title']}}</b> - {{gtl_arr_optinal['content']}}</div>                                                    
                                                    <hr *ngIf="i != gtl_feature_arr.pf_optional.length">
                                                </div>
                                            </div> 
                                        </div>                                    
                                    </div>

                                </div>

                            </div>
                            <!-- End -->

                        </div>
                        <div class="modal-footer p-2" style="display: flex; background-color: white;">
                            <button (click)="closealert()" class="btn btn-danger" style="float: right; margin-top: 0px;">Close</button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Coverages Popup -->
            <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':Coverages}">
                <div class="modal-dialog" role="document" style="max-width: 900px;">                    
                    <div class="modal-content" style="top: -10px">                       
                        <div class="modal-header" style="background-color: var(--bs-sidebar); padding: 20px">
                            <h4 class="modal-title text-white p-0">Add on Coverages OR Modular Benefits
                                <button type="button" class="btn btn-danger" (click)="closealert()" style="position: absolute;right: 10px;"> &#10006;</button>
                            </h4>
                        </div>
                        <div class="modal-body p-0" style="height: 300px; overflow-y: auto; overflow-x: hidden;">
                            
                            <div class="p-3 m-3 mb-3 coverageRow" *ngFor="let res of CoveragesArray; let i = index;">
                                <div class="row">
                                    <div class="col-sm-3">
                                        <!-- <img src="../../../assets/img/employee/{{res['img_URL']}}" style="height: 150px;" /> -->
                                        <img src="../../../assets/img/employee/coverage_1.png" style="height: 150px;" />
                                    </div>
                                    <div class="col-sm-6 text-center mt-3">
                                        <h3 class="bs-title text-black Ctitle">{{res['benefit_name']}}</h3>
                                        <h2 class="bs-title text-black Cprice"><b>
                                            {{commonservice.moneyformatter_flex(res['sum_insured'])}}</b>
                                        </h2>
                                    </div>
                                    <div class="col-sm-3">
                                        <button class="btn btn-primary mt-3" style="position: relative;top: 40px;" 
                                        (click)="openOPDBenefits(res.flex_benefit_id, res.policy_id, 'gmc_dropdown')">
                                            4 View Plans
                                            <img src="../../../assets/img/employee/rightArrow.png" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-danger" (click)="closealert()"> Close</button>
                        </div>
                    </div>                    
                </div>
            </div>
            
            <!-- OPD Benefits Popup -->
            <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':OPDBenefits}">
                <div class="modal-dialog" role="document" style="max-width: 1000px;">                    
                    <div class="modal-content">                       
                      
                        <div class="modal-header">
                            <h4 class="modal-title">Benefits Plan
                                <img src="../../../../../../assets/img/employee/close.png" (click)="closealert_ben()" class="closebtn_all" />
                            </h4>
                        </div>

                        <div class="modal-body" style="overflow-x: hidden;">
                            
                            <div class="row">
                                <div class="col-sm-3">
                                    <img src="../../../assets/img/employee/OPD 1.png" style="width: 70%; position: relative; top: 20px; margin: auto; display: block;" alt="image" />
                                </div>
                                <div class="col-sm-9">
                                    <p class="mt-5" style="font-size: 16px;">{{plan_description}}</p>
                                </div>    
                            </div>                                                       

                            <table class="table table-striped" id="pricingTable" style="table-layout: fixed;">
                                <thead>
                                  <tr style="border: none; background: none;">
                                    <th style="background: none; border: none;"></th>
                                    <ng-container *ngFor="let plan of plan_Array; let i = index;">
                                      <th class="nonselectedcolumn" [style.background-color]="plan.bgcolor" [style.color]="plan.color">
                                        <input type="checkbox" id="plan_{{i}}" name="plan" class="custom_checkbox" (click)="handleCheckbox(i, 0)" />
                                        {{ plan.plan_name }}
                                      </th>
                                    </ng-container>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr *ngFor="let row of TableArray">
                                    <td>{{ row.title }}</td>
                                    <ng-container *ngFor="let column of getColumns()">
                                      <ng-container *ngIf="column !== 'description' && column !== 'master_id'">
                                        <td [innerHTML]="row[column].includes('Premium') ? row[column].split('Premium').join('<br/>Premium') : (row[column])" style="line-height: 18px; white-space: break-spaces;"></td>
                                      </ng-container>
                                    </ng-container>
                                  </tr>
                                </tbody>
                              </table>
                            
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-danger" (click)="closealert_ben()"> Close</button>
                        </div>
                    </div>                    
                </div>
            </div>  
            
            <!-- Member Details Popup -->
            <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyleClosePolicy}">
                <div class="modal-dialog modellg" role="document" style="max-width: 800px;">
                    <div class="modal-content">
					
                        <div class="modal-header" [ngStyle]="{'background-color': bgcolor}">
                            <h4 class="modal-title">Member Details
                                <img src="../../../../../../assets/img/employee/close.png" (click)="closePopup()" class="closebtn_all" />
                            </h4>
                        </div>
            
                        <div class="modal-body" style="max-height: 400px; overflow-y: auto;">
                            <div class="row" *ngFor="let closeres of ClosePolicyArray; let cl = index" style="background-color: #f9f9f9; margin-bottom: 15px; border-radius: 10px; overflow: hidden; padding: 10px">
                                    
                                    <div class="col-md-2">
                                        <!-- Profile Image -->
                                        <img 
                                        src="{{closeres['image_url']}}" 
                                        alt="img" 
                                        class="img-fluid mb-3" 
                                        style="width: 100px; height: 100px; object-fit: cover; margin: auto; display: block; margin-top: 40px;" 
                                        />
                                        <!-- Download Button -->
                                        <button 
                                            class="btn btn-primary w-100 text-uppercase" 
                                            style="font-size: 12px;" [ngStyle]="{'background-color': bgcolor}">
                                            Download E-Card
                                        </button>
                                    </div>
                                    <!-- Member Information -->
                                    <div class="col-md-3">
                                        <div class="card border-light shadow-sm">
                                            <div class="card-header text-white" [ngStyle]="{'background-color': bgcolor}">Member Information</div>
                                            <div class="card-body cardbody">
                                                <div class="row">
                                                    <div class="col-6">
                                                        <p class="mb-0 text-muted">Member ID</p>
                                                        <p class="mb-1 fw-bold">{{ closeres['member_id'] }}</p>
                                                    </div>             
                                                    <div class="col-6">
                                                        <p class="mb-0 text-muted">DOB</p>
                                                        <p class="mb-1 fw-bold">{{ closeres['dob'] }}</p>
                                                    </div>                                                                                           
                                                </div>                                                
                                                <p class="mb-0 text-muted">Relation</p>
                                                <p class="mb-1 fw-bold">{{ closeres['relation'] }}</p>
                                                <p class="mb-0 text-muted">Status</p>
                                                <p class="mb-1 fw-bold">{{ closeres['status'] }}</p>                                                
                                                <p class="mb-0 text-muted">Enrollement Status</p>
                                                <p class="mb-1 fw-bold">{{ closeres['enrollement_status'] }}</p>                                                
                                            </div>
                                        </div>
                                    </div>

                                    <!-- Policy Details -->
                                    <div class="col-md-4">
                                        <div class="card border-light shadow-sm">
                                            <div class="card-header text-white" [ngStyle]="{'background-color': bgcolor}">Policy Details</div>
                                            <div class="card-body cardbody">
                                                <p class="mb-0 text-muted">Policy Name</p>
                                                <p class="mb-1 fw-bold">{{ closeres['policy_name'] }}</p>
                                                <p class="mb-0 text-muted">Policy Number</p>
                                                <p class="mb-1 fw-bold">{{ closeres['policy_number'] }}</p>
                                                <p class="mb-0 text-muted">Sum Insured</p>
                                                <p class="mb-1 fw-bold">&#8377; {{ closeres['suminsured'] || '-' }}</p>
                                                <p class="mb-0 text-muted">Cover Date</p>
                                                <p class="mb-1 fw-bold">{{ closeres['cover_date'] }}</p>                                                
                                            </div>
                                        </div>
                                    </div>

                                    <!-- Personal Details -->
                                    <div class="col-md-3">
                                        <div class="card border-light shadow-sm">
                                            <div class="card-header text-white" [ngStyle]="{'background-color': bgcolor}">Personal Details</div>
                                            <div class="card-body cardbody">                                                
                                                <p class="mb-0 text-muted">Name</p>
                                                <p class="mb-1 fw-bold">{{ closeres['first_name'] }} {{ closeres['last_name'] }}</p>                                                
                                                <p class="mb-0 text-muted">Gender</p>
                                                <p class="mb-1 fw-bold">{{ closeres['gender'] }}</p>
                                                <p class="mb-0 text-muted">Employee Premium</p>
                                                <p class="mb-1 fw-bold">{{ closeres['employee_premium'] }}</p>
                                                <p class="mb-0 text-muted">Employer Premium</p>
                                                <p class="mb-1 fw-bold">{{ closeres['employer_premium'] }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                        </div>
						
                        <div class="modal-footer">
                            <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
                        </div>
            
                    </div>
                </div>
            </div>
                
        </div>
        <app-footer></app-footer>
    </div>
</div>