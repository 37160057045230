import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { Service } from './service';
import { Subject } from 'rxjs';
import { CommonService } from '../../_services/common.service';
import { numberFormat } from 'highcharts';

@Component({
  selector: 'app-contact-us-info',
  templateUrl: './contact-us-info.component.html',
  styleUrls: ['./contact-us-info.component.css']
})
export class ContactUsInfoComponent implements OnInit {

  resstatus = localStorage.getItem('resstatus');
  pagehide = localStorage.getItem('pagehide');
  useremail = localStorage.getItem('username');

  selectedGMCPolicy: any;
  selectedOPDPolicy: any;
  selectedGPAPolicy: any;
  selectedGTLPolicy: any;

  gmcBroker:any = [];
  gmcEmployer:any = [];
  gmcInsurer:any = [];

  opdBroker:any = [];
  opdEmployer:any = [];
  opdInsurer:any = [];

  gpaBroker:any = [];
  gpaEmployer:any = [];
  gpaInsurer:any = [];

  gtlBroker:any = [];
  gtlEmployer:any = [];
  gtlInsurer:any = [];

  gmcPolicy:any = [];
  opdPolicy:any = [];
  gpaPolicy:any = [];
  gtlPolicy:any = [];

  ngOnInit(): void {
    this.getInfo();    
  }

  constructor(
    private fb: FormBuilder,
    private dataservice: Service,
    private route: Router,
    public commonservice: CommonService,

  ) {
    this.route.events.subscribe((e) => {
      if (this.resstatus == 'false') {
          this.route.navigate(['/home']);
      }
    });
  }

  getInfo(){

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id')      
    };

    this.dataservice.getInfo(postdata)
      .subscribe((result) => {  
        
        if(result.status == true){   
          
          this.gmcPolicy = result.data.gmcPolicyArray;
          this.opdPolicy = result.data.opdPolicyArray;
          this.gpaPolicy = result.data.gpaPolicyArray;
          this.gtlPolicy = result.data.gtlPolicyArray;
          
          if (this.gmcPolicy && this.gmcPolicy.length > 0) {
            this.selectedGMCPolicy = this.gmcPolicy[0].policy_id;
          }

          if (this.opdPolicy && this.opdPolicy.length > 0) {
            this.selectedOPDPolicy = this.opdPolicy[0].policy_id;
          }

          if (this.gpaPolicy && this.gpaPolicy.length > 0) {
            this.selectedGPAPolicy = this.gpaPolicy[0].policy_id;
          }

          if (this.gtlPolicy && this.gtlPolicy.length > 0) {
            this.selectedGTLPolicy = this.gtlPolicy[0].policy_id;
          }

          this.gmcBroker = result.data.gmcContactInfo.gmcBroker;
          this.gmcEmployer = result.data.gmcContactInfo.gmcEmployer;
          this.gmcInsurer = result.data.gmcContactInfo.gmcInsurer;

          this.opdBroker = result.data.opdContactInfo.opdBroker;
          this.opdEmployer = result.data.opdContactInfo.opdEmployer;
          this.opdInsurer = result.data.opdContactInfo.opdInsurer;

          this.gpaBroker = result.data.gpaContactInfo.gpaBroker;
          this.gpaEmployer = result.data.gpaContactInfo.gpaEmployer;
          this.gpaInsurer = result.data.gpaContactInfo.gpaInsurer;

          this.gtlBroker = result.data.gtlContactInfo.gtlBroker;
          this.gtlEmployer = result.data.gtlContactInfo.gtlEmployer;
          this.gtlInsurer = result.data.gtlContactInfo.gtlInsurer;
        }

      }, (error) => {         
        
        if(error.error.message == 'Unauthenticated.'){
          localStorage.clear();
          localStorage.setItem('resstatus', 'false');
          this.route.navigate(['/home']);
        }
    });
  }

  getInfoPolicyGMC(){

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),     
      "policy_id":$('#policy_number_gmc').val()     
    };

    this.dataservice.getInfoContact(postdata)
      .subscribe((result) => {  
        
        if(result.status == true){   
          
          this.gmcBroker = result.data.gmcContactInfo.gmcBroker;
          this.gmcEmployer = result.data.gmcContactInfo.gmcEmployer;
          this.gmcInsurer = result.data.gmcContactInfo.gmcInsurer;

          console.log(this.gmcBroker);
        }

      }, (error) => {         
        
        if(error.error.message == 'Unauthenticated.'){
          localStorage.clear();
          localStorage.setItem('resstatus', 'false');
          this.route.navigate(['/home']);
        }
    });
  }

  getInfoPolicyOPD(){

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),     
      "policy_id":$('#policy_number_opd').val()     
    };

    this.dataservice.getInfoContact(postdata)
      .subscribe((result) => {  
        
        if(result.status == true){   
          
          this.opdBroker = result.data.opdContactInfo.opdBroker;
          this.opdEmployer = result.data.opdContactInfo.opdEmployer;
          this.opdInsurer = result.data.opdContactInfo.opdInsurer;

        }

      }, (error) => {         
        
        if(error.error.message == 'Unauthenticated.'){
          localStorage.clear();
          localStorage.setItem('resstatus', 'false');
          this.route.navigate(['/home']);
        }
    });
  }
  
  getInfoPolicyGPA(){

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),     
      "policy_id":$('#policy_number_gpa').val()     
    };

    this.dataservice.getInfoContact(postdata)
      .subscribe((result) => {  
        
        if(result.status == true){   
          
          this.gpaBroker = result.data.gpaContactInfo.gpaBroker;
          this.gpaEmployer = result.data.gpaContactInfo.gpaEmployer;
          this.gpaInsurer = result.data.gpaContactInfo.gpaInsurer;

        }

      }, (error) => {         
        
        if(error.error.message == 'Unauthenticated.'){
          localStorage.clear();
          localStorage.setItem('resstatus', 'false');
          this.route.navigate(['/home']);
        }
    });
  }

  getInfoPolicyGTL(){

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),     
      "policy_id":$('#policy_number_gtl').val()     
    };

    this.dataservice.getInfoContact(postdata)
      .subscribe((result) => {  
        
        if(result.status == true){   
          
          this.gtlBroker = result.data.gtlContactInfo.gtlBroker;
          this.gtlEmployer = result.data.gtlContactInfo.gtlEmployer;
          this.gtlInsurer = result.data.gtlContactInfo.gtlInsurer;

        }

      }, (error) => {         
        
        if(error.error.message == 'Unauthenticated.'){
          localStorage.clear();
          localStorage.setItem('resstatus', 'false');
          this.route.navigate(['/home']);
        }
    });
  }

  goback(){
    this.route.navigate(['/flex-configurator-dashboard']);
  }

  getColor(index: number): string {
    const colors = ['#005963', '#C8535E', '#2C2C2C'];
    return colors[index % colors.length];
  }


}
