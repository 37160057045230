<!-- Body -->
<div class="container-fluid page-body-wrapper">

    <!-- Sidebar -->
    <app-sidebar></app-sidebar>

    <!-- Main Wrapper -->
    <div class="main-panel">
        <div class="content-wrapper pt-3">

            <ngx-loader [show]="show" [fullScreen]="fullScreen" [template]="template"
                style="z-index: 500000; position: absolute;"> Loading... </ngx-loader>

            <h3 class="bs-title" *ngIf="addflag == 0">Manage FAQ
                <button *ngIf="writeaccess == 1" type="button" class="btn btn-primary" (click)="openPopupAdd()"
                    role="button" style="float:right">Add
                    New</button>
            </h3>
            <h3 class="bs-title" *ngIf="addflag == 1">Add FAQ
                <button type="button" class="btn btn-primary" (click)="closePopupAdd()" role="button"
                    style="float:right">Back</button>
            </h3>
            <h3 class="bs-title" *ngIf="addflag == 2">Edit FAQ
                <button type="button" class="btn btn-primary" (click)="closePopupAdd()" role="button"
                    style="float:right">Back</button>
            </h3>

            <hr class="mb-2 mt-4" style="height: 0px">

            <!-- List -->
            <div *ngIf="addflag == 0">
                <table class="table table-sm row-border" width="100%" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                    <thead>
                        <tr>
                            <th style="width:5%">SrNo</th>
                            <th>Client</th>
                            <th>Policy Type</th>
                            <th>Question</th>
                            <th>Answer</th>
                            <th>Status</th>
                            <th style="text-align: center;" *ngIf="writeaccess == 1">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let group of InfoArray; let i = index">
                            <td style="width:5%">{{i+1}}</td>
                            <td>{{group['employer']}}</td>
                            <td>{{group['policy_sub_type']}}</td>
                            <td>
                                <div class="ellipsis" [title]="group['questions']">
                                    {{group['questions']}}
                                </div>
                            </td>
                            <td>
                                <div class="ellipsis" [title]="group['answers']">
                                    {{group['answers']}}
                                </div>
                            </td>
                            <td>
                                <span *ngIf="group['is_active'] == 1" class="btn btn-inverse-success btn-fw">Active</span>
                                <span *ngIf="group['is_active'] == 0" class="btn btn-inverse-danger btn-fw">Deactive</span>
                            </td>
                            <td style="width: 15%" align="center" *ngIf="writeaccess == 1">
                                <img src="../../../assets/img/common icons/edit_new.png" id="edituser" (click)="openPopupEdit(group)"
                                    role="button" title="Edit" />
                                <img *ngIf="group['is_active'] == 1" src="../../../assets/img/selectproduct/deactive.png"
                                    id="deleteuser" (click)="openPopupDeactivate(group)" class="ml-4" role="button" title="Deactive" />
                                <img *ngIf="group['is_active'] == 0" src="../../../assets/img/policy/tickicon.png" id="deleteuser"
                                    (click)="openPopupActivate(group)" class="ml-4" role="button" title="Active" />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>            

            <!-- Add Form -->
            <div *ngIf="addflag == 1" style="background: #fff; padding: 15px;">
                <form [formGroup]="AddInfo">
                    <div class="row">
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label class="bs-form-label" for="client_id">Client <span
                                        class="redstar">*</span></label>
                                <ng-select formControlName="client_id" id="client_id"
                                    [items]="InfoArrayCorporate" bindLabel="name" bindValue="id"
                                    [placeholder]="'Select Client'"
                                    [ngClass]="{'control-red': AddInfoSubmitted && getAddInformationFormControls.client_id.errors}">
                                </ng-select>
                                <ng-container
                                    *ngIf="AddInfoSubmitted && getAddInformationFormControls.client_id.errors">
                                    <p class="error"
                                        *ngIf="AddInfoSubmitted && getAddInformationFormControls.client_id.errors.required">
                                        Client is required
                                    </p>
                                </ng-container>
                            </div>
                        </div>

                        <div class="col-sm-4">
                            <div class="form-group">
                                <label class="bs-form-label" for="sub_type_id">Policy Type <span
                                        class="redstar">*</span></label>
                                <select class="form-select" formControlName="sub_type_id" id="sub_type_id"
                                    [ngClass]="{'control-red': AddInfoSubmitted && getAddInformationFormControls.sub_type_id.errors}"
                                    required>
                                    <option value="">Select Policy Type</option>
                                    <option *ngFor="let policyType of sub_type_id; let i = index"
                                        value="{{policyType.id}}">
                                        {{policyType.name}}</option>
                                </select>
                                <ng-container
                                    *ngIf="AddInfoSubmitted && getAddInformationFormControls.sub_type_id.errors">
                                    <p class="error"
                                        *ngIf="AddInfoSubmitted && getAddInformationFormControls.sub_type_id.errors.required">
                                        Policy Type is required
                                    </p>
                                </ng-container>
                            </div>
                        </div>
                        <div class="col-sm-4 form-group" style="display: none;">
                            <div class="mb-2 bs-form-label">File Option <i class="bi bi-exclamation-circle"
                                    style="width:20px; height:20px; transform: rotate(-180deg)"></i>
                            </div>
                            <input type="radio" class="form-radio" value="yes" formControlName="file_type" id="file_type" /> <span
                                class="bs-radio-label"> Overwrite file</span>
                            &nbsp;
                            <input type="radio" class="form-radio" value="no" formControlName="file_type" id="file_type" /> <span
                                class="bs-radio-label"> Add into Existing FAQs</span>
                        </div>
                        <div class="col-sm-12 mt-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="document_file">Upload File <span
                                        class="redstar">*</span> </label>
                                <label for="document_file" id="features-file-upload_document_file" class=" form-control"
                                    style="text-align:center; cursor:pointer; padding: 5px;">
                                    <img src="../../assets/img/policy/pin.png" style="width: 12px;">&nbsp;
                                    <span id="selectFile_Logo"
                                        style="color:#4E0099; font-weight: 600; text-align:center">Upload
                                        File</span>
                                </label>
                                <input id="document_file" type="file" (change)="uploadfile($event)"
                                    formControlName="document_file" accept=".xlsx, .csv, .xls"
                                    enctype="multipart/form-data" style="display: none" />
                            </div>
                            <small style="font-size: 12px; color:#605D62" id="policyfeatures_document_file">Upload only
                                .xlsx, .csv, .xls
                                files</small>
                            <small style="font-size: 12px; color:#552ce9; float: right; cursor: pointer" (click)="downloadsample()">Download Sample File</small>                                
                            <small class="error" style="display: none; font-weight:normal"
                                id="document_file_error">Document Required</small>

                        </div>
                        <div class="col-sm-12" align="center">
                            <button type="button" class="btn btn-primary mt-3" (click)="onSubmitAdd()"
                                role="button">Save</button>
                        </div>
                    </div>
                </form>
            </div>

            <!-- Edit Form -->
            <div *ngIf="addflag == 2" style="background: #fff; padding: 15px;">
                <form [formGroup]="EditInfo">
                    <div class="row">
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label class="bs-form-label" for="client_id_edit">Client <span
                                        class="redstar">*</span></label>
                                <ng-select formControlName="client_id_edit" id="client_id_edit"
                                    [items]="InfoArrayCorporate" bindLabel="name" bindValue="id"
                                    [placeholder]="'Select Client'"
                                    [ngClass]="{'control-red': EditInfoSubmitted && getEditInformationFormControls.client_id_edit.errors}">
                                </ng-select>
                                <ng-container
                                    *ngIf="EditInfoSubmitted && getEditInformationFormControls.client_id_edit.errors">
                                    <p class="error"
                                        *ngIf="EditInfoSubmitted && getEditInformationFormControls.client_id_edit.errors.required">
                                        Client is required
                                    </p>
                                </ng-container>
                            </div>
                        </div>

                        <div class="col-sm-4">
                            <div class="form-group">
                                <label class="bs-form-label" for="sub_type_id_edit">Policy Type <span
                                        class="redstar">*</span></label>
                                <select class="form-select" formControlName="sub_type_id_edit" id="sub_type_id_edit"
                                    [ngClass]="{'control-red': EditInfoSubmitted && getEditInformationFormControls.sub_type_id_edit.errors}"
                                    required>
                                    <option value="">Select Policy Type</option>
                                    <option *ngFor="let policyType of sub_type_id; let i = index"
                                        value="{{policyType.id}}">
                                        {{policyType.name}}</option>
                                </select>
                                <ng-container
                                    *ngIf="EditInfoSubmitted && getEditInformationFormControls.sub_type_id_edit.errors">
                                    <p class="error"
                                        *ngIf="EditInfoSubmitted && getEditInformationFormControls.sub_type_id_edit.errors.required">
                                        Policy Type is required
                                    </p>
                                </ng-container>
                            </div>
                        </div>
                        <div class="col-sm-4 form-group" style="display: none;">
                            <div class="mb-2 bs-form-label">File Option <i class="bi bi-exclamation-circle"
                                    style="width:20px; height:20px; transform: rotate(-180deg)"></i>
                            </div>
                            <input type="radio" class="form-radio" value="yes" formControlName="file_type_edit" id="file_type_edit" /> <span
                                class="bs-radio-label"> Overwrite file</span>
                            &nbsp;
                            <input type="radio" class="form-radio" value="no" formControlName="file_type_edit" id="file_type_edit" /> <span
                                class="bs-radio-label"> Edit into Existing FAQs</span>
                        </div> 
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label class="bs-form-label" for="edit_is_active">Status <span
                                        class="redstar">*</span></label>
                                <select class="form-select" formControlName="edit_is_active" id="edit_is_active"
                                    required>
                                    <option [value]="1">Active</option>
                                    <option [value]="0">Deactive</option>
                                </select>
                            </div>
                        </div>                                                
                        <div class="col-sm-4 mt-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="edit_is_active">Question <span
                                        class="redstar">*</span></label>
                                <input type="type" class="form-control" formControlName="questions" id="questions" placeholder="Question"
                                        [ngClass]="{'control-red': EditInfoSubmitted && getEditInformationFormControls.questions.errors}"
                                    required />
                                    <ng-container
                                    *ngIf="EditInfoSubmitted && getEditInformationFormControls.questions.errors">
                                    <p class="error"
                                    *ngIf="EditInfoSubmitted && getEditInformationFormControls.questions.errors.required">
                                    Question is required
                                    </p>
                                </ng-container>                                    
                            </div>
                        </div>
                        <div class="col-sm-4 mt-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="edit_is_active">Answer <span
                                        class="redstar">*</span></label>
                                <input type="type" class="form-control" formControlName="answers" id="answers" placeholder="Answer"
                                        [ngClass]="{'control-red': EditInfoSubmitted && getEditInformationFormControls.answers.errors}"
                                    required />
                                    <ng-container
                                    *ngIf="EditInfoSubmitted && getEditInformationFormControls.answers.errors">
                                    <p class="error"
                                    *ngIf="EditInfoSubmitted && getEditInformationFormControls.answers.errors.required">
                                    Answer is required
                                    </p>
                                </ng-container>                                    
                            </div>
                        </div>                                                                           
                        <!-- <div class="col-sm-12 mt-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="document_file_edit">Upload File <span
                                        class="redstar">*</span> </label>
                                <label for="document_file_edit" id="features-file-upload_document_file_edit" class=" form-control"
                                    style="text-align:center; cursor:pointer; padding: 5px;">
                                    <img src="../../assets/img/policy/pin.png" style="width: 12px;">&nbsp;
                                    <span id="selectFile_Logo"
                                        style="color:#4E0099; font-weight: 600; text-align:center">Edit
                                        File</span>
                                </label>
                                <input id="document_file_edit" type="file" (change)="uploadfile_edit($event)"
                                    formControlName="document_file_edit" accept=".xlsx, .csv, .xls"
                                    enctype="multipart/form-data" style="display: none" />
                            </div>
                            <small style="font-size: 12px; color:#605D62" id="policyfeatures_document_file_edit">Upload only
                                .xlsx, .csv, .xls
                                files</small>
                            <small style="font-size: 12px; color:#552ce9; float: right; cursor: pointer" (click)="downloadsample()">Download Sample File</small>                                
                            <small class="error" style="display: none; font-weight:normal"
                                id="document_file_edit_error">Document Required</small>

                        </div> -->

                        <div class="col-sm-12" align="center">
                            <button type="button" class="btn btn-primary mt-3" (click)="onSubmitEdit()"
                                role="button">Update</button>
                        </div>
                    </div>
                    <input type="hidden" class="form-control" formControlName="edit_id" id="edit_id" />
                </form>
            </div>

            <!-- Deactivate Form -->
            <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyleDeactivate}">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">Deactivate Master Module</h4>
                        </div>
                        <form [formGroup]="DeactivateInfo">
                            <div class="modal-body">
                                Are you sure you want to Deactivate?
                            </div>
                            <input type="hidden" class="form-control" formControlName="delete_id" id="delete_id" />
                            <div class="modal-footer">
                                <button type="button" class="btn btn-danger" style="background-color: #000;" (click)="onSubmitDeactivate()"
                                    role="button">Deactivate</button>
                                <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <!-- Activate Form -->
            <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyleActivate}">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">Activate Master Module</h4>
                        </div>
                        <form [formGroup]="ActivateInfo">
                            <div class="modal-body">
                                Are you sure you want to Activate?
                            </div>
                            <input type="hidden" class="form-control" formControlName="active_delete_id"
                                id="active_delete_id" />
                            <div class="modal-footer">
                                <button type="button" class="btn btn-danger" style="background-color: #000;" (click)="onSubmitActivate()"
                                    role="button">Activate</button>
                                <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </div>
        <app-footer></app-footer>
    </div>
</div>