<!-- Body -->
<div class="container-fluid page-body-wrapper">

    <!-- Sidebar -->
    <app-sidebar></app-sidebar>

    <!-- Main Wrapper -->
    <div class="main-panel">
        <div class="content-wrapper pt-3">

            <h3 class="bs-title">Employee Assistance</h3>

            <div class="col-sm-12 mt-3">
                <!-- Dynamic Tabs -->
                <ul class="nav nav-tabs" id="dynamicTab" role="tablist" style="border-radius: 10px 10px 0px 0px;">
                    <li 
                        class="nav-item liwidth" 
                        role="presentation" 
                        *ngFor="let tab of InfoArray; let i = index">
                        <button 
                            class="nav-link w100" 
                            [class.active]="i === 0"
                            [id]="'tab-' + tab.sub_type_policy_idfk" 
                            data-bs-toggle="tab" 
                            [attr.data-bs-target]="'#content-' + tab.sub_type_policy_idfk"
                            type="button" 
                            role="tab" 
                            [attr.aria-controls]="'content-' + tab.sub_type_policy_idfk" 
                            [attr.aria-selected]="i === 0 ? 'true' : 'false'">
                            {{ tab.policy_sub_type }}
                        </button>
                    </li>
                </ul>
            </div>
            
            <!-- Dynamic Tabs Content -->
            <div class="tab-content tabUI" id="dynamicTabContent" style="padding-bottom: 20px;">
                <div 
                    class="tab-pane" 
                    *ngFor="let tab of InfoArray; let i = index"
                    [class.show]="i === 0"
                    [class.active]="i === 0"
                    [id]="'content-' + tab.sub_type_policy_idfk" 
                    role="tabpanel" 
                    [attr.aria-labelledby]="'tab-' + tab.sub_type_policy_idfk">
                    
                    <div class="faq-container mt-3">
                        <div 
                            class="faq" 
                            *ngFor="let item of tab.qa" 
                            [class.active]="item.active" 
                            (click)="toggleAnswer(item)">
                            <div class="faq-question">
                                <h3>{{ item.question }}</h3>
                                <i class="mdi" [ngClass]="item.active ? 'mdi-chevron-up' : 'mdi-chevron-down'"></i>
                            </div>
                            <div class="faq-answer">
                                <p>{{ item.answer }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>                                  

        </div>
        <app-footer></app-footer>
    </div>
</div>